<div class="container-fluid">
  <div class="row text-center">
    <div *ngIf="view !== GitsCalendarView.Custom" class="col-md-4">
      <div>
        <button mat-flat-button color="primary" type="button"
                mwlCalendarPreviousView
                [view]="calendarView"
                [(viewDate)]="viewDate"
                (viewDateChange)="onViewDateChange($event)">
          {{"page.previous" | translate}}</button>
        <button mat-flat-button color="secondary" type="button"
                mwlCalendarToday
                [(viewDate)]="viewDate">
          {{"dateTime.today" | translate}}</button>
        <button mat-flat-button color="primary" type="button"
                mwlCalendarNextView
                [view]="calendarView"
                [(viewDate)]="viewDate"
                (viewDateChange)="onViewDateChange($event)">
          {{"page.next" | translate}}</button>
      </div>
    </div>
    <div *ngIf="view !== GitsCalendarView.Custom" class="col-md-4">
      <div>
        <h3>{{viewDate | calendarDate:(view + 'ViewTitle'):locale:weekStartsOn}}</h3>
      </div>
    </div>
    <div *ngIf="view === GitsCalendarView.Custom" class="col-md-8 date-range-margin-fix">
      <app-leave-filter (selectionChanged)="leaveFilter = $event"
                        [typeSelection]="filter === LeaveType.Business ? [LeaveType.Business] : null"
                        [lockTypeSelection]="filter === LeaveType.Business"></app-leave-filter>
    </div>
    <div class="col-md-3">
          <mat-button-toggle-group name="calendarView" [(value)]="view" aria-label="Calendar view" (change)="onViewChange($event.value)">
            <mat-button-toggle [value]="GitsCalendarView.Custom"> {{"generic.search" | translate}} </mat-button-toggle>
            <mat-button-toggle [value]="GitsCalendarView.Month"> {{"dateTime.month" | translate}} </mat-button-toggle>
            <mat-button-toggle [value]="GitsCalendarView.Week"> {{"dateTime.week" | translate}} </mat-button-toggle>
            <mat-button-toggle [value]="GitsCalendarView.Day"> {{"dateTime.day" | translate}} </mat-button-toggle>
          </mat-button-toggle-group>

          <span class="outer-action-button">
            <mat-icon *ngIf="view !== GitsCalendarView.Custom"></mat-icon>
            <button mat-icon-button *ngIf="view === GitsCalendarView.Custom" type="button" [matMenuTriggerFor]="exportMenu"
                    matTooltip="{{'generic.export' | translate}}">
              <mat-icon>list_alt</mat-icon>
            </button>
            <mat-menu #exportMenu="matMenu" xPosition="before">
              <button mat-menu-item (click)="leaveManager.exportToExcel()">
                <mat-icon>filter_none</mat-icon>
                <span>{{"generic.exportXls" | translate}}</span>
              </button>
              <button mat-menu-item (click)="leaveManager.exportToPdf()">
                <mat-icon>picture_as_pdf</mat-icon>
                <span>{{"generic.exportPdf" | translate}}</span>
              </button>
            </mat-menu>

            <button mat-icon-button color="primary" type="button" class="outer-action-button" (click)="addLeave()">
              <mat-icon class="outer-action-button-icon">add_circle</mat-icon>
            </button>
           </span>
    </div>
  </div>

  <br/>

  <ng-template #loading>
    <div class="row text-center">
      <div class="col-md-12">
        <mat-spinner class="text-center"></mat-spinner>
      </div>
    </div>
  </ng-template>

  <ng-template #noEvents>
    <div class="row text-center">
      <div class="col-md-12">
        <div class="text-center">{{"leave.messages.infNoLeavesToLoad" | translate}}</div>
      </div>
    </div>
  </ng-template>

  <ng-template #monthDayCellTemplate
               let-day="day"
               let-openDay="openDay"
               let-locale="locale"
               let-tooltipPlacement="tooltipPlacement"
               let-highlightDay="highlightDay"
               let-unhighlightDay="unhighlightDay"
               let-eventClicked="eventClicked">
    <div (mouseover)="day.active = true" (mouseleave)="day.active = false">
      <div class="cal-cell-top">
        <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
        <span class="cal-day-number">{{day.date | calendarDate:'monthViewDayNumber':locale}}</span>
      </div>
      <div class="cal-events">
        <div
          class="cal-event"
          *ngFor="let event of day.events"
          [style.backgroundColor]="event.color.primary"
          [ngClass]="event?.cssClass"
          (mouseenter)="highlightDay.emit({event: event})"
          (mouseleave)="unhighlightDay.emit({event: event})"
          [mwlCalendarTooltip]="event.title | calendarEventTitle:'monthTooltip':event"
          [tooltipPlacement]="tooltipPlacement"
          (click)="$event.stopPropagation(); eventClicked.emit({event: event})">
        </div>
        <div>
          <mat-icon *ngIf="day.active" inline="true" (click)="handleEvent('Clicked', day.date)"
                    mwlCalendarTooltip="{{'leave.addLeave' | translate}}">add_circle
          </mat-icon>
        </div>
      </div>
    </div>
  </ng-template>

  <div *ngIf="(events$ | async) as events; else loading">
    <div *ngIf="events.length">
        <div [ngSwitch]="view">
          <mwl-calendar-month-view
            *ngSwitchCase="CalendarView.Month"
            [viewDate]="viewDate"
            [events]="events"
            [refresh]="refresh"
            [activeDayIsOpen]="activeDayIsOpen"
            [locale]="locale"
            [weekStartsOn]="weekStartsOn"
            [cellTemplate]="monthDayCellTemplate"
            (dayClicked)="dayClicked($event.day)"
            (eventClicked)="handleEvent('Clicked', $event.event)">
          </mwl-calendar-month-view>
          <mwl-calendar-week-view
            *ngSwitchCase="CalendarView.Week"
            [viewDate]="viewDate"
            [events]="events"
            [dayStartHour]="9"
            [dayEndHour]="8"
            [refresh]="refresh"
            [locale]="locale"
            [weekStartsOn]="weekStartsOn"
            (eventClicked)="handleEvent('Clicked', $event.event)">
          </mwl-calendar-week-view>
          <mwl-calendar-day-view
            *ngSwitchCase="CalendarView.Day"
            [viewDate]="viewDate"
            [events]="events"
            [dayStartHour]="9"
            [dayEndHour]="8"
            [refresh]="refresh"
            [locale]="locale"
            (eventClicked)="handleEvent('Clicked', $event.event)">
          </mwl-calendar-day-view>
        </div>
    </div>
    <div *ngIf="events.length === 0 then noEvents">
    </div>
  </div>

  <div *ngIf="view === GitsCalendarView.Custom">
    <app-leave-manager #leaveManager [filter]="leaveFilter" [showFilter]="false"></app-leave-manager>
  </div>
</div>
