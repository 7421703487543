import {LocationType} from './location';

export interface Timesheet {
  id: number;
  employeeBadgeNumber: number;
  employeeDepartmentName: string;
  employeeFullName: string;
  dateTimeIn: string | Date;
  dateTimeOut: string | Date;
  type: TimesheetType;
  location: LocationType;
  description: string;
  status: TimesheetStatus;
}

export enum TimesheetType {
  Work = 'Work',
  Project = 'Project'
}

export enum TimesheetStatus {
  Open = 'Open',
  Finalized = 'Finalized',
  Canceled = 'Canceled'
}

export interface TimesheetFilter {
  dateTimeInGreaterThan: string;
  dateTimeInGreaterThanOrEqualTo: string;
  dateTimeInLessThan: string;
  dateTimeInLessThanOrEqualTo: string;
  dateTimeOutGreaterThan: string;
  dateTimeOutGreaterThanOrEqualTo: string;
  dateTimeOutGreaterThanOrEqualToOrNull: string;
  dateTimeOutIsNull: boolean;
  dateTimeOutLessThan: string;
  dateTimeOutLessThanOrEqualTo: string;
  departments: string[];
  employeeBadgeNumbers: number[];
  locations: string[];
  statusIn: TimesheetStatus[];
  statusNotIn: TimesheetStatus[];
  types: string[];
}
