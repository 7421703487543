import {Component, OnDestroy, OnInit} from '@angular/core';
import {EmployeeService} from '../../services/employee.service';
import {LeaveService} from '../../services/leave.service';
import {Employee} from '../../interfaces/employee';
import {LeaveSummary} from '../../interfaces/leave-summary';
import {LeaveType} from '../../interfaces/leave';
import {SessionService} from '../../services/session.service';
import {SessionData} from '../../interfaces/session';
import {Observable, Subject, Subscriber} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {EmployeePhotoService} from 'src/app/services/employee-photo.service';
import {EmployeePhoto} from 'src/app/interfaces/employee-photo';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ErrorService} from 'src/app/services/error.service';
import {ServiceConfigurationProperties} from '../../interfaces/service-configuration';
import {ServiceConfigurationService} from '../../services/service-configuration.service';

@Component({
  selector: 'app-employee-panel',
  templateUrl: './employee-panel.component.html',
  styleUrls: ['./employee-panel.component.less']
})
export class EmployeePanelComponent implements OnInit, OnDestroy {

  readonly LeaveType = LeaveType;

  employee = {} as Employee;
  leaveSummary: LeaveSummary[] | null = [];
  leaveShowResidualSummary: boolean | null = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.LeaveShowResidualSummary,true);
  employeePhoto = {} as EmployeePhoto;
  sessionData = this.sessionService.getData() as SessionData;
  destroy$ = new Subject<void>();
  selectedFile!: File;
  formGroup: FormGroup;

  constructor(private employeeService: EmployeeService,
              private employeePhotoService: EmployeePhotoService,
              private errorService: ErrorService,
              private leaveService: LeaveService,
              private sessionService: SessionService,
              private serviceConfigurationService: ServiceConfigurationService,
              formBuilder: FormBuilder) {
    this.formGroup = formBuilder.group({
      badgeNumber: this.sessionData.currentUser.id,
      photo: new FormControl(null)
    });
  }

  ngOnInit(): void {
    if (this.sessionData.currentUser.id) {
      this.loadEmployeeDetails(this.sessionData.currentUser.id);
    }
    this.subscribeForServiceConfigurations();
  }

  loadEmployeeDetails(employeeBadgeNumber: number): void {
    this.employeeService.getEmployee(employeeBadgeNumber)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(response =>
        this.employee = response
      );

    this.employeePhotoService.getEmployeePhoto(employeeBadgeNumber)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(response =>
        this.employeePhoto = response
      );

    this.leaveService.getLeaveSummary(employeeBadgeNumber)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        response =>
          this.leaveSummary = response,
        () =>
          this.leaveSummary = null
      );
  }

  onPhotoUpload(event: any): void {
    this.selectedFile = event.target.files[0];
    this.convertToBase64(this.selectedFile);
  }

  convertToBase64(file: File): void {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(file, subscriber);
    });
    observable.subscribe(response => {
      this.formGroup.patchValue({
        photo: response
      });
      this.uploadEmployeePhoto();
    });
  }

  readFile(file: File, subscriber: Subscriber<any>): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const image = new Image();
      image.src = reader.result as string;
      image.onload = () => {
        if (image.width > 140 || image.height > 170) {
          this.errorService.showError('employee.messages.imageDimensions');
        } else {
          subscriber.next(reader.result?.slice(23));
          subscriber.complete(); }
      };
    };

    reader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    };
  }

  subscribeForServiceConfigurations(): void {
    this.sessionService.getServiceConfigurationsObservable()
      .subscribe(
        configurations => {
          this.leaveShowResidualSummary = ServiceConfigurationService.getBooleanPropertyValue(
            ServiceConfigurationProperties.LeaveShowResidualSummary, this.leaveShowResidualSummary as boolean, configurations);
        }
      )
  }

  uploadEmployeePhoto(): void {
    const employeePhoto = this.formGroup.value;
    this.employeePhotoService.createEmployeePhoto(employeePhoto)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.refresh();
      });
  }

  refresh(): void {
    window.location.reload();
  }

  deleteEmployeePhoto(employeePhoto: EmployeePhoto): void{
    this.employeePhotoService.deleteEmployeePhoto(employeePhoto.id)
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        this.refresh();
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
