<p>This is the employees-list</p>
<mat-card>
  <mat-card-header>
    <div mat-card-avatar></div>
    <mat-card-title>Employees stuff</mat-card-title>
    <mat-card-subtitle>Some stuff here...</mat-card-subtitle>
  </mat-card-header>
  <div mat-card-image></div>
  <mat-card-content>
    <p>
      Bla bla bla...
    </p>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button>LIKE</button>
    <button mat-button>SHARE</button>
  </mat-card-actions>
</mat-card>
<br><br>
<p>after mat-card</p>
