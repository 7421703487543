import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ErrorService} from './error.service';
import {Document} from '../interfaces/document';

@Injectable({
  providedIn: 'root'
})
export class DocumentManagerService {

  constructor(private http: HttpClient) {
  }

  static documentMangerApi: string = environment.documentMangerServiceUrl + '/' + environment.documentMangerApiContext + '/' + 'documents';

  get(id: string): Observable<Document> {
    return this.http.get<Document>(DocumentManagerService.documentMangerApi + '/' + id)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  download(id: string): Observable<HttpResponse<Blob>> {
    return this.http.get(DocumentManagerService.documentMangerApi + '/download/' + id,
      {
        responseType: 'blob',
        observe: 'response'
      })
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  add(file: File, reportProgress = true): Observable<any> {
    if (file === undefined) {
      return of();
    }

    const formData = new FormData();
    formData.append('title', file.name);
    formData.append('file', file);
    return this.http.post<Document>(DocumentManagerService.documentMangerApi, formData, {
      reportProgress,
      observe: 'events'
    })
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  patch(document: Partial<Document>): Observable<Document> {
    if (document == null || document.id === undefined) {
      throwError('invalid input');
    }
    return this.http.patch<Document>(DocumentManagerService.documentMangerApi, document)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  delete(id: string): Observable<any> {
    return this.http.delete(DocumentManagerService.documentMangerApi + '/' + id)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }
}
