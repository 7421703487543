<div class="container">
  <div class="row">
    <div class="col-md-4">
      <div class="row">
        <app-employee-panel class="full-fill"></app-employee-panel>
      </div>
      <div class="row">
        <app-events-calendar class="full-fill p-3"></app-events-calendar>
      </div>
      <div class="row" *ngIf="dailyEventsEnabled">
        <app-events-list class="full-fill"></app-events-list>
      </div>
    </div>
    <div class="col-md-8">
      <div class="row" style="min-height: 20vh; max-height: 40vh">
        <app-quick-access-panel class="full-fill"></app-quick-access-panel>
      </div>
      <div class="row" style="min-height: 17vh">
        <div class="m-0 p-0 flex-grow-1">
          <app-user-notification-panel class="full-fill m-0 p-0 flex-grow-1"></app-user-notification-panel>
        </div>
      </div>
      <div class="row" style="min-height: 17vh">
        <app-feed-panel class="full-fill" [type] = "FeedType.Internal"></app-feed-panel>
      </div>
      <div class="row" style="min-height: 17vh">
        <app-feed-panel class="full-fill" [type] = "FeedType.External"></app-feed-panel>
      </div>
    </div>
  </div>
</div>
