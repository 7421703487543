import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LuxonDateFormatterPipe} from './pipes/luxonDateFormatter.pipe';


@NgModule({
  declarations: [
    LuxonDateFormatterPipe
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    LuxonDateFormatterPipe
  ]
})
export class UtilsModule {
}
