import {ChangeDetectorRef, Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {Subject, throwError} from 'rxjs';
import {LeaveService} from 'src/app/services/leave.service';
import {takeUntil} from 'rxjs/operators';
import {PageEvent} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {SessionService} from 'src/app/services/session.service';
import {SessionData} from 'src/app/interfaces/session';
import {LeaveFilter, LeaveType} from 'src/app/interfaces/leave';
import {TranslateService} from '@ngx-translate/core';
import {DocumentManagerService} from 'src/app/services/document-manager.service';
import * as saveAs from 'file-saver';
import {Page, PageFilter, Paginator} from "../../../interfaces/page";
import {
  ColumnsTranslationsConfig,
  GitsTableEvent,
  IColumnDef,
  NativeTableEvent,
  RowButton,
  TableEventSubscription
} from '@gits/ngx-tabulator-tables';
import {
  getGridLeaveRequestColumnDefs,
  GRID_LEAVE_REQUEST_REPORT_LABELS,
  GRID_LEAVE_REQUEST_REPORT_OPTIONS
} from './leave-manager-grid.config';
import {MatPaginatorOptions} from '@gits/ngx-tabulator-tables/lib/models/pagination-config.model';
import {PAGINATION_OPTIONS} from '../../../@utils/constants/constants';
import {TableApiService} from '@gits/ngx-tabulator-tables/lib/services/table-api.service';
import {DateTime, DateTime as LuxonDateTime} from "luxon";
import {MatDrawer} from '@angular/material/sidenav';
import {ReportTemplateType} from '../../../interfaces/report-template';
import {ReportFileType} from '../../../interfaces/report';
import {ErrorService} from '../../../services/error.service';
import {ReportService} from '../../../services/report.service';

@Component({
  selector: 'app-leave-manager',
  templateUrl: './leave-manager.component.html',
  styleUrls: ['./leave-manager.component.less']
})
export class LeaveManagerComponent implements OnDestroy {

  @ViewChild('documentsDrawer') documentsDrawer!: MatDrawer;
  readonly LeaveType = LeaveType;
  private destroy$ = new Subject<void>();
  private sessionData: SessionData = this.sessionService.getData();
  private leaveFilter: Partial<LeaveFilter> = {employeeBadgeNumberList: [this.sessionData.currentUser.id]};
  leavePaginator: Paginator = new Paginator();

  rowData: { [key: string]: any } | undefined;
  selectedRowComponent: { [key: string]: any } | undefined;

  @Input()
  get filter(): Partial<LeaveFilter> {
    return this.leaveFilter as LeaveFilter;
  }

  set filter(filter: Partial<LeaveFilter>) {
    if (filter === null || filter === undefined || Object.keys(filter).length === 0) {
      return;
    }
    this.leaveFilter = filter;
    this.loadLeaveRequestsReport();
  };

  @Input() showFilter: boolean = true;

  /*** START grid config */
  tableId: string = 'leaveRequestReport';
  gridApi: TableApiService | undefined;
  currentLang: string = '';
  columnLabels: ColumnsTranslationsConfig = GRID_LEAVE_REQUEST_REPORT_LABELS;
  gridOptions: any = GRID_LEAVE_REQUEST_REPORT_OPTIONS;
  paginationOptions: MatPaginatorOptions = PAGINATION_OPTIONS;
  gridData: any = {};
  rowControls: RowButton[] = [
    {
      el: 'span',
      title: 'Edit',
      label: 'buttons.VALIDATE',
      classList: ['table-btn', 'me-2', 'pe-2', 'bi', 'bi-pencil-fill'],
      callback: this.onRowEdit,
      bindToScope: this
    },
    {
      el: 'span',
      title: 'Download',
      label: 'buttons.VALIDATE',
      classList: ['table-btn', 'me-2', 'pe-2', 'bi', 'bi-download'],
      callback: this.onViewDocuments,
      bindToScope: this
    }
  ];
  gridSubscriptions: TableEventSubscription[] = [
    {
      tableId: this.tableId,
      observer: this.onRowClick,
      observerScope: this,
      subscribeToTableEvent: NativeTableEvent.rowClick
    },
    {
      tableId: this.tableId,
      observer: this.onDataSorting,
      observerScope: this,
      subscribeToTableEvent: NativeTableEvent.dataSorting
    },
  ];
  gridTypeColumnFormatter = (cell: any): string => {
    const value = cell.getValue();
    if (value === undefined || value === null) {
      return this.translateService.instant(`generic.unknown`);
    }

    return this.translateService.instant(`leave.types.${value}`);
  };

  gridDateFormatter = (cell: any, formatterParams: any): string => {
    let value = cell.getValue();
    if (value === undefined || value === null || value === '') {
      return '';
    }

    const rowData = cell.getRow().getData();
    const isAbsence = cell.getData()?.type === LeaveType.Absence;
    let formattedDate: string = LuxonDateTime.fromISO(value).toFormat('dd-MM-yyyy');
    if (isAbsence && formatterParams.useTimeFrom && rowData[formatterParams.useTimeFrom]) {
      formattedDate += ` ${rowData[formatterParams.useTimeFrom].slice(0, 5)}`;
    }
    return formattedDate;
  };

  minDateCalculation = (values: any[], data: any[], calcParams: any): number => {
    const min = values?.reduce((previousValue, currentValue) =>
      DateTime.fromISO(previousValue) > DateTime.fromISO(currentValue) ? currentValue : previousValue,
      values[0]);
    return min;
  };

  maxDateCalculation = (values: any[], data: any[], calcParams: any): number => {
    const min = values?.reduce((previousValue, currentValue) =>
        DateTime.fromISO(previousValue) < DateTime.fromISO(currentValue) ? currentValue : previousValue,
      values[0]);
    return min;
  };

  gridStatusFormatter = (cell: any) => {
    const value = cell.getValue();
    if (value === undefined || value === null) {
      return this.translateService.instant(`generic.unknown`);
    }

    return this.translateService.instant(`leave.statuses.${value}`);
  };
  columnDefs: IColumnDef[] = getGridLeaveRequestColumnDefs(this);

  /*** END-OF grid config */

  private pageFilter: PageFilter = {
    page: 1,
    size: this.paginationOptions.pageSize as number,
    unpaged: false
  };

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private documentManagerService: DocumentManagerService,
              private errorService: ErrorService,
              private leaveService: LeaveService,
              private reportService: ReportService,
              private router: Router,
              private sessionService: SessionService,
              public translateService: TranslateService) {

    this.sessionData = this.sessionService.getData();
    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(() => {
      if (this.gridApi) {
        // redraw table to apply the translations in formatters
        this.gridApi.getTableReference(this.tableId).redraw(true);
      }
    });
  }

  closeDocumentsDrawer() {
    this.documentsDrawer.close();
  }

  deselectRow() {
    this.selectedRowComponent?.deselect();
    this.selectedRowComponent = undefined;
  }

  downloadAttachment(documentId: string): void {
    this.documentManagerService.download(documentId)
      .subscribe(response => {
        const header = response.headers.get('Content-Disposition') || '';
        const filename = /filename=([^;]+)/ig.exec(header)?.pop();
        saveAs(response.body as Blob, filename);
      });
  }

  editEvent(id: number): void {
    this.router.navigate(['/leaves/leave/' + id]);
  }

  exportToExcel() {
    const params: Map<string, object> = new Map<string, object>();
    params.set('filter', this.leaveFilter);
    this.reportService.get(ReportTemplateType.HumanResourcesLeaves, params, ReportFileType.Xlsx)
      .subscribe(response => {
          const contentDisposition = response.headers.get('content-disposition');
          let filename;
          if (contentDisposition != null) {
            filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          }
          saveAs(response.body as Blob, filename);
        },
        error => this.errorService.showError('reports.messages.unableToGenerate', {error}));

    return;
  }

  exportToPdf(): void {
    const params: Map<string, object> = new Map<string, object>();
    params.set('filter', this.leaveFilter);

    this.reportService.get(ReportTemplateType.HumanResourcesLeaves, params)
      .subscribe(
        response => {
          const contentDisposition = response.headers.get('content-disposition');
          let filename;
          if (contentDisposition != null) {
            filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          }
          saveAs(response.body as Blob, filename);
        },
        error => {
          this.errorService.showError('reports.messages.unableToGenerate', {error})
        })
  }

  loadLeaveRequestsReport(page?: Partial<PageFilter>): void {
    const defaultPage =
      {
        page: this.leavePaginator.page + 1,
        size: 25
      };
    const pageFilter = page ? page : defaultPage;
    this.leaveService.getLeaveRequestsReport(this.leaveFilter, pageFilter)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (leavesPage: Page) => {
          if (leavesPage) {
            const columnCalcModule = this.gridApi?.getTableReference(this.tableId).module('columnCalcs');
            if (this.leaveFilter.employeeBadgeNumberList?.length === 1) {
              columnCalcModule.reinitializeCalcs();
            } else {
              columnCalcModule.removeCalcs();
            }
            this.gridData = leavesPage;
            this.leavePaginator.page = leavesPage.number
            this.changeDetectorRef.markForCheck();
          }
        },
        error => {
          return throwError(this.translateService.instant('leave.messages.errLoading', {error}));
        }
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onDataSorting(event: GitsTableEvent) {
    // todo backend sorting; when ready link sorting params from tabulator to the backend sorting functionality
  }

  // todo we need to add pagination here
  onFilterChanged(filter: Partial<LeaveFilter>): void {
    this.leaveFilter = filter;
    this.loadLeaveRequestsReport();
  }

  onRowClick(event: GitsTableEvent) {
    if (event) {
      this.selectRowByLeaveId(event.tableEventData);
      this.openDocumentsDrawer(event.tableEventData);
    }
  }

  onViewDocuments(rowData: { [key: string]: any }) {
    this.selectRowByLeaveId(rowData);
    this.openDocumentsDrawer(rowData);
  }

  onMatDrawerOpenedChanged(isOpened: boolean) {
    if (!isOpened) {
      this.deselectRow();
    }
  }

  onTableApiAvailable(gridApi: TableApiService) {
    this.gridApi = gridApi;
  }

  onPageChanged(newPage: PageEvent) {
    this.loadLeaveRequestsReport({
      page: newPage.pageIndex + 1,
      size: newPage.pageSize,
      unpaged: false
    });
  }

  onRowEdit(rowData: any) {
    this.editEvent(rowData.leaveId);
  }

  openDocumentsDrawer(rowData: { [key: string]: any }) {
    this.rowData = rowData;
    this.documentsDrawer.open();
  }

  selectRowByLeaveId(rowData: { [key: string]: any }) {
    const tabulator = this.gridApi?.getTableReference(this.tableId);
    const clickedRowComponent = tabulator.getRows().filter((rc: any) => rc.getData().leaveId === rowData.leaveId);

    if (clickedRowComponent.length === 1) {
      this.selectedRowComponent = clickedRowComponent.pop();
      this.selectedRowComponent?.select();
    }
  }

  // todo decide if we need to implement user privileges access
  userHasPrivileges(privileges: string[]): boolean {
    return true;
  }
}
