import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CustomMatPaginatorIntl} from 'src/app/config/custom-mat-paginator-int';
import {AggregatedSummary, ReportFileType, ReportFilter} from 'src/app/interfaces/report';
import {
  ColumnsTranslationsConfig,
  GitsTableEvent,
  IColumnDef,
  NativeTableEvent,
  RowButton,
  TableEventSubscription
} from '@gits/ngx-tabulator-tables';
import {
  getGridLeaveRequestColumnDefs,
  GRID_LEAVE_REQUEST_REPORT_LABELS,
  GRID_LEAVE_REQUEST_REPORT_OPTIONS
} from './aggregated-summary-grid.config';
import {TableApiService} from "@gits/ngx-tabulator-tables/lib/services/table-api.service";
import {MatPaginatorOptions} from "@gits/ngx-tabulator-tables/lib/models/pagination-config.model";
import {PAGINATION_OPTIONS} from "../../../@utils/constants/constants";
import {TranslateService} from "@ngx-translate/core";
import {Page, PageFilter, Paginator} from "../../../interfaces/page";
import {ReportService} from "../../../services/report.service";
import {SessionService} from "../../../services/session.service";
import {SessionData} from "../../../interfaces/session";
import {ReportTemplateType} from "../../../interfaces/report-template";
import * as saveAs from "file-saver";
import {ErrorService} from "../../../services/error.service";
import {UserRole} from "../../../interfaces/user";
import {Department} from "../../../interfaces/department";
import {Employee, EmployeeFilter} from "../../../interfaces/employee";
import {AuthenticationService} from "../../../services/authentication.service";
import {DepartmentService} from "../../../services/department.service";
import {EmployeeService} from "../../../services/employee.service";
import {MatSelect} from "@angular/material/select";
import {MatOption} from "@angular/material/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DateTimeService} from "../../../services/date-time.service";
import {endOfMonth, startOfMonth} from "date-fns";

@Component({
  selector: 'app-aggregated-summary-report',
  templateUrl: './aggregated-summary-report.component.html',
  styleUrls: ['./aggregated-summary-report.component.less'],
  providers: [{
    provide: MatPaginatorIntl,
    useClass: CustomMatPaginatorIntl
  }]
})
export class AggregatedSummaryReportComponent implements OnDestroy, OnInit {

  readonly ALL_VALUE = 'ALL';

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  aggregatedSummary: AggregatedSummary [] = [];
  employees: Employee[] = [];
  departments: Department[] = [];
  formGroup: FormGroup;
  yearMax = new Date().getFullYear();
  currentMonth = new Date().getMonth() + 1;
  selectedDepartment = [];
  selectedEmployees = [];
  referenceDate = new Date(this.yearMax, 12, 0);
  private sessionData: SessionData = this.sessionService.getData();
  private reportFilter: Partial<ReportFilter> = {employeeBadgeNumberList: [this.sessionData.currentUser.id]};
  isError = false;
  private destroy$ = new Subject<void>();
  dataSource = new MatTableDataSource<AggregatedSummary>(this.aggregatedSummary);
  displayedColumns: string[] = ['badgeNumber', 'lastName', 'firstName', 'position', 'pid', 'startDate', 'allowedDays',
    'residualDays', 'totalDays', 'usedDays', 'availableDays', 'allowedDaysOnReferenceDate', 'department', 'endDate'];
  reportPaginator: Paginator = new Paginator();


  /*** START grid config */
  tableId: string = 'leaveRequestReport';
  gridApi: TableApiService | undefined;
  currentLang: string = '';
  columnLabels: ColumnsTranslationsConfig = GRID_LEAVE_REQUEST_REPORT_LABELS;
  gridOptions: any = GRID_LEAVE_REQUEST_REPORT_OPTIONS;
  paginationOptions: MatPaginatorOptions = PAGINATION_OPTIONS;
  gridData: any = {};
  rowControls: RowButton[] = [
    {
      el: 'span',
      title: 'Detailed report',
      label: 'buttons.VALIDATE',
      classList: ['table-btn', 'me-2', 'pe-2', 'bi', 'bi-pencil-fill'],
      callback: this.onRowEdit,
      bindToScope: this
    }
  ];
  gridSubscriptions: TableEventSubscription[] = [
    {
      tableId: this.tableId,
      observer: this.onRowClick,
      observerScope: this,
      subscribeToTableEvent: NativeTableEvent.rowClick
    },
    {
      tableId: this.tableId,
      observer: this.onDataSorting,
      observerScope: this,
      subscribeToTableEvent: NativeTableEvent.dataSorting
    },
  ];

  columnDefs: IColumnDef[] = getGridLeaveRequestColumnDefs(this);

  /*** END-OF grid config */

  constructor(private authenticationService: AuthenticationService,
              private changeDetectorRef: ChangeDetectorRef,
              private dateTimeService: DateTimeService,
              private departmentService: DepartmentService,
              private employeeService: EmployeeService,
              private errorService: ErrorService,
              private formBuilder: FormBuilder,
              private reportService: ReportService,
              private sessionService: SessionService,
              public translateService: TranslateService) {
    this.sessionData = this.sessionService.getData();
    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(() => {
      if (this.gridApi) {
        // redraw table to apply the translations in formatters
        this.gridApi.getTableReference(this.tableId).redraw(true);
      }
    });
    this.formGroup = this.formBuilder.group({
      employeeBadgeNumbers: ['', Validators.required],
      departments: ['', Validators.required],
      dateRange: this.formBuilder.group({
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date())
      })
    });
  }

  ngOnInit() {
    this.getDepartmentsAndEmployeeList();
  }

  onPageChanged(newPage: PageEvent) {
    this.loadReport({
      page: newPage.pageIndex + 1,
      size: newPage.pageSize,
      unpaged: false
    });
  }

  onDataSorting(event: GitsTableEvent) {
    // todo backend sorting; when ready link sorting params from tabulator to the backend sorting functionality
  }

  onTableApiAvailable(gridApi: TableApiService) {
    this.gridApi = gridApi;
  }

  onRowClick(event: GitsTableEvent) {
/*    if (event) {
      this.selectRowByLeaveId(event.tableEventData);
    }*/
  }

  onRowEdit(rowData: any) {
    // this.editEvent(rowData.leaveId);
  }

/*  editEvent(id: number): void {
    this.router.navigate(['/leaves/leave/' + id]);
  }*/

  loadReport(page?: Partial<PageFilter>): void {
    const defaultPage =
      {
        page: this.reportPaginator.page + 1,
        size: 25
      };
    const pageFilter = page ? page : defaultPage;
    this.reportService.getAggregatedSummary(this.reportFilter, pageFilter)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        (reportPage: Page) => {
          if (reportPage) {
            const columnCalcModule = this.gridApi?.getTableReference(this.tableId).module('columnCalcs');
            if (this.reportFilter.employeeBadgeNumberList?.length === 1) {
              columnCalcModule.reinitializeCalcs();
            } else {
              columnCalcModule.removeCalcs();
            }
            this.gridData = reportPage;
            this.reportPaginator.page = reportPage.number
            this.changeDetectorRef.markForCheck();
          }
        },
        error => {
          this.isError = true;
        }
      );
  }

  applyFilter(): void{
 /*   this.reportFilter = {
      startDateGreaterThanOrEqualTo: "2020-01-01",
      endDateLessThanOrEqualTo: "2025-12-31"
    };*/
    this.reportFilter.employeeBadgeNumberList = this.selectedEmployees;
    this.reportFilter.startDateGreaterThanOrEqualTo = this.dateTimeService.formatDate(this.formGroup.get('dateRange.startDate')?.value, 'yyyy-MM-dd');
    this.reportFilter.endDateLessThanOrEqualTo = this.dateTimeService.formatDate(this.formGroup.get('dateRange.endDate')?.value, 'yyyy-MM-dd');
    this.loadReport();
  }

  departmentChange(isChanged: boolean): void {
    if (isChanged) {
      if (this.formGroup.controls.departments.value[0] === this.ALL_VALUE) {
        this.selectedDepartment = this.formGroup.controls.departments.value.slice(1);
      } else {
        this.selectedDepartment = this.formGroup.controls.departments.value;
      }
      this.filterEmployeeList(this.selectedDepartment);
      this.applyFilter();
    }
  }

  employeeChange(isChanged: boolean): void {
    if (isChanged) {
      if (this.formGroup.controls.employeeBadgeNumbers.value[0] === this.ALL_VALUE) {
        this.selectedEmployees = this.formGroup.controls.employeeBadgeNumbers.value.slice(1);
      } else {
        this.selectedEmployees = this.formGroup.controls.employeeBadgeNumbers.value;
      }
      this.applyFilter();
    }
  }

  dateRangeChange(isChanged: boolean): void {
    if (isChanged) {
      this.reportFilter.startDateGreaterThanOrEqualTo = this.dateTimeService.formatDate(this.formGroup.get('dateRange.startDate')?.value, 'yyyy-MM-dd');
      this.reportFilter.endDateLessThanOrEqualTo = this.dateTimeService.formatDate(this.formGroup.get('dateRange.endDate')?.value, 'yyyy-MM-dd');
      this.applyFilter();
    }
  }

  toggleAllSelection(matSelect: MatSelect): void {
    const isSelected: boolean = matSelect.options
      .filter((item: MatOption) => item.value === this.ALL_VALUE)
      .map((item: MatOption) => item.selected)[0];

    if (isSelected) {
      matSelect.options.forEach((item: MatOption) => item.select());
    } else {
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  getDepartmentsAndEmployeeList(): void {
    if (this.authenticationService.currentUserHasRole(UserRole.departmentManager) &&
      !this.authenticationService.currentUserHasRole(UserRole.humanResourceManager)) {
      this.departmentService.filter({
        managerBadgeNumber: this.sessionData.currentUser.id,
        active: true})
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe((managedDepartments: Department[]) => {
          this.departments = this.departmentService.getFlatDepartmentTree(managedDepartments);
          this.filterEmployeeList(this.departments.map(department => department.name));
          // this.selectDefaultDepartment();
        });
    } else if (this.authenticationService.currentUserHasRole(UserRole.humanResourceManager)) {
      this.employeeService.getActiveEmployees()
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe((employee: Employee[]) =>
          this.departmentService.filter({active: true})
            .subscribe((departmentsList: Department[]) => {
              this.departments = this.departmentService.getFlatDepartmentTree(departmentsList);
              this.filterEmployeeList(this.formGroup.controls.departments.value);
              // this.selectDefaultDepartment();
            }));
    } else {
      this.employeeService.getEmployee(this.sessionData.currentUser.id)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe((employee: Employee) => {
          this.departmentService.filter({nameExact: employee.departmentName})
            .subscribe((departments: Department[]) => {
              this.departments = departments;
              // this.selectDefaultDepartment();
            });
          this.employees = [employee];
        });
    }
  }

  filterEmployeeList(departmentList: string[]): void {
    // this is only useful for managers, for user role a single employee is set by getDepartmentsAndEmployeeList()
    if (this.authenticationService.currentUserHasRole(UserRole.departmentManager) ||
      this.authenticationService.currentUserHasRole(UserRole.humanResourceManager)) {
      const employeeFilter: Partial<EmployeeFilter> = {
        departmentNameList: departmentList,
        recurseDepartments: true
      };
      this.employeeService.getActiveEmployees(employeeFilter)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe((response: Employee[]) => {
          this.employees = response;

          if (this.formGroup.controls.employeeBadgeNumbers.value.length > 0) {
            // remove employees not part of the filter results from employee selection list
            this.formGroup.controls.employeeBadgeNumbers.patchValue(this.formGroup.controls.employeeBadgeNumbers.value.filter(
              (badgeNumber: any) => this.employees.some(emp => emp.badgeNumber === badgeNumber) || badgeNumber === this.ALL_VALUE
            ));
            if (this.formGroup.controls.employeeBadgeNumbers.value.some((id: any) => id === this.ALL_VALUE)) {
              this.formGroup.controls.employeeBadgeNumbers.patchValue([this.ALL_VALUE, ...this.employees.map(e=>e.badgeNumber)]);
            }
          }
          this.applyFilter()
        });
    } else {
      this.applyFilter()
    }
  }

  exportToExcel() {
    const params: Map<string, object> = new Map<string, object>();
    params.set('filter', this.reportFilter);
    this.reportService.get(ReportTemplateType.HumanResourcesAggregatedSummary, params, ReportFileType.Xlsx)
      .subscribe(response => {
          const contentDisposition = response.headers.get('content-disposition');
          let filename;
          if (contentDisposition != null) {
            filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          }
          saveAs(response.body as Blob, filename);
        },
        error => this.errorService.showError('reports.messages.unableToGenerate', {error}));

    return;
  }

  exportToPdf(): void {
    const params: Map<string, object> = new Map<string, object>();
    params.set('filter', this.reportFilter);

    this.reportService.get(ReportTemplateType.HumanResourcesAggregatedSummary, params)
      .subscribe(
        response => {
          const contentDisposition = response.headers.get('content-disposition');
          let filename;
          if (contentDisposition != null) {
            filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          }
          saveAs(response.body as Blob, filename);
        },
        error => {
          this.errorService.showError('reports.messages.unableToGenerate', {error})
        })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
