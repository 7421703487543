import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self
} from '@angular/core';
import {ControlValueAccessor, FormControl, NgControl, ValidationErrors, Validator} from '@angular/forms';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'gits-select-autocomplete',
  templateUrl: './gits-select-autocomplete.component.html',
  styleUrls: ['./gits-select-autocomplete.component.less'],
  providers: [
    {provide: MatFormFieldControl, useExisting: GitsSelectAutocompleteComponent}
  ]
})
export class GitsSelectAutocompleteComponent implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<string>, Validator {

  static nextId = 0;

  private destroy$ = new Subject<void>();
  // ID of the currently selected option
  private optionIdSelected = '-1';
  // the list options
  private optionsList: any[] = [];
  // the item in the list to display when the field is optional an no actual item needs to be selected
  private optionalValue = {badgeNumber: -1, fullName: this.translateService.instant('generic.optional')};
  private _optional = false;
  private _optionalTextTranslateKey = 'generic.optional';
  private _placeholder = '';
  private _required = false;

  // ControlValueAccessor
  touched = false;
  _disabled = false;
  // MatFormFieldControl
  stateChanges = new Subject<void>();
  focused = false;
  // Custom
  valid = false;
  inputControl = new FormControl();
  filteredOptions = [] as any[];
  controlType = 'gits-select-autocomplete';

  constructor(@Optional() @Self() public ngControl: NgControl, // MatFormFieldControl
              private elementRef: ElementRef<HTMLElement>, // MatFormFieldControl
              private translateService: TranslateService) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  defaultFormatOptionFn = (option: any): string => {
    return option.toString();
  }

  defaultFilterOptionsFn = (filter: string, options: any[]): any[] => {
    if (options === null || options === undefined || options.length === 0) {
      return [];
    }
    return options.filter(option => option.toString().includes(filter));
  }

  onChange = (option: any) => {
  }

  onTouched = () => {
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.setDisabledState(this._disabled);
    this.stateChanges.next();
  }

  get empty(): boolean {
    return !this.inputControl.value;
  }

  get errorState(): boolean {
    this.ngControl.control?.setErrors(this.inputControl.errors);
    return this.inputControl.invalid && this.inputControl.dirty;
  }

  @Input()
  get value(): string {
    return this.inputControl.value;
  }
  set value(optionId: string) {
    this.writeValue(optionId);
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input() set options(list: any[]) {
    this.optionsList = list;
    this.optionsList = this.toggleOptionalOption(this._optional, list);
    this.selectOption(this.optionIdSelected);
  }

  @Input()
  get optional(): boolean {
    return this._optional;
  }

  set optional(value: boolean) {
    this._optional = coerceBooleanProperty(value);
    this.optionsList = this.toggleOptionalOption(this._optional, this.optionsList);
    this.selectOption(this.optionIdSelected);
    this.stateChanges.next();
  }

  @Input()
  get optionalTextTranslateKey(): string {
    return this._optionalTextTranslateKey;
  }

  set optionalTextTranslateKey(value: string) {
    this._optionalTextTranslateKey = value;
    this.optionalValue = {
      badgeNumber: -1,
      fullName: ''
    };
    this.translateService.get(this._optionalTextTranslateKey).subscribe(
      text =>
      this.optionalValue = {
        badgeNumber: -1,
        fullName: text
      }
    );
  }

  // Custom
  @Input()
  formatOptionFn: ((option: any) => string) = this.defaultFormatOptionFn;

  @Input()
  filterOptionsFn: ((filter: string, options: any[]) => any[]) = this.defaultFilterOptionsFn;

  @Output()
  optionSelected = new EventEmitter<any>();

  @HostBinding()
  id = this.controlType + `-${GitsSelectAutocompleteComponent.nextId++}`;

  @Input()
  'aria-describedby' = ''; // userAriaDescribedBy renamed due to linting errors

  setDescribedByIds(ids: string[]): void {
    if (this.elementRef !== null && this.elementRef.nativeElement) {
      const controlElement = this.elementRef.nativeElement
        .querySelector('.' + this.controlType)!;
      controlElement?.setAttribute('aria-describedby', ids.join(' '));
    }
  }

  onContainerClick(event: MouseEvent): void {
    if (this.elementRef !== null &&
      this.elementRef.nativeElement !== null &&
      (event.target as Element).tagName.toLowerCase() !== 'input') {
      const inputSelector = this.elementRef.nativeElement.querySelector('input');
      if (inputSelector) {
        inputSelector.focus();
      }
    }
  }

  ngOnInit(): void {
    this.inputControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(optionFilter =>
        this.filteredOptions = this.filterOptionsFn(optionFilter, this.optionsList)
      );
    const validators = this.ngControl.control?.validator;
    const asyncValidators = this.ngControl.control?.asyncValidator;
    this.inputControl.setValidators(validators ? validators : null);
    this.inputControl.setAsyncValidators(asyncValidators ? asyncValidators : null);
    this.inputControl.updateValueAndValidity();
  }

  // ControlValueAccessor
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(optionId: string): void {
    this.selectOption(optionId);
  }

  setDisabledState(disabled: boolean): void {
    this._disabled = disabled;
    if (this._disabled) {
      this.inputControl.disable();
    } else {
      this.inputControl.enable();
    }
    this.stateChanges.next();
  }

  // MatFormFieldControl
  // Custom
  onOptionSelected(value: any): void {
    this.markAsTouched();
    this.optionSelected.emit(value);
    this.onChange(value);
  }

  onFocus(): void {
    this.filteredOptions = this.optionsList;
    this.focused = true;
    this.stateChanges.next();
  }

  onFocusOut(): void {
    this.focused = false;
    // this.validate(this.inputControl);
    this.markAsTouched();
    this.selectOption(this.inputControl.value?.badgeNumber);
    // this.inputControl.updateValueAndValidity();
    this.optionSelected.emit(this.inputControl.value);
    // this.stateChanges.next();
  }

  private markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
      this.stateChanges.next();
    }
  }

  private markValid(valid: boolean): boolean {
    if (valid !== this.valid) {
      this.valid = valid;
      this.stateChanges.next();
      this.markAsTouched();
    }

    return this.valid;
  }

  private toggleOptionalOption(optional: boolean, list: any[]): any[] {
    if (optional) {
      return [this.optionalValue, ...list];
    }
    return list?.filter(option => option.badgeNumber !== -1);
  }

  private selectOption(optionId: string): void {
    let optionSelected = null;
    // accept values when list is empty, option will be selected when set options() is used
    this.optionIdSelected = optionId;
    if (this.optionsList.length > 0 && this.optionIdSelected !== '' && this.optionIdSelected !== undefined) {
      optionSelected = this.findOption(optionId);
      if (optionSelected !== null && optionSelected !== undefined) {
        this.markValid(true);
      } else {
        this.markValid(false);
      }
    }
    this.inputControl.setValue(optionSelected);
  }

  // NG_VALIDATORS
  validate({value}: FormControl): ValidationErrors | null {
    if (value?.badgeNumber) {
      value = value?.badgeNumber;
    }
    let isValid = this.optionsList.some(option => option.badgeNumber === Number(value));
    isValid = isValid && this.inputControl.valid;
    const invalidError = {invalid: true};
    return !isValid ? {...this.inputControl?.errors, ...invalidError} : this.inputControl?.errors;
  }

  private findOption(optionId: string): any {
    return this.optionsList.find(option => option.badgeNumber === Number(optionId));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.stateChanges.complete();
  }
}
