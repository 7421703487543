import {Component, OnDestroy, OnInit} from '@angular/core';
import {SessionService} from '../../../services/session.service';
import {SessionData} from '../../../interfaces/session';
import {FormControl, Validators} from '@angular/forms';
import {Location} from '@angular/common';
import {EmployeeAddressService} from '../../../services/employee-address.service';
import {switchMap, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ErrorService} from '../../../services/error.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {EmployeeAddress} from '../../../interfaces/employee-address';
import {User} from '../../../interfaces/user';
import {ServiceConfigurationService} from '../../../services/service-configuration.service';
import {ServiceConfigurationProperties} from '../../../interfaces/service-configuration';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.less']
})
export class UserProfileComponent implements OnDestroy {

  actionInProgress = false;
  destroy$ = new Subject<void>();
  sessionData: SessionData;
  passwordControl = new FormControl('', [Validators.required]);
  userPasswordPolicyMinDigit: number = ServiceConfigurationService.getNumberPropertyValue(
    ServiceConfigurationProperties.UserPasswordPolicyMinDigit, 0) as number;
  userPasswordPolicyMinLength: number = ServiceConfigurationService.getNumberPropertyValue(
    ServiceConfigurationProperties.UserPasswordPolicyMinLength, 0) as number;
  userPasswordPolicyMinLower: number = ServiceConfigurationService.getNumberPropertyValue(
    ServiceConfigurationProperties.UserPasswordPolicyMinLower, 0) as number;
  userPasswordPolicyMinSpecial: number = ServiceConfigurationService.getNumberPropertyValue(
    ServiceConfigurationProperties.UserPasswordPolicyMinSpecial, 0) as number;
  userPasswordPolicyMinUpper: number = ServiceConfigurationService.getNumberPropertyValue(
    ServiceConfigurationProperties.UserPasswordPolicyMinUpper, 0) as number;
  userPasswordPolicySpecialList: string | null = ServiceConfigurationService.getPropertyValue(
    ServiceConfigurationProperties.UserPasswordPolicySpecialList);

  constructor(private authenticationService: AuthenticationService,
              private employeeAddressService: EmployeeAddressService,
              private errorService: ErrorService,
              private location: Location,
              private sessionService: SessionService,
              private serviceConfigurationService: ServiceConfigurationService) {
    this.sessionData = this.sessionService.getData();
  }

  updatePassword(): void {
    this.setActionInProgress(true);
    this.employeeAddressService.patch({
      badgeNumber: this.sessionData.currentUser.id,
      passwordPayslip: this.passwordControl.value
    })
      .pipe(
        takeUntil(this.destroy$),
        switchMap((employeeAddress: EmployeeAddress) => {
          this.sessionService.removeData();
          return this.authenticationService.authenticateWithRealm(employeeAddress.badgeNumber, this.passwordControl.value);
        })
      )
      .subscribe((user: User) => {
          if (user && user.id) {
            this.errorService.showInfo('user.messages.passwordUpdated', {
              id: user.id,
              fullName: user.lastName + ' ' + user.firstName
            });
            this.navigateBack();
          } else {
            this.errorService.showError('security.messages.errAuthenticationFailure');
          }
          this.setActionInProgress(false);
        },
        error => {
          this.errorService.showError('user.messages.errPasswordUpdate', {
            error,
            id: this.sessionData.currentUser.id,
            fullName: this.sessionData.currentUser.lastName + ' ' + this.sessionData.currentUser.firstName
          });
          this.setActionInProgress(false);
        });
  }

  navigateBack(): void {
    this.location.back();
  }

  private setActionInProgress(value: boolean): void {
    this.actionInProgress = value;
    if (value) {
      this.passwordControl.disable();
    } else {
      this.passwordControl.enable();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
