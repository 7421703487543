import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private snackBar: MatSnackBar,
              private translateService: TranslateService) {
    ErrorService.translateService = translateService;
  }

  private static translateService: TranslateService;

  /**
   * Manages the API errors and extracts the error message. It is typically used with the catchError RxJS pipe operator
   * @param error
   * @returns New exception using the extracted error message
   */
  static handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage;
    if (error.status === 0) {
      errorMessage = ErrorService.translateService.instant('generic.messages.errBackendNotAvailable');
    } else {
      if (error.error?.message) {
        errorMessage = error.error.message;
      } else {
        errorMessage = error.status + ' - ' + error.statusText;
      }
    }
    return throwError(errorMessage);
  }

  /**
   * Displays an information notification box on the screen
   * @param messageOrTranslationKey The text message to display or a translation key which will be looked-up in the
   * internationalization files
   * @param translateParams (optional) Translation parameters, if required
   * @see src/assets/i18n/*.json
   */
  showInfo(messageOrTranslationKey: string, translateParams?: object): void {
    ErrorService.translateService.get(messageOrTranslationKey, translateParams)
      .subscribe(message =>
    this.snackBar.open(message,
      'X',
      {
        duration: 5 * 1000
      })
      );
  }

  /**
   * Displays an error notification box on the screen
   * @param messageOrTranslationKey The text message to display or a translation key which will be looked-up in the
   * internationalization files
   * @param translateParams (optional) Translation parameters, if required
   * @see src/assets/i18n/*.json
   */
  showWarning(messageOrTranslationKey: string, translateParams?: object): void {
    ErrorService.translateService.get(messageOrTranslationKey, translateParams)
      .subscribe(message =>
        this.snackBar.open(message,
          'X',
          {
            duration: 7 * 1000,
            panelClass: 'light-background'
          })
      );
  }

  /**
   * Displays an error notification box on the screen
   * @param messageOrTranslationKey The text message to display or a translation key which will be looked-up in the
   * internationalization files
   * @param translateParams (optional) Translation parameters, if required
   * @see src/assets/i18n/*.json
   */
  showError(messageOrTranslationKey: string, translateParams?: object): void {
    ErrorService.translateService.get(messageOrTranslationKey, translateParams)
      .subscribe(message =>
        this.snackBar.open(message,
          'X',
          {
            duration: 7 * 1000,
            panelClass: 'light-warn-background'
          })
      );
  }
}
