import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-employment-certificate-dialog',
  templateUrl: './employment-certificate-dialog.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class EmploymentCertificateDialog {

  reason: string = '';

  /*constructor(
    public dialogRef: MatDialogRef<EmploymentCertificateDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}*/

  constructor(
    public dialogRef: MatDialogRef<EmploymentCertificateDialog>,
    private translateService: TranslateService) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
