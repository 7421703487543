import {Pipe, PipeTransform} from '@angular/core';
import {DateTime} from 'luxon';

@Pipe({name: 'luxonDateFormatter'})
export class LuxonDateFormatterPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let result = '';

    if (value && DateTime.fromISO(value) && args.length === 1) {
      result = DateTime.fromISO(value).toFormat(args[0]);
    }

    return result;
  }
}
