import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ErrorService} from './error.service';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {ReportFileType} from "../interfaces/report";

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  static apiUrl: string = environment.backendServiceUrl + '/' + environment.backendApiContext + '/' + 'reports';

  constructor(private http: HttpClient, ) { }

  get(name: string, params: Map<string, object>, fileType: ReportFileType = ReportFileType.Pdf): Observable<HttpResponse<Blob>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('name', name);
    httpParams = httpParams.append('type', fileType);
    if (params !== undefined) {
      for (const [key, value] of params) {
        httpParams = httpParams.append(key, JSON.stringify(value));
      }
    }

    return this.http.get(ReportService.apiUrl + '/', {
      params: httpParams,
      responseType: 'blob',
      observe: 'response'})
      .pipe(
        catchError(ErrorService.handleError)
      );
  }
}
