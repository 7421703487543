import {LeaveDocument} from './leave-document';

export interface Leave {
  id: number;
  type: LeaveType;
  employeeBadgeNumber: number;
  approverBadgeNumber: number | null;
  substituteBadgeNumber: number | null;
  hrApproverBadgeNumber: number | null;
  startDate: string | Date | null;
  endDate: string | Date| null;
  startTime: string | null;
  endTime: string | null;
  status: LeaveStatus;
  details: string | null;
  documentsList: LeaveDocument[];
}

export interface LeaveFilter {
  approverBadgeNumber: number;
  employeeBadgeNumber: number;
  employeeBadgeNumberList: number[];
  substituteBadgeNumber: number;
  startDateLessThan: string;
  startDateLessThanOrEqualTo: string;
  startDateGreaterThan: string;
  startDateGreaterThanOrEqualTo: string;
  endDateLessThan: string;
  endDateLessThanOrEqualTo: string;
  endDateGreaterThan: string;
  endDateGreaterThanOrEqualTo: string;
  statusGreaterThan: LeaveStatus;
  statusIn: LeaveStatus[];
  statusLessThan: LeaveStatus;
  typeIn: LeaveType[];
  typeNotIn: LeaveType[];
}

export enum LeaveType {
  Paid = 'Paid',
  Unpaid = 'Unpaid',
  Medical = 'Medical',
  PublicHoliday = 'PublicHoliday',
  Maternity = 'Maternity',
  Paternity = 'Paternity',
  Bereavement = 'Bereavement',
  Absence = 'Absence',
  ChildCare = 'ChildCare',
  Compensatory = 'Compensatory',
  Business = 'Business'
}

// Keep the logical flow order of the statuses for index comparision
export enum LeaveStatus {
  New = 'New',
  Planned = 'Planned',
  SubstituteApproved = 'SubstituteApproved',
  SubstituteRejected = 'SubstituteRejected',
  ManagerApproved = 'ManagerApproved',
  ManagerRejected = 'ManagerRejected',
  HumanResourcesApproved = 'HumanResourcesApproved',
  HumanResourcesRejected = 'HumanResourcesRejected',
  Finalized = 'Finalized',
  Canceled = 'Canceled'
}
