import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LeaveFilter, LeaveStatus, LeaveType} from 'src/app/interfaces/leave';
import {endOfMonth, startOfMonth} from 'date-fns';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SessionService} from '../../services/session.service';
import {SessionData} from '../../interfaces/session';
import {Subject} from 'rxjs';
import {UserRole} from '../../interfaces/user';
import {EmployeeService} from '../../services/employee.service';
import {AuthenticationService} from '../../services/authentication.service';
import {DepartmentService} from '../../services/department.service';
import {Employee, EmployeeFilter, EmployeeSelectionType} from '../../interfaces/employee';
import {Department} from '../../interfaces/department';
import {DateTimeService} from '../../services/date-time.service';
import {MatSelect} from '@angular/material/select';
import {MatOption} from '@angular/material/core';
import {takeUntil} from 'rxjs/operators';
import {ServiceConfigurationService} from '../../services/service-configuration.service';
import {ServiceConfigurationProperties} from '../../interfaces/service-configuration';

@Component({
  selector: 'app-leave-filter',
  templateUrl: './leave-filter.component.html',
  styleUrls: ['./leave-filter.component.less']
})
export class LeaveFilterComponent implements OnDestroy, OnInit {

  readonly LeaveType = LeaveType;
  readonly LeaveStatus = LeaveStatus;
  readonly ALL_VALUE = 'ALL';
  readonly defaultTypeSelection = [this.ALL_VALUE, ...Object.values(LeaveType)];
  readonly defaultStatusSelection = [this.ALL_VALUE, ...Object.values(LeaveStatus)];

  private destroy$ = new Subject<void>();
  private leaveFilterDefaultEmployeeSelection: EmployeeSelectionType | null = EmployeeSelectionType.Self;
  private sessionData: SessionData = this.sessionService.getData();

  employees: Employee[] = [];
  departments: Department[] = [];
  formGroup: FormGroup;

  @Input()
  lockTypeSelection: boolean = false;

  @Input()
  set typeSelection(typeList: string[] | null) {
    if (typeList !== null) {
      this.formGroup.controls.types.setValue(typeList);
    }
  }

  @Output()
  selectionChanged = new EventEmitter<Partial<LeaveFilter>>();

  constructor(private authenticationService: AuthenticationService,
              private dateTimeService: DateTimeService,
              private departmentService: DepartmentService,
              private employeeService: EmployeeService,
              private formBuilder: FormBuilder,
              private serviceConfigurationService: ServiceConfigurationService,
              private sessionService: SessionService) {
    this.formGroup = this.formBuilder.group({
      departments: ['', Validators.required],
      employeeBadgeNumbers: [[this.sessionData.currentUser.id], Validators.required],
      types: [this.defaultTypeSelection, Validators.required],
      statuses: [this.defaultStatusSelection, Validators.required],
      dateRange: this.formBuilder.group({
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date())
      }),
      includePartial: true
    });
  }

  ngOnInit(): void {
    this.leaveFilterDefaultEmployeeSelection = ServiceConfigurationService.getPropertyValue(
      ServiceConfigurationProperties.LeaveFilterDefaultEmployeeSelection, EmployeeSelectionType.Self) as EmployeeSelectionType;
    this.getDepartmentsAndEmployeeList();
  }

  filterEmployeeList(departmentList: string[]): void {
    // this is only useful for managers, for user role a single employee is set by getDepartmentsAndEmployeeList()
    if (this.authenticationService.currentUserHasRole(UserRole.departmentManager) ||
      this.authenticationService.currentUserHasRole(UserRole.humanResourceManager)) {
      const employeeFilter: Partial<EmployeeFilter> = {
        departmentNameList: departmentList,
        recurseDepartments: true
      };
      this.employeeService.getActiveEmployees(employeeFilter)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe((response: Employee[]) => {
            this.employees = response;

            if (this.formGroup.controls.employeeBadgeNumbers.value.length > 0) {
              // remove employees not part of the filter results from employee selection list
              this.formGroup.controls.employeeBadgeNumbers.patchValue(this.formGroup.controls.employeeBadgeNumbers.value.filter(
                (badgeNumber: any) => this.employees.some(emp => emp.badgeNumber === badgeNumber) || badgeNumber === this.ALL_VALUE
              ));
              if (this.formGroup.controls.employeeBadgeNumbers.value.some((id: any) => id === this.ALL_VALUE)) {
                this.formGroup.controls.employeeBadgeNumbers.patchValue([this.ALL_VALUE, ...this.employees.map(e=>e.badgeNumber)]);
              }
            }
            this.selectionChanged.emit(this.getFilter());
          });
    } else {
      this.selectionChanged.emit(this.getFilter());
    }
  }

  getDepartmentsAndEmployeeList(): void {
    if (this.authenticationService.currentUserHasRole(UserRole.departmentManager) &&
      !this.authenticationService.currentUserHasRole(UserRole.humanResourceManager)) {
      this.departmentService.filter({
        managerBadgeNumber: this.sessionData.currentUser.id,
        active: true})
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe((managedDepartments: Department[]) => {
          this.departments = this.departmentService.getFlatDepartmentTree(managedDepartments);
          // this.filterEmployeeList(this.departments.map(department => department.name));
          this.selectDefaultDepartment();
        });
    } else if (this.authenticationService.currentUserHasRole(UserRole.humanResourceManager)) {
      this.employeeService.getActiveEmployees()
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe((employee: Employee[]) =>
          this.departmentService.filter({active: true})
            .subscribe((department: Department[]) => {
              this.departments = department.filter(department => employee.map(x => x.departmentName).includes(department.name));
              // this.filterEmployeeList(this.formGroup.controls.departments.value);
              this.selectDefaultDepartment();
            }));
    } else {
      this.employeeService.getEmployee(this.formGroup.controls.employeeBadgeNumbers.value)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe((employee: Employee) => {
          this.departmentService.filter({nameExact: employee.departmentName})
            .subscribe((departments: Department[]) => {
              this.departments = departments;
              this.selectDefaultDepartment();
            });
          this.employees = [employee];
        });
    }
  }

  onAnySelectionChange(isChanged: boolean): void {
    if (isChanged && this.formGroup.valid) {
      this.selectionChanged.emit(this.getFilter());
    }
  }

  onDepartmentSelectionChange(isChanged: boolean): void {
    if (isChanged) {
      /*if (this.formGroup.controls.employeeBadgeNumbers.value[0] === this.ALL_VALUE) {
        this.formGroup.controls.employeeBadgeNumbers.patchValue(this.formGroup.controls.employeeBadgeNumbers.value.slice(1));
      }*/
      this.filterEmployeeList(this.formGroup.controls.departments.value);
    }
  }

  toggleAllSelection(matSelect: MatSelect): void {
    const isSelected: boolean = matSelect.options
      .filter((item: MatOption) => item.value === this.ALL_VALUE)
      .map((item: MatOption) => item.selected)[0];

    if (isSelected) {
      matSelect.options.forEach((item: MatOption) => item.select());
    } else {
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  private getFilter(): Partial<LeaveFilter> {
    let filter: Partial<LeaveFilter> = ({
      employeeBadgeNumberList: this.formGroup.controls.employeeBadgeNumbers.value,
      typeIn: this.formGroup.controls.types.value,
      statusIn: this.formGroup.controls.statuses.value
    });

    if (this.formGroup.controls.includePartial.value) {
      filter.startDateLessThanOrEqualTo = this.dateTimeService.formatDate(this.formGroup.get('dateRange.endDate')?.value, 'yyyy-MM-dd');
      filter.endDateGreaterThanOrEqualTo = this.dateTimeService.formatDate(this.formGroup.get('dateRange.startDate')?.value, 'yyyy-MM-dd');
    } else {
      filter.startDateGreaterThanOrEqualTo = this.dateTimeService.formatDate(this.formGroup.get('dateRange.startDate')?.value, 'yyyy-MM-dd');
      filter.endDateLessThanOrEqualTo = this.dateTimeService.formatDate(this.formGroup.get('dateRange.endDate')?.value, 'yyyy-MM-dd');
    }

    if (this.formGroup.controls.employeeBadgeNumbers.value === undefined || this.formGroup.controls.employeeBadgeNumbers.value === null ||
      this.formGroup.controls.employeeBadgeNumbers.value.length === 0) {
      filter.employeeBadgeNumberList = [-1];
    }

    if (this.formGroup.controls.employeeBadgeNumbers.value[0] === this.ALL_VALUE) {
      filter.employeeBadgeNumberList = this.formGroup.controls.employeeBadgeNumbers.value.slice(1);
    }

    if (this.formGroup.controls.types.value[0] === this.ALL_VALUE) {
      filter.typeIn = this.formGroup.controls.types.value.slice(1);
    }

    if (this.formGroup.controls.statuses.value[0] === this.ALL_VALUE) {
      filter.statusIn = this.formGroup.controls.statuses.value.slice(1);
    }

    return filter;
  }

  private selectDefaultDepartment(): void {
    if (!this.authenticationService.currentUserHasRole(UserRole.departmentManager) &&
      !this.authenticationService.currentUserHasRole(UserRole.humanResourceManager)) {
      this.leaveFilterDefaultEmployeeSelection = EmployeeSelectionType.Self;
    }

    switch(this.leaveFilterDefaultEmployeeSelection) {
      case EmployeeSelectionType.Self:
        this.employeeService.getEmployee(this.sessionData.currentUser.id)
          .subscribe((employee: Employee) => {
            this.formGroup.controls.departments.patchValue([employee.departmentName]);
            this.onDepartmentSelectionChange(true);
          });
        break;
      case EmployeeSelectionType.Department:
        this.employeeService.getEmployee(this.sessionData.currentUser.id)
          .subscribe((employee: Employee) => {
            this.formGroup.controls.departments.patchValue([employee.departmentName]);
            this.formGroup.controls.employeeBadgeNumbers.patchValue([this.ALL_VALUE]);
            this.onDepartmentSelectionChange(true);
          });
        break;
      case EmployeeSelectionType.ManagedDepartments:
        this.departmentService.filter({
          managerBadgeNumber: this.sessionData.currentUser.id,
          active: true})
          .pipe(
            takeUntil(this.destroy$)
          )
          .subscribe((managedDepartments: Department[]) => {
            const managedDepartmentsList = this.departmentService.getFlatDepartmentTree(managedDepartments);
            if (managedDepartmentsList !== null && managedDepartmentsList !== undefined) {
              this.formGroup.controls.departments.patchValue(managedDepartmentsList.map(d=>d.name));
              this.formGroup.controls.employeeBadgeNumbers.patchValue([this.ALL_VALUE]);
              this.onDepartmentSelectionChange(true);
            }
          });
        break;
      case EmployeeSelectionType.All:
        this.formGroup.controls.departments.patchValue([this.ALL_VALUE, ...this.departments.map(d=>d.name)]);
        this.formGroup.controls.employeeBadgeNumbers.patchValue([this.ALL_VALUE]);
        this.onDepartmentSelectionChange(true);
        break;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
