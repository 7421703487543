import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EmployeeService} from '../../../services/employee.service';
import {LeaveService} from '../../../services/leave.service';
import {Employee} from '../../../interfaces/employee';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {Leave, LeaveStatus, LeaveType} from '../../../interfaces/leave';
import {LeaveSummary} from '../../../interfaces/leave-summary';
import {Location} from '@angular/common';
import {SessionService} from '../../../services/session.service';
import {SessionData} from '../../../interfaces/session';
import {
  catchError,
  concatAll,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  mergeAll,
  mergeMap,
  switchMap,
  takeUntil,
  toArray
} from 'rxjs/operators';
import {BehaviorSubject, EMPTY, from, merge, Observable, of, Subject, throwError, zip} from 'rxjs';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {DepartmentManagerService} from '../../../services/department-manager.service';
import {HumanResourcesManagerService} from '../../../services/human-resources-manager.service';
import {EntityVersion, RevisionType} from '../../../interfaces/entity-version';
import {TranslateService} from '@ngx-translate/core';
import {DateTimeService} from '../../../services/date-time.service';
import {LanguageService} from '../../../services/language.service';
import {environment} from '../../../../environments/environment';
import {MatRadioChange} from '@angular/material/radio';
import {ErrorService} from '../../../services/error.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {UserRole} from '../../../interfaces/user';
import {addDays, addHours, format, isBefore, isSameMonth, roundToNearestMinutes} from 'date-fns';
import {DocumentManagerService} from '../../../services/document-manager.service';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {LeaveDocument} from '../../../interfaces/leave-document';
import {saveAs} from 'file-saver';
import {ServiceConfigurationService} from '../../../services/service-configuration.service';
import {ServiceConfigurationProperties} from '../../../interfaces/service-configuration';
import {ConfirmationDialogComponent} from '../../../components/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {DepartmentService} from '../../../services/department.service';
import {Department} from '../../../interfaces/department';
import {LeaveEditorUtils} from "./leave-editor.utils";
import {Page} from "../../../interfaces/page";

@Component({
  selector: 'app-leave-editor',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './leave-editor.component.html',
  styleUrls: ['./leave-editor.component.less'],
  providers: [
    {provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}}
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeaveEditorComponent implements OnInit, OnDestroy {

  readonly environment = environment;
  readonly LeaveType = LeaveType;
  readonly LeaveStatus = LeaveStatus;
  readonly RevisionType = RevisionType;

  private currentUserIsManager = false;
  private isMultiMonthPeriod = false;
  leaveAllowBusySubstitute: boolean | null = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.LeaveAllowBusySubstitute, false);
  leaveAllowRequestOnCredit: boolean | null = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.LeaveAllowRequestOnCredit, true);
  private leaveEmployeeCancel: boolean | null = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.LeaveEmployeeCancel, true);
  leaveMedicalManagerApproval: boolean | null = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.LeaveMedicalManagerApproval, true);
  leaveMedicalHrManagerApproval: boolean | null = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.LeaveMedicalHrManagerApproval, true);
  leavePaidSubstituteApproval: boolean | null = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.LeavePaidSubstituteApproval, true);
  private leaveRetroactiveApprovingManagerWrite: boolean | null = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.LeaveRetroactiveApprovingManagerWrite, true);
  private leaveSelfManagerApproval: boolean | null = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.LeaveSelfManagerApproval, true);
  private leaveSkipPlanning: boolean | null = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.LeaveSkipPlanning, false);
  leaveSubstituteRequired: boolean = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.LeaveSubstituteRequired, false) as boolean;
  minDateRange: Date | null = null;
  private missingAttachmentWarningShown = false;
  private missingSubstituteWarningShown = false;
  private previousUrl;
  private removedDocumentsList = [] as LeaveDocument[];

  actionInProgress = false;
  attachProgressValue = 0;
  departmentEmployees = [] as Employee[];
  departmentManagers = [] as Employee[];
  filter: string | null = null;
  metadataLoaded$ = new BehaviorSubject<boolean>(false);
  destroy$ = new Subject<void>();
  employees = [] as Employee[];
  employeeMedicalLeaveSummary = undefined as LeaveSummary | undefined;
  employeePaidLeaveSummary = undefined as LeaveSummary | undefined;
  filteredEmployees = [] as Employee[];
  formGroup: FormGroup;
  hrManagers = [] as Employee[];
  isSubstituteBusy = false;
  journalActivities = [] as EntityVersion[];
  journalPanelLoadComplete = new BehaviorSubject(true);
  journalPanelOpenState = false;
  originalLeavePeriodDays = 0;
  sessionData: SessionData;

  weekendsAndHolidaysFilter = (date: Date | null): boolean => {
    const day = (date || new Date()).getDay();
    return day !== 0 && day !== 6 && !this.dateTimeService.isPublicHoliday(date);
  }

  constructor(private activatedRoute: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private departmentService: DepartmentService,
              private departmentManagerService: DepartmentManagerService,
              private dialog: MatDialog,
              private documentManagerService: DocumentManagerService,
              private errorService: ErrorService,
              private formBuilder: FormBuilder,
              private humanResourcesManagerService: HumanResourcesManagerService,
              private languageService: LanguageService,
              private location: Location,
              private router: Router,
              private serviceConfigurationService: ServiceConfigurationService,
              private sessionService: SessionService,
              private translateService: TranslateService,
              public employeeService: EmployeeService,
              public leaveEditorUtils: LeaveEditorUtils,
              public leaveService: LeaveService,
              public dateTimeService: DateTimeService) {
    this.sessionData = this.sessionService.getData();
    const nextHalfHour = roundToNearestMinutes(new Date(), {nearestTo: 30});
    this.formGroup = this.formBuilder.group({
      id: 0,
      status: this.leaveSkipPlanning ? LeaveStatus.Planned : LeaveStatus.New,
      employeeBadgeNumber: [null, [Validators.required, this.validatorRequireValidEmployee()]],
      substituteBadgeNumber: [-1, this.leaveSubstituteRequired ? Validators.required : null],
      approverBadgeNumber: [-1, Validators.required],
      hrApproverBadgeNumber: -1,
      type: [this.LeaveType.Paid, Validators.required],
      startDate: [new Date(), Validators.required],
      endDate: [new Date(), Validators.required],
      startTime: format(nextHalfHour, 'HH:mm'),
      endTime: format(addHours(nextHalfHour, 1), 'HH:mm'),
      details: null,
      documentsList: this.formBuilder.array([]),
      attachmentProgressSpinner: this.attachProgressValue
    });
    this.previousUrl = this.router?.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString();
  }

  ngOnInit(): void {
    this.subscribeForServiceConfigurations();
    this.setOptionalValidators();
    this.loadPageParameters(this.activatedRoute);
    if (this.formGroup.controls.id.value !== 0) {
      this.loadLeave(this.formGroup.controls.id.value);
    } else {
      this.populateEmployeeLists(this.sessionData.currentUser.id);
    }

    this.formGroup.controls.employeeBadgeNumber.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(selectedEmployee =>
        this.filteredEmployees = this.employeeService.filterEmployeesInList(selectedEmployee, this.employees)
      );
  }

  approveLeave(): void {
    if (!this.canApproveLeave()) {
      this.errorService.showError('leave.messages.invalidApprover',
        {
          badgeNumber: this.sessionData.currentUser.id,
          id: this.formGroup.controls.id.value,
          leaveType: this.translateService.instant('leave.types.articled.' + this.formGroup.controls.type.value).toLowerCase()
        });
      return;
    }

    if (this.showMissingSubstituteWarning() || this.showMissingAttachmentWarning()) {
      return;
    }

    let nextLeaveStatus: LeaveStatus | undefined = undefined;
    switch (this.formGroup.controls.status.value) {
      case LeaveStatus.Planned:
        if (this.formGroup.controls.substituteBadgeNumber.value !== -1 &&
          (this.formGroup.controls.type.value === LeaveType.Paid && this.leavePaidSubstituteApproval) ||
          this.formGroup.controls.type.value !== LeaveType.Paid) {
          nextLeaveStatus = LeaveStatus.SubstituteApproved;
        }
        if (this.formGroup.controls.substituteBadgeNumber.value === -1 ||
          (this.formGroup.controls.type.value === LeaveType.Paid && !this.leavePaidSubstituteApproval)) {
          nextLeaveStatus = this.formGroup.controls.approverBadgeNumber.value === -1 ?
            LeaveStatus.HumanResourcesApproved : LeaveStatus.ManagerApproved;
          if (this.formGroup.controls.type.value === LeaveType.Medical && !this.leaveMedicalManagerApproval) {
            nextLeaveStatus = LeaveStatus.HumanResourcesApproved;
          }
        }
        break;
      case LeaveStatus.SubstituteApproved:
        if (this.formGroup.controls.type.value === LeaveType.Medical && !this.leaveMedicalManagerApproval) {
          nextLeaveStatus = LeaveStatus.HumanResourcesApproved;
        } else {
          nextLeaveStatus = LeaveStatus.ManagerApproved;
        }
        break;
      case LeaveStatus.ManagerApproved:
        nextLeaveStatus = LeaveStatus.HumanResourcesApproved;
        break;
    }

    if (nextLeaveStatus === undefined) {
      this.errorService.showError('leave.messages.unknownNextStatus');
      return;
    }

    this.sendLeaveApproval(this.formGroup.controls.id.value, nextLeaveStatus, this.formGroup.controls.details.value as string);
  }

  attachDocument(event: Event): void {
    const fileList = (event.target as HTMLInputElement).files;
    const documentListCtrl = this.formGroup.controls.documentsList as FormArray;
    const indexNewFile = documentListCtrl.length;
    if (fileList) {
      this.setActionInProgress(true);
      const file = fileList[0];
      const uploadingDocument: Partial<LeaveDocument> = {
        id: '',
        fileName: file.name,
      };
      documentListCtrl.push(this.formBuilder.control(uploadingDocument));
      this.documentManagerService.add(file)
        .subscribe(uploadEvent => {
            this.setActionInProgress(false);
            if (uploadEvent.type === HttpEventType.UploadProgress) {
              this.attachProgressValue = Math.round(100 * uploadEvent.loaded / uploadEvent.total);
            } else if (uploadEvent instanceof HttpResponse) {
              if (uploadEvent.status === 200 && uploadEvent.body !== undefined) {
                const uploadedDocument: LeaveDocument = {
                  id: uploadEvent.body.id,
                  fileName: uploadEvent.body.fileName,
                  fileType: uploadEvent.body.fileType,
                  leave_id: this.formGroup.controls.id.value
                };
                documentListCtrl.at(indexNewFile).setValue(uploadedDocument);
                documentListCtrl.markAsDirty();
              }
            }
          },
          error => {
            this.setActionInProgress(false);
            documentListCtrl.removeAt(indexNewFile);
            this.errorService.showError(error);
          }
        );
    }
  }

  canApproveLeave(): boolean {
    const isActiveLeave = this.formGroup.controls.status.value !== LeaveStatus.New && this.formGroup.controls.status.value !== LeaveStatus.Canceled;
    if (!isActiveLeave) {
      return false;
    }

    const isPendingSubstituteApproval = this.formGroup.controls.status.value === LeaveStatus.Planned;
    const requiresSubstituteApproval = (this.formGroup.controls.type.value === LeaveType.Paid && this.leavePaidSubstituteApproval) ||
      this.formGroup.controls.type.value !== LeaveType.Paid;
    if (this.sessionData.currentUser.id === this.formGroup.controls.substituteBadgeNumber.value &&
      isPendingSubstituteApproval && requiresSubstituteApproval) {
      return true;
    }

    const isPendingApprovingManagerApproval = isPendingSubstituteApproval ||
      this.formGroup.controls.status.value === LeaveStatus.SubstituteApproved;
    const requiresApprovingManagerApproval = this.formGroup.controls.type.value === LeaveType.Medical && this.leaveMedicalManagerApproval ||
      this.formGroup.controls.type.value !== LeaveType.Medical;
    if (this.sessionData.currentUser.id === this.formGroup.controls.approverBadgeNumber.value &&
      isPendingApprovingManagerApproval && requiresApprovingManagerApproval) {
      return true;
    }

    const isPendingHrApproval = isPendingApprovingManagerApproval || this.formGroup.controls.status.value === LeaveStatus.ManagerApproved;
    return isPendingHrApproval && this.authenticationService.currentUserHasRole(UserRole.humanResourceManager);
  }

  cancelAttachDocument(): void {
    this.setActionInProgress(false);
  }

  cancelLeave(): void {
    if (this.formGroup.controls.id.value !== 0 && this.canCancelLeave()) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {data: {
          question: this.translateService.instant('leave.messages.cancelConfirmation',
            {
              leaveType: this.translateService.instant('leave.types.articled.' + this.formGroup.controls.type.value).toLowerCase()
            })}
      });

      dialogRef.afterClosed()
        .subscribe(confirmation => {
          if (confirmation) {
            this.setActionInProgress(true);
            this.leaveService.cancelLeave(this.formGroup.controls.id.value)
              .subscribe(
                () => {
                  this.setActionInProgress(false);
                  this.errorService.showInfo('leave.messages.cancelSuccess', {
                    id: this.formGroup.controls.id.value,
                    leaveType: this.translateService.instant('leave.types.articled.' + this.formGroup.controls.type.value)
                  });
                  this.navigateBack();
                },
                error => {
                  this.setActionInProgress(false);
                  this.errorService.showError(error);
                }
              );
          }
        });
    } else {
      this.navigateBack();
    }
  }

  canCancelLeave(): boolean {
    if (!this.canEditLeave()) {
      return false;
    }
    if (!this.leaveEmployeeCancel && this.sessionData.currentUser.id === this.formGroup.controls.employeeBadgeNumber.value) {
      return false;
    }

    return true;
  }

  /**
   * Rules for allowing user to edit a leave
   *   * User is composing a new leave that is not saved yet
   *   OR
   *   * Leave status is not Finalized OR Leave status is Finalized but leave period extends between 2 months (i.e. 25 aug - 5 sep)
   *    AND
   *    * (Leave period end date has not yet passed) OR (Current user is a dept manager AND retroactive edits are allowed)
   *    AND
   *    * Current user is the leave employee OR Current user is one of the dept manager OR Current user is HR manager
   */
  canEditLeave(): boolean {
    const now = new Date();
    const endsThisMonth = isSameMonth(now, this.formGroup.controls.endDate.value);
    const statusPermitsEditing = (this.formGroup.controls.status.value !== LeaveStatus.Canceled && this.formGroup.controls.status.value !== LeaveStatus.Finalized) ||
      (this.formGroup.controls.status.value === LeaveStatus.Finalized && this.isMultiMonthPeriod && endsThisMonth);
    const currentUserIsApprovingOrManager = this.currentUserIsManager || (this.sessionData.currentUser.id === this.formGroup.controls.approverBadgeNumber.value);
    return (this.formGroup.controls.id.value === 0 ||
      (statusPermitsEditing &&
        (this.sessionData.currentUser.id === this.formGroup.controls.employeeBadgeNumber.value ||
          (currentUserIsApprovingOrManager && this.leaveRetroactiveApprovingManagerWrite) ||
          (currentUserIsApprovingOrManager && !this.leaveRetroactiveApprovingManagerWrite && isBefore(now, addDays(this.formGroup.controls.endDate.value, 1))) ||
          this.authenticationService.currentUserHasRole(UserRole.humanResourceManager))));
  }

  canRequestLeave(): boolean {
    return this.formGroup.controls.status.value === LeaveStatus.New && this.formGroup.controls.id.value !== 0 &&
      (this.canEditLeave() || this.sessionData.currentUser.id === this.formGroup.controls.substituteBadgeNumber.value);
  }

  deleteAttachment(documentId: string): void {
    if (documentId === undefined || documentId === null) {
      return;
    }
    if (!this.deleteDocumentFromAttachmentList(documentId)) {
      this.errorService.showError('leave.messages.errDocumentNotFoundInAttachments', {id: documentId});
      return;
    }
    this.formGroup.controls.documentsList.markAsDirty();
  }

  private deleteDocumentFromAttachmentList(id: string): boolean {
    const documentListCtrl = this.formGroup.controls.documentsList as FormArray;
    const index = documentListCtrl.controls.findIndex(documentCtrl => documentCtrl.value.id === id);
    if (index >= 0) {
      this.removedDocumentsList.push(documentListCtrl.at(index)?.value);
      documentListCtrl.removeAt(index);
      return true;
    }

    return false;
  }

  downloadAttachment(documentId: string): void {
    this.setActionInProgress(true);
    this.documentManagerService.download(documentId)
      .subscribe(response => {
        this.setActionInProgress(false);
        const header = response.headers.get('Content-Disposition') || '';
        const filename = /filename=([^;]+)/ig.exec(header)?.pop();
        saveAs(response.body as Blob, filename);
      });
  }

  getEmployeeDepartmentName(badgeNumber: number): string {
    const selectedEmployee = this.employees.find(employee => employee.badgeNumber === badgeNumber);
    if (selectedEmployee) {
      return selectedEmployee.departmentName;
    }
    return '';
  }

  getStatusIndex(status: LeaveStatus = this.formGroup.controls.status.value): number {
    return Object.keys(LeaveStatus).indexOf(status);
  }

  /**
   The stepper shows logical flow while LeaveStatus holds all possible statuses.
   We need to match the LeaveStatus values to a step in the stepper, in order to show current active step and progress.
   */
  getStepperIndexByLeaveStatus(status: LeaveStatus = this.formGroup.controls.status.value): number {
    switch (status) {
      case LeaveStatus.Planned:
        if ((this.formGroup.controls.type.value === LeaveType.Paid && this.leavePaidSubstituteApproval) ||
          (this.formGroup.controls.type.value === LeaveType.Absence && this.formGroup.controls.substituteBadgeNumber.value !== -1)) {
          return 1;
        } else {
          if (this.formGroup.controls.type.value !== LeaveType.Medical ||
            (this.formGroup.controls.type.value === LeaveType.Medical && this.leaveMedicalManagerApproval)) {
            return 2;
          } else {
            return 3;
          }
        }
      case LeaveStatus.SubstituteApproved:
        if (this.formGroup.controls.type.value === LeaveType.Medical && !this.leaveMedicalManagerApproval) {
          return 3;
        } else {
          return 2;
        }
      case LeaveStatus.SubstituteRejected:
        return 1;
      case LeaveStatus.ManagerApproved:
        return 3;
      case LeaveStatus.ManagerRejected:
        return 2;
      case LeaveStatus.HumanResourcesApproved:
        return 4;
      case LeaveStatus.HumanResourcesRejected:
        return 3;
      default:
        return 0;
    }
  }

  isLeaveAvailableDaysExceeded(leaveSummary: LeaveSummary | undefined): boolean {
    return this.leaveService.isLeaveAvailableDaysExceeded(leaveSummary,
      this.leaveEditorUtils.getNumberOfBusinessDays(this.formGroup.controls.startDate.value, this.formGroup.controls.endDate.value));
  }

  loadLeaveJournal(leaveId: number, numberOfVersions?: number): void {
    if (this.journalActivities.length > 2) {
      return;
    }
    if (leaveId > 0) {
      this.journalPanelLoadComplete.next(false);
      this.journalActivities = [];
      this.leaveService.getLeaveJournal(leaveId, numberOfVersions)
        .pipe(
          mergeMap(value => value),
          concatMap(version => {
            const observables: Observable<any>[] = [of(version)];
            observables.push(this.employeeService.getEmployee(Number(version.audit.lastModifiedBy), false));
            const employeeApprovingManager: Observable<any> = (version.entity.approverBadgeNumber === undefined) ?
              of(null) :
              this.employeeService.getEmployee(Number(version.entity.approverBadgeNumber), false);
            observables.push(employeeApprovingManager);
            const employeeSubstitute: Observable<any> = (version.entity.substituteBadgeNumber === undefined) ?
              of(null) :
              this.employeeService.getEmployee(Number(version.entity.substituteBadgeNumber), false);
            observables.push(employeeSubstitute);
            const employeeApprovingHr: Observable<any> = (version.entity.hrApproverBadgeNumber === undefined) ?
              of(null) :
              this.employeeService.getEmployee(Number(version.entity.hrApproverBadgeNumber), false);
            observables.push(employeeApprovingHr);
            return zip(...observables);
          }),
          takeUntil(this.destroy$)
        )
        .subscribe(([version, employeeAuthor, employeeApprover, employeeSubstitute, employeeHr]) => {
            if (employeeAuthor) {
              version.audit.lastModifiedByFullName = employeeAuthor.lastName + ' ' + employeeAuthor.firstName;
            }
            if (employeeApprover) {
              version.entity.approverFullName = employeeApprover.lastName + ' ' + employeeApprover.firstName;
            }
            if (employeeSubstitute) {
              version.entity.substituteFullName = employeeSubstitute.lastName + ' ' + employeeSubstitute.firstName;
            }
            if (employeeHr) {
              version.entity.hrApproverFullName = employeeHr.lastName + ' ' + employeeHr.firstName;
            }
            this.journalActivities.push(version);
          },
          error => {
            if (this.journalPanelOpenState) {
              this.errorService.showError('leave.messages.errJournalLoadingFailed', {error});
            } else {
              this.errorService.showWarning('leave.messages.errJournalLoadingFailed', {error});
            }
          },
          () => this.journalPanelLoadComplete.next(true)
        );
    }
  }

  onEmployeeSelectionChange(selectedBadgeNumber: number): void {
    const selectedEmployee = this.employees.find(employee => employee.badgeNumber === selectedBadgeNumber);
    this.formGroup.controls.employeeBadgeNumber.setValue(selectedBadgeNumber);
    if (selectedEmployee != null) {
      const employeeFilter = {
        departmentNameExact: selectedEmployee.departmentName,
        recurseDepartments: true
      };
      this.employeeService.getActiveEmployees(employeeFilter)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(response => {
          this.departmentEmployees = response;
          // exclude selected employee from the substitute employee list
          const indexSelectedEmployee = this.departmentEmployees.findIndex((employee: Employee) =>
            employee.badgeNumber === selectedBadgeNumber);
          this.departmentEmployees.splice(indexSelectedEmployee, 1);
        });

      const excludeOwnApproval = this.authenticationService.currentUserHasRole(UserRole.departmentManager) && !this.leaveSelfManagerApproval;
      this.departmentManagerService.filter({departmentName: selectedEmployee.departmentName})
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(response => {
          if (response.length > 0) {
            const employeeBadgeNumberList = response.filter(manager => !(manager.badgeNumber === selectedBadgeNumber && excludeOwnApproval))
              .map(manager => manager.badgeNumber);
            if (employeeBadgeNumberList.length === 0) {
              this.departmentManagers = [] as Employee[];
            } else {
              this.employeeService.getActiveEmployees({employeeBadgeNumberList})
                .pipe(
                  takeUntil(this.destroy$)
                )
                .subscribe(employees => {
                    this.departmentManagers = employees;
                    // pre-set the approval manager for new leaves
                    if (this.departmentManagers.length > 0) {
                      this.currentUserIsManager = this.departmentManagers.some(manager =>
                        manager.badgeNumber === this.sessionData.currentUser.id);
                      if (this.formGroup.controls.id.value === 0 &&
                        this.formGroup.controls.approverBadgeNumber.value === -1) {
                        this.formGroup.controls.approverBadgeNumber.setValue(this.departmentManagers[0].badgeNumber);
                      }
                    }
                    this.updateFormFieldsValidity(this.formGroup.controls.type.value);
                  }
                );
            }
          }
        });

      this.populateEmployeeLeaveSummary(selectedEmployee.badgeNumber);
    }
  }

  onApproverSelectionChange(selectedBadgeNumber: number): void {
    const selectedEmployee = this.departmentManagers.find(employee => employee.badgeNumber === selectedBadgeNumber);
    if (selectedEmployee != null || selectedBadgeNumber === -1) {
      this.formGroup.controls.approverBadgeNumber.setValue(selectedBadgeNumber);
    }
  }

  onHrApproverSelectionChange(selectedBadgeNumber: number): void {
    const selectedEmployee = this.hrManagers.find(employee => employee.badgeNumber === selectedBadgeNumber);
    if (selectedEmployee != null || selectedBadgeNumber === -1) {
      this.formGroup.controls.hrApproverBadgeNumber.setValue(selectedBadgeNumber);
    }
  }

  onLeavePeriodPickerEndDateChange(date: Date | null): void {
    if (date === null) {
      return;
    }
    if (this.formGroup.controls.type.value === LeaveType.Absence) {
      this.formGroup.controls.endDate.setValue(date);
    }
    if (this.formGroup.controls.type.value !== LeaveType.Paid) {
      return;
    }

    if (!this.leaveAllowRequestOnCredit &&
      this.employeePaidLeaveSummary &&
      this.isLeaveAvailableDaysExceeded(this.employeePaidLeaveSummary)) {
      this.formGroup.controls.endDate.setErrors({exceeded: true});
      this.formGroup.controls.endDate.markAsTouched();
    }

    // re-validate current substitute overlap
    this.onSubstituteSelectionChange(this.formGroup.controls.substituteBadgeNumber.value);
  }

  onLeavePeriodPickerStartDateChange(date: Date | null): void {
    if (this.formGroup.controls.type.value !== LeaveType.Absence || date === null) {
      return;
    }
    this.formGroup.controls.endDate.setValue(date);
  }

  onLeaveTypeChange($event: MatRadioChange): void {
    this.missingAttachmentWarningShown = false;
    this.updateFormFieldsValidity($event.value);
  }

  onSubstituteSelectionChange(selectedBadgeNumber: number): void {
    const selectedEmployee = this.departmentEmployees.find(employee => employee.badgeNumber === selectedBadgeNumber);
    if (selectedEmployee != null || selectedBadgeNumber === -1) {
      this.formGroup.controls.substituteBadgeNumber.setValue(selectedBadgeNumber);
      this.getActiveLeavesDuringPeriod(selectedBadgeNumber,
        this.formGroup.controls.startDate.value,
        this.formGroup.controls.endDate.value)
        .subscribe(leave => {
            this.isSubstituteBusy = leave.length > 0;
          }
        );
    }
  }

  rejectLeave(): void {
    if (!this.canApproveLeave()) {
      this.errorService.showError('leave.messages.invalidApprover',
        {
          badgeNumber: this.sessionData.currentUser.id,
          id: this.formGroup.controls.id.value,
          leaveType: this.translateService.instant('leave.types.articled.' + this.formGroup.controls.type.value).toLowerCase()
        });
      return;
    }

    const detailsString = this.formGroup.controls.details.value as string;
    if (detailsString === null || detailsString.trim() === '') {
      this.formGroup.controls.details.setErrors({required: true});
      this.formGroup.controls.details.markAsTouched();
      setTimeout(() => {
        this.formGroup.controls.details.setErrors(null);
        this.formGroup.controls.details.markAsTouched();
      }, 7000);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {data: {
        question: this.translateService.instant('leave.messages.rejectConfirmation',
          {
            leaveType: this.translateService.instant('leave.types.articled.' + this.formGroup.controls.type.value).toLowerCase()
          })}
    });

    dialogRef.afterClosed()
      .subscribe(confirmation => {
        if (confirmation) {

          let newStatus: LeaveStatus;
          switch (this.formGroup.controls.status.value) {
            case LeaveStatus.Planned:
              newStatus = LeaveStatus.SubstituteRejected;
              break;
            case LeaveStatus.SubstituteApproved:
              newStatus = LeaveStatus.ManagerRejected;
              break;
            case LeaveStatus.ManagerApproved:
              newStatus = LeaveStatus.HumanResourcesRejected;
              break;
            default:
              this.errorService.showError('leave.messages.errNotPendingApproval',
                {
                  id: this.formGroup.controls.id.value,
                  leaveType: this.formGroup.controls.type.value
                });
              return;
          }

          this.setActionInProgress(true);
          this.leaveService.rejectLeave(this.formGroup.controls.id.value, newStatus, this.formGroup.controls.details.value as string)
            .pipe(
              takeUntil(this.destroy$)
            )
            .subscribe(response => {
                this.setActionInProgress(false);
                if (response && response.id) {
                  this.errorService.showInfo('leave.messages.rejectSuccess', {
                    id: response.id,
                    leaveType: this.translateService.instant('leave.types.articled.' + response.type)
                  });
                  this.navigateBack();
                }
              },
              error => {
                this.setActionInProgress(false);
                this.errorService.showError('leave.messages.rejectError', {
                  error,
                  leaveType: this.translateService.instant('leave.types.articled.' + this.formGroup.controls.type.value)
                });
              });
        }
      });
  }

  requestLeave(): void {
    if (!this.formGroup.pristine) {
      return;
    }
    if (!this.canRequestLeave()) {
      this.errorService.showError('leave.messages.invalidRequester',
        {
          badgeNumber: this.sessionData.currentUser.id,
          id: this.formGroup.controls.id.value,
          leaveType: this.translateService.instant('leave.types.' + this.formGroup.controls.type.value).toLowerCase()
        });
      return;
    }

    if (this.showMissingSubstituteWarning() || this.showMissingAttachmentWarning()) {
      return;
    }

    this.setActionInProgress(true);
    this.leaveService.requestLeave(this.formGroup.controls.id.value)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(leave => {
          this.setActionInProgress(false);
          if (leave && leave.id) {
            this.errorService.showInfo('leave.messages.sendSuccess', {
              id: leave.id,
              leaveType: this.translateService.instant('leave.types.articled.' + leave.type).toLowerCase()
            });
            this.navigateBack();
          }
        },
        error => {
          this.setActionInProgress(false);
          this.errorService.showError('leave.messages.sendError', {
            error,
            leaveType: this.translateService.instant('leave.types.articled.' + this.formGroup.controls.type.value)
          });
        });
  }

  saveLeave(): void {
    if (this.formGroup.invalid) {
      this.errorService.showError('leave.messages.invalidLeaveFields', {
        leaveType: this.translateService.instant('leave.types.articled.' + this.formGroup.controls.type.value).toLowerCase()
      });
      return;
    }

    if (this.showMissingSubstituteWarning() || this.showMissingAttachmentWarning()) {
      return;
    }

    this.setActionInProgress(true);
    const leave: Leave = this.formGroup.getRawValue();
    const isNewLeave = leave.id === null || leave.id === undefined || leave.id === 0;

    const actionsObservables: Observable<any>[] = [];
    this.removedDocumentsList.forEach(document =>
      actionsObservables.push(
        this.documentManagerService.delete(document.id)
          .pipe(
            catchError(error => {
              this.errorService.showWarning(this.translateService.instant('leave.messages.errDeleteAttachment', {error}));
              return EMPTY;
            })
          )
      ));

    leave.documentsList.forEach(document =>
      actionsObservables.push(
        this.documentManagerService.patch({id: document.id, referenced: true})
          .pipe(
            catchError(error => {
                this.setActionInProgress(false);
                return throwError(this.translateService.instant('leave.messages.errReferenceAttachment', {error}));
              }
            )
          )
      ));

    if (isNewLeave) {
      actionsObservables.push(
        this.leaveService.createLeave(this.leaveEditorUtils.serializeLeave(leave, this.formGroup))
          .pipe(
            catchError(error => {
              this.setActionInProgress(false);
              return throwError(this.translateService.instant('leave.messages.createError', {
                error,
                leaveType: this.translateService.instant('leave.types.articled.' + this.formGroup.controls.type.value),
                genderSuffix: this.formGroup.controls.type.value === LeaveType.Business ||
                              this.formGroup.controls.type.value === LeaveType.Absence ?
                              'ă' : ''
              }));
            })
          )
      );
    } else {
      actionsObservables.push(
        this.leaveService.updateLeave(this.leaveEditorUtils.serializeLeave(leave, this.formGroup))
          .pipe(
            catchError(error => {
              this.setActionInProgress(false);
              return throwError(this.translateService.instant('leave.messages.updateError', {
                  error,
                  leaveType: this.translateService.instant('leave.types.articled.' + this.formGroup.controls.type.value)
                })
              );
            })
          )
      );
    }

    of(...actionsObservables)
      .pipe(
        takeUntil(this.destroy$),
        concatAll()
      )
      .subscribe(
        response => {
          this.setActionInProgress(false);
          if (response && response.employeeBadgeNumber) {
            if (isNewLeave) {
              this.errorService.showInfo('leave.messages.createSuccess', {
                id: response.id,
                leaveType: this.translateService.instant('leave.types.articled.' + response.type)
              });
              if (response.status == LeaveStatus.New) {
                this.router.navigate([response.id], {relativeTo: this.activatedRoute});
              } else {
                this.navigateBack();
              }
            } else {
              this.errorService.showInfo('leave.messages.updateSuccess', {
                id: response.id,
                leaveType: this.translateService.instant('leave.types.articled.' + response.type)
              });
              this.removedDocumentsList = [];
              this.refreshForm(this.leaveEditorUtils.deserializeLeave(response));
            }
          }
        },
        error => {
          this.setActionInProgress(false);
          this.errorService.showError(error);
        }
      );
  }

  private loadLeave(id: number): void {
    this.setActionInProgress(true);
    if (id > 0) {
      this.leaveService.getLeave(id)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(leave => {
            if (leave) {
              if (leave.type === LeaveType.Business) {
                this.filter = LeaveType.Business;
              }
              if (this.sessionData.currentUser.id === leave.substituteBadgeNumber) {
                this.populateEmployeeLists(leave.employeeBadgeNumber);
              } else {
                this.populateEmployeeLists(this.sessionData.currentUser.id);
              }

              this.metadataLoaded$.subscribe(isLoaded => {
                if (isLoaded) {
                  this.leaveEditorUtils.originalLeave = this.leaveEditorUtils.deserializeLeave(leave);
                  this.loadLeaveFields(this.leaveEditorUtils.originalLeave as Leave);
                  // synchronize onEmployeeChange for using this.employees
                  this.onEmployeeSelectionChange(leave.employeeBadgeNumber);
                }
              });
            }
          },
          error => {
            this.setActionInProgress(false);
            this.errorService.showError(error);
            this.router.navigate(['/home']);
          }
        );
    }
  }

  private loadLeaveFields(leave: Leave): void {
    this.formGroup.patchValue(leave);
    const documentListCtrl = this.formGroup.controls.documentsList as FormArray;
    documentListCtrl.clear();
    leave.documentsList.forEach(document =>
      documentListCtrl.push(this.formBuilder.control(document)));
    this.formGroup.patchValue({
      startDate: leave.startDate,
      endDate: leave.endDate
    });
    this.isMultiMonthPeriod = !isSameMonth(this.formGroup.controls.startDate.value, this.formGroup.controls.endDate.value);
    if (this.isMultiMonthPeriod && this.formGroup.controls.status.value === LeaveStatus.Finalized) {
      this.minDateRange = this.formGroup.controls.startDate.value;
    }
    // validate date range fits into current allowed leave days
    this.onLeavePeriodPickerEndDateChange(this.formGroup.controls.endDate.value);
    this.updateFormFieldsValidity(leave.type);
    this.setActionInProgress(false);
    // default number of versions can be 1 one LeaveServiceImpl.autoApprove creates different audit versions (HR-304)
    this.loadLeaveJournal(leave.id, this.journalPanelOpenState ? undefined : 2);
  }

  private loadPageParameters(route: ActivatedRoute): void {
    route.paramMap.forEach(params => {
      let id: string | null = params.get('id');
      if (params.has('id') && id !== null) {
        // converting formatted string to integer, can be removed after 2022, see HR-247
        id = id.replace('.', '');
        id = id.replace(',', '');
        this.formGroup.controls.id.setValue(Number(id));
      } else {
        // pre-set new leave fields
        this.activatedRoute.queryParams
          .subscribe(queryParams => {
            if (queryParams.hasOwnProperty('startDate')) {
              const paramStartDate = Date.parse(queryParams.startDate);
              if (!isNaN(paramStartDate)) {
                this.formGroup.controls.startDate.setValue(new Date(queryParams.startDate));
              }
            }
            if (queryParams.hasOwnProperty('endDate')) {
              const paramEndDate = Date.parse(queryParams.endDate);
              if (!isNaN(paramEndDate)) {
                this.formGroup.controls.endDate.setValue(new Date(queryParams.endDate));
              }
            } else {
              this.formGroup.controls.endDate.setValue(this.formGroup.controls.startDate.value);
            }
            if (queryParams.hasOwnProperty('type') &&
              Object.keys(LeaveType).some((type) => type === queryParams.type)) {
              this.formGroup.controls.type.setValue(queryParams.type);
              this.updateFormFieldsValidity(queryParams.type);
            }
            if (queryParams.hasOwnProperty('filter')) {
              this.filter = queryParams.filter;
              this.formGroup.controls.type.setValue(this.filter);
            }
          });
      }
    });
  }

  private navigateBack(): void {
    const previousUrl = this.previousUrl?.split('?', 1).pop();
    const previousPage = previousUrl?.split('/').pop();
    if (previousPage === 'leave') {
      this.router.navigate(['/home']);
    } else {
      this.location.back();
    }
  }

  private populateEmployeeLists(defaultEmployeeBadgeNumber: number): void {
    this.employees = [];
    // employee list will only have current user, by default
    let employeesList$: Observable<Employee[]> = this.employeeService.getActiveEmployees({
      employeeBadgeNumberList: [defaultEmployeeBadgeNumber]
    });
    // for dept mangers
    if (this.authenticationService.currentUserHasRole(UserRole.departmentManager) &&
      !this.authenticationService.currentUserHasRole(UserRole.humanResourceManager)) {
      // merging current employee details + his/her department()s and subdepartment(s) employees
      employeesList$ = merge(employeesList$,
        this.departmentService.filter({managerBadgeNumber: this.sessionData.currentUser.id})
        .pipe(
        switchMap((departments: Department[]) => {
            if (departments === null || departments.length < 1) {
              return EMPTY;
            }
            return this.employeeService.getActiveEmployees({
                departmentNameList: departments.map(department => department.name),
                recurseDepartments: true
              });
          }
        )
      ));
    }
    // for HR mangers, employee list will have all active employees,
    if (this.authenticationService.currentUserHasRole(UserRole.humanResourceManager)) {
      employeesList$ = this.employeeService.getActiveEmployees({});
    }

    employeesList$.pipe(
      mergeAll(), // required for dept managers case
      toArray(),
      takeUntil(this.destroy$)
    )
      .subscribe(employees => {
        this.employees = this.employees.concat(employees);
          if (this.formGroup.controls.id.value === 0) {
            // populate the department employee list
            this.onEmployeeSelectionChange(this.sessionData.currentUser.id);
            this.onLeavePeriodPickerEndDateChange(this.formGroup.controls.endDate.value);
          }
          this.metadataLoaded$.next(true);
        }
      );

    // populate the HR managers list
    this.humanResourcesManagerService.getAll()
      .pipe(
        switchMap(
          managers =>
            this.employeeService.getActiveEmployees({
                employeeBadgeNumberList: managers.map(manager => manager.badgeNumber)
              }
            )
        ),
        takeUntil(this.destroy$))
      .subscribe(employees =>
        this.hrManagers = employees
      );
  }

  private refreshForm(leave?: Leave): void {
    if (leave) {
      this.journalActivities = [];
      this.loadLeaveFields(leave);
    }
    this.formGroup.markAsPristine();
  }

  private sendLeaveApproval(id: number,
                            nextStatus: LeaveStatus,
                            details?: string): void {

    if (nextStatus !== LeaveStatus.SubstituteApproved &&
      nextStatus !== LeaveStatus.ManagerApproved &&
      nextStatus !== LeaveStatus.HumanResourcesApproved) {
      this.errorService.showError('leave.messages.statusMismatch', {
        leaveStatus: this.translateService.instant('leave.statuses.' + nextStatus)
      });
      return;
    }
    this.setActionInProgress(true);
    this.leaveService.approveLeave(id, nextStatus, details)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(response => {
          this.setActionInProgress(false);
          if (response && response.id) {
            this.errorService.showInfo('leave.messages.approveSuccess', {
              id: response.id,
              leaveType: this.translateService.instant('leave.types.articled.' + response.type)
            });
            this.navigateBack();
          }
        },
        error => {
          this.setActionInProgress(false);
          this.errorService.showError('leave.messages.approveError', {
            error,
            leaveType: this.translateService.instant('leave.types.articled.' + this.formGroup.controls.type.value)
          });
        });
  }

  private setActionInProgress(value: boolean): void {
    this.actionInProgress = value;
  }

  private setOptionalValidators(): void {
    if (!this.leaveAllowBusySubstitute) {
      this.formGroup.controls.substituteBadgeNumber.setAsyncValidators(this.validatorBusyEmployee());
    }
  }

  private subscribeForServiceConfigurations(): void {
    this.sessionService.getServiceConfigurationsObservable()
      .subscribe(
        configurations => {
          this.leaveAllowBusySubstitute = ServiceConfigurationService.getBooleanPropertyValue(
            ServiceConfigurationProperties.LeaveAllowBusySubstitute, this.leaveAllowBusySubstitute as boolean, configurations);
          this.leaveAllowRequestOnCredit = ServiceConfigurationService.getBooleanPropertyValue(
            ServiceConfigurationProperties.LeaveAllowRequestOnCredit, this.leaveAllowRequestOnCredit as boolean, configurations);
          this.leaveEmployeeCancel = ServiceConfigurationService.getBooleanPropertyValue(
            ServiceConfigurationProperties.LeaveEmployeeCancel, this.leaveEmployeeCancel as boolean, configurations);
          this.leaveMedicalHrManagerApproval = ServiceConfigurationService.getBooleanPropertyValue(
            ServiceConfigurationProperties.LeaveMedicalHrManagerApproval, this.leaveMedicalHrManagerApproval as boolean,
            configurations);
          this.leaveMedicalManagerApproval = ServiceConfigurationService.getBooleanPropertyValue(
            ServiceConfigurationProperties.LeaveMedicalManagerApproval, this.leaveMedicalManagerApproval as boolean, configurations);
          this.leavePaidSubstituteApproval = ServiceConfigurationService.getBooleanPropertyValue(
            ServiceConfigurationProperties.LeavePaidSubstituteApproval, this.leavePaidSubstituteApproval as boolean, configurations);
          this.leaveRetroactiveApprovingManagerWrite = ServiceConfigurationService.getBooleanPropertyValue(
            ServiceConfigurationProperties.LeaveRetroactiveApprovingManagerWrite,
            this.leaveRetroactiveApprovingManagerWrite as boolean, configurations);
          this.leaveSelfManagerApproval = ServiceConfigurationService.getBooleanPropertyValue(
            ServiceConfigurationProperties.LeaveSelfManagerApproval, this.leaveSelfManagerApproval as boolean, configurations);
          this.leaveSkipPlanning = ServiceConfigurationService.getBooleanPropertyValue(
            ServiceConfigurationProperties.LeaveSkipPlanning, this.leaveSkipPlanning as boolean, configurations);
          this.leaveSubstituteRequired = ServiceConfigurationService.getBooleanPropertyValue(
            ServiceConfigurationProperties.LeaveSubstituteRequired, this.leaveSubstituteRequired as boolean, configurations) as boolean;
        }
      )
  }

  private updateFormFieldsValidity(leaveType: LeaveType): void {
    if (this.canEditLeave()) {
      this.formGroup.controls.substituteBadgeNumber.setValidators(this.leaveSubstituteRequired ? [Validators.required] : null);
      this.formGroup.controls.substituteBadgeNumber.updateValueAndValidity();
      if (!this.leaveMedicalManagerApproval && leaveType === LeaveType.Medical) {
        this.formGroup.controls.approverBadgeNumber.setValidators(null);
        if (this.formGroup.controls.id.value === 0) {
          this.formGroup.controls.approverBadgeNumber.setValue(-1);
        }
      } else {
        this.formGroup.controls.approverBadgeNumber.setValidators([Validators.required]);
        if (this.formGroup.controls.id.value === 0) {
          this.formGroup.controls.approverBadgeNumber.setValue(this.departmentManagers[0]?.badgeNumber);
        }
      }
      if (this.formGroup.controls.type.value !== LeaveType.Medical ||
        (!this.leaveMedicalHrManagerApproval && leaveType === LeaveType.Medical)) {
        this.formGroup.controls.hrApproverBadgeNumber.setValidators(null);
        if (this.formGroup.controls.id.value === 0) {
          this.formGroup.controls.hrApproverBadgeNumber.setValue(-1);
        }
      } else {
        this.formGroup.controls.hrApproverBadgeNumber.setValidators([Validators.required]);
        if (this.formGroup.controls.id.value === 0) {
          this.formGroup.controls.hrApproverBadgeNumber.setValue(this.hrManagers[0]?.badgeNumber);
        }
      }
      this.formGroup.controls.approverBadgeNumber.updateValueAndValidity();
      this.formGroup.controls.startTime.setValidators(leaveType === LeaveType.Absence ? [Validators.required] : null);
      this.formGroup.controls.startTime.updateValueAndValidity();
      this.formGroup.controls.endTime.setValidators(leaveType === LeaveType.Absence ? [Validators.required] : null);
      this.formGroup.controls.endTime.updateValueAndValidity();
      if (leaveType === LeaveType.Absence) {
        this.formGroup.controls.endDate.disable();
        this.formGroup.controls.endDate.setValue(this.formGroup.controls.startDate.value);
        this.formGroup.controls.endDate.updateValueAndValidity();
        if (this.formGroup.controls.substituteBadgeNumber.value === null) {
          this.formGroup.controls.substituteBadgeNumber.setValue(-1);
        }
      }
      this.formGroup.enable();
    } else {
      this.formGroup.disable();
      if (this.canApproveLeave()) {
        this.formGroup.controls.details.enable();
      }
    }
  }

  private validatorRequireValidEmployee(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = Number(control.value?.badgeNumber);

      if (!value) {
        return {employeeBadgeNumberNotFound: true};
      }
      const employeeExists = this.employees.some(employee => employee.badgeNumber === value);
      return employeeExists ? null : {employeeBadgeNumberNotFound: true};
    };
  }

  private validatorBusyEmployee(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const employeeBadgeNumber = control.value?.badgeNumber ? Number(control.value?.badgeNumber) : Number(control.value);

      if (!employeeBadgeNumber || employeeBadgeNumber < 0) {
        return of(null);
      }

      return this.getActiveLeavesDuringPeriod(employeeBadgeNumber,
        this.formGroup.controls.startDate.value,
        this.formGroup.controls.endDate.value)
        .pipe(
          switchMap(leaves => {
            return leaves.length === 0 ? of(null) : of({busy: true});
          })
        );
    };
  }

  private getActiveLeavesDuringPeriod(employeeBadgeNumber: number,
                                      startDate: Date,
                                      endDate: Date): Observable<Leave[]> {
    const leaveFilter = {
      employeeBadgeNumber: employeeBadgeNumber,
      startDateLessThanOrEqualTo: this.dateTimeService.formatDate(endDate, 'yyyy-MM-dd'),
      endDateGreaterThanOrEqualTo: this.dateTimeService.formatDate(startDate, 'yyyy-MM-dd'),
      statusGreaterThan: LeaveStatus.New,
      statusLessThan: LeaveStatus.Canceled
    };
    return this.leaveService.getLeaves(leaveFilter)
      .pipe(
        switchMap((page: Page) => {
          if (page.numberOfElements > 0) {
            return from(page.content);
          } else {
            return of();
          }
        })
      );
  }

  private populateEmployeeLeaveSummary(employeeBadgeNumber: number): void {
    this.leaveService.getLeaveSummary(employeeBadgeNumber)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(response => {
        this.employeePaidLeaveSummary = response.find(leave => leave.type === LeaveType.Paid);
        this.employeeMedicalLeaveSummary = response.find(leave => leave.type === LeaveType.Medical);
      });
  }

  private showMissingAttachmentWarning(): boolean {
    if (!this.missingAttachmentWarningShown &&
      (this.formGroup.controls.type.value === LeaveType.Bereavement || this.formGroup.controls.type.value === LeaveType.Medical ||
        this.formGroup.controls.type.value === LeaveType.Maternity || this.formGroup.controls.type.value === LeaveType.Paternity ||
        this.formGroup.controls.type.value === LeaveType.ChildCare || this.formGroup.controls.type.value === LeaveType.Unpaid) &&
      this.formGroup.controls.documentsList.value.length === 0) {
      this.errorService.showWarning('leave.messages.missingAttachmentWarn');
      this.missingAttachmentWarningShown = true;
      return true;
    }

    return false;
  }

  private showMissingSubstituteWarning(): boolean {
    if (!this.missingSubstituteWarningShown &&
      (this.formGroup.controls.type.value === LeaveType.Bereavement || this.formGroup.controls.type.value === LeaveType.ChildCare
        || this.formGroup.controls.type.value === LeaveType.Paid || this.formGroup.controls.type.value === LeaveType.Unpaid) &&
      (this.formGroup.controls.substituteBadgeNumber.value === null || this.formGroup.controls.substituteBadgeNumber.value === undefined
        || this.formGroup.controls.substituteBadgeNumber.value === -1)) {
      this.errorService.showWarning('leave.messages.missingSubstituteWarn');
      this.missingSubstituteWarningShown = true;
      return true;
    }

    return false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
