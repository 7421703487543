import {ColumnsTranslationsConfig, IColumnDef} from '@gits/ngx-tabulator-tables';
import {DebugConfigModel} from '@gits/ngx-tabulator-tables/lib/models/debug-config.model';
import {TimesheetManagerComponent} from './timesheet-manager.component';

export const GRID_TIMESHEET_MANAGER_OPTIONS: any = {
  renderHorizontal: 'basic',
  layout: "fitDataStretch",
  movableRows: false,
  movableColumns: true,
  selectable: false,
  height: 400,
  // sortMode: 'remote', // todo wait for backend implementation

  // by default, Tabulator will wait 300 milliseconds after a keystroke before triggering the filter.
  // you can customise this delay by using the [headerFilterLiveFilterDelay] setting
  headerFilterLiveFilterDelay: 500,
  bufferingTimeForTableEvents: 450, // accepted interval >= 150 <= 500 (below or above the default value of 500ms will be used)
  columnDefaults: { // setting applies to all columns
    minWidth: 90
  }
};

export const GRID_TIMESHEET_MANAGER_DEBUG_OPTIONS: DebugConfigModel = {
  pauseTableRenderingOnInit: false,
  showDebugPanel: false,
  debugRxjsAssets: false,
  debugTableHeaderFilters: false,
  debugTableEventsBuffering: false
};

export const GRID_TIMESHEET_MANAGER_LABELS: ColumnsTranslationsConfig = {
  languages: ['en-GB', 'ro-RO'],
  columnsWithLabels: {
    __rowActions: 'generic.tasks', // label for special ACTIONS column
    __dataTree: 'NO_LABEL/NOT_IN_USE', // label for special DATA_TREE column
    id: 'generic.id',
    employeeBadgeNumber: 'employee.badgeNumber',
    employeeFullName: 'employee.employee',
    employeeDepartmentName: 'employee.department',
    location: 'location.location',
    dateTimeIn: 'timesheet.checkinTime',
    dateTimeOut: 'timesheet.checkoutTime',
    duration: 'generic.DURATION_HH_MM',
    status: 'generic.status',
    description: 'generic.description'
  }
};

export function getGridTimesheetManagerColumnDefs(scope: TimesheetManagerComponent): IColumnDef[] {
  return [
    {
      title: 'Id',
      field: 'id',
      backendFilterName: '',
      minWidth: 50
    },
    {
      title: 'Employee Badge No',
      field: 'employeeBadgeNumber',
      backendFilterName: '',
      minWidth: 60
    },
    {
      title: 'Employee Full Name',
      field: 'employeeFullName',
      backendFilterName: ''
    },
    {
      title: 'Employee Department',
      field: 'employeeDepartmentName',
      backendFilterName: ''
    },
    {
      title: 'Employee Location',
      field: 'location',
      backendFilterName: '',
      formatter: scope.gridLocationFormatter
    },
    {
      title: 'Employee checkin',
      field: 'dateTimeIn',
      backendFilterName: '',
      formatter: scope.gridDateFormatter,
      bottomCalc: 'min',
      bottomCalcFormatter: scope.gridDateFormatter,
      minWidth: 150
    },
    {
      title: 'Employee checkout',
      field: 'dateTimeOut',
      backendFilterName: '',
      formatter: scope.gridDateFormatter,
      bottomCalc: 'max',
      bottomCalcFormatter: scope.gridDateFormatter,
      minWidth: 150
    },
    {
      title: 'Duration',
      field: 'duration',
      backendFilterName: '',
      formatter: scope.gridDurationFormatter,
      bottomCalc: scope.durationCalculation,
      bottomCalcFormatter: scope.formatMinutesToHours
    },
    {
      title: 'Status',
      field: 'status',
      backendFilterName: '',
      formatter: scope.gridStatusFormatter
    },
    {
      title: 'Description',
      field: 'description',
      backendFilterName: ''
    }
  ];
}
