import {LeaveStatus, LeaveType} from "./leave";

export enum ReportFileType {
  Pdf ="Pdf",
  Xlsx = "Xlsx"
}

export enum ReportType {
  AggregatedSummary = "AggregatedSummary"
}

export enum AggregatedSummaryTimesheetCalculationType {
  WorkingHours= "WorkingHours",
  Reported = "Reported"
}

export interface ReportFilter {
  type: ReportType;
  employeeBadgeNumberList: number[];
  startDateLessThan: string;
  startDateLessThanOrEqualTo: string;
  startDateGreaterThan: string;
  startDateGreaterThanOrEqualTo: string;
  endDateLessThan: string;
  endDateLessThanOrEqualTo: string;
  endDateGreaterThan: string;
  endDateGreaterThanOrEqualTo: string;
}

export interface AggregatedSummary {
  startDate: string;
  endDate: string;
  employeeBadgeNumber: number;
  employeeFirstName: string;
  employeeLastName: string;
  departmentName: string;
  timesheetTotalDays: number;
  timesheetTotalHours: number;
  officeTimesheetHours: number;
  remoteTimesheetHours: number;
  businessLeaveTimesheetHours: number;
  leaveTotalDays: number;
}
