<div class="container-fluid">
  <div>
    <gits-tabulator-table [tableId]="tableId"
                          [initLang]="currentLang"
                          [initWithoutTranslationsCheck]="true"
                          [columnsDefs]="columnsDefs"
                          [columnLabels]="columnLabels"
                          [options]="gridOptions"
                          [data]="gridData"
                          [paginatorOptions]="paginationOptions"
                          [addRowButtons]="rowControls"
                          [groupByFieldsConfig]=""
                          [customValidators]="[]"
                          [debugConfig]=""
                          [showHelp]=""
                          (apiAvailable)="onTableApiAvailable($event)"
                          (pageChanged)="onPageChanged($event)">
    </gits-tabulator-table>
  </div>
</div>
