<div class="container-fluid">
  <form [formGroup]="formGroup" (ngSubmit)="saveConfigurations()">
    <div class="row justify-content-md-center">
      <div class="col-md-6">
        <mat-accordion>
          <p>
            <mat-expansion-panel hideToggle expanded>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon>beach_access</mat-icon>
                  {{ 'leave.leaves' | translate }}
                </mat-panel-title>
                <mat-panel-description>
                  <span [matTooltip]="formGroup.value.leaveEnabled ? translateService.instant('generic.active') :
                  translateService.instant('generic.inactive')">
              <mat-slide-toggle formControlName="leaveEnabled" color="primary"></mat-slide-toggle>
              </span>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <mat-divider></mat-divider>

              <mat-checkbox formControlName="leaveSubstituteRequired" color="primary">
                <div class="text-wrap">
                  {{ 'leave.config.leaveSubstituteRequired' | translate }}
                </div>
              </mat-checkbox>

              <mat-divider></mat-divider>

              <mat-checkbox formControlName="leaveAllowBusySubstitute" color="primary">
                <div class="text-wrap">
                  {{ 'leave.config.leaveAllowBusySubstitute' | translate }}
                </div>
              </mat-checkbox>

              <mat-divider></mat-divider>

              <mat-checkbox formControlName="leaveAllowRequestOnCredit" color="primary">
                <div class="text-wrap">
                  {{ 'leave.config.leaveAllowRequestOnCredit' | translate }}
                </div>
              </mat-checkbox>
              <div class="description">
                {{ 'leave.config.leaveAllowRequestOnCreditDesc' | translate }}
              </div>

              <mat-divider></mat-divider>

              <span class="text-wrap">{{ 'leave.config.leaveMaxLeaveOfAbsencePeriod' | translate }}</span>
              <mat-form-field appearance="fill">
                <input type="number" matInput formControlName="leaveMaxLeaveOfAbsencePeriod"/>
              </mat-form-field>

              <mat-divider></mat-divider>

              <span>{{ 'leave.config.leaveCalendarDisplayFormat' | translate }}</span>
              <mat-form-field appearance="fill">
                <mat-select formControlName="leaveCalendarDisplayFormat">
                  <mat-option *ngFor="let format of CalendarView | keyvalue" [value]="format.value">
                    {{ "dateTime." + format.value | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-divider></mat-divider>

              <mat-checkbox formControlName="leaveSkipPlanning" color="primary">
                <div class="text-wrap">
                  {{ 'leave.config.leaveSkipPlanning' | translate }}
                </div>
              </mat-checkbox>
              <div class="description">
                {{ 'leave.config.leaveSkipPlanningDesc' | translate }}
              </div>

              <mat-divider></mat-divider>

              <mat-checkbox formControlName="leaveNotifyHrManagers" color="primary">
                <div class="text-wrap">
                  {{ 'leave.config.leaveNotifyHrManagers' | translate }}
                </div>
              </mat-checkbox>
              <div class="description">
                {{ 'leave.config.leaveNotifyHrManagersDesc' | translate }}
              </div>

            </mat-expansion-panel>
          </p>
          <p>
            <mat-expansion-panel hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon>alarm_on</mat-icon>
                  {{ 'timesheet.timesheets' | translate }}
                </mat-panel-title>
                <mat-panel-description>
                  <span [matTooltip]="formGroup.value.timesheetEnabled ? translateService.instant('generic.active') :
                  translateService.instant('generic.inactive')">
              <mat-slide-toggle formControlName="timesheetEnabled"
                                color="primary"></mat-slide-toggle>
              </span>
                </mat-panel-description>
              </mat-expansion-panel-header>

            </mat-expansion-panel>
          </p>
          <p>
            <mat-expansion-panel hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon>today</mat-icon>
                  {{ 'event.dailyEvents' | translate }}
                </mat-panel-title>
                <mat-panel-description>
                  <span [matTooltip]="formGroup.value.dailyEventsEnabled ? translateService.instant('generic.active') :
                  translateService.instant('generic.inactive')">
                    <mat-slide-toggle formControlName="dailyEventsEnabled"
                                      color="primary"></mat-slide-toggle>
                  </span>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <mat-divider></mat-divider>

              <mat-checkbox formControlName="dailyEventsShowBirthAnniversary" color="primary">
                <div class="text-wrap">
                  {{ 'event.config.dailyEventsShowBirthAnniversary' | translate }}
                </div>
              </mat-checkbox>

            </mat-expansion-panel>
          </p>
          <p>
            <mat-expansion-panel hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon>business_center</mat-icon>
                  {{ 'leave.businessLeaves' | translate }}
                </mat-panel-title>
                <mat-panel-description>
                  <span [matTooltip]="formGroup.value.businessLeaveEnabled ? translateService.instant('generic.active') :
                  translateService.instant('generic.inactive')">
                    <mat-slide-toggle formControlName="businessLeaveEnabled" color="primary"></mat-slide-toggle>
                  </span>
                </mat-panel-description>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
          </p>

        </mat-accordion>
      </div>
    </div>

    <div class="row justify-content-md-around">
      <div class="col-md-6">
        <button mat-flat-button color="primary" class="m-1" type="submit" [disabled]="actionInProgress">
          <!-- formGroup.pristine || actionInProgress -->
          {{ "generic.save" | translate }}
        </button>
        <button mat-flat-button color="secondary" class="m-1" type="button" (click)="location.back()">
          {{ "generic.back" | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
