export interface UserNotification {
  id: number;
  subject: string | null;
  message: string | null;
  type: UserNotificationType;
  read: boolean | null;
  priority: number | null;
  sourceUserAccountId: number | null;
  sourceUserName: string | null;
  targetUserAccountId: number | null;
  receivedDate: string | null;
  expirationDate: string | null;
  fullDisplay: boolean | null;
  isBeingEdited: boolean | null;
}

export interface UserNotificationFilter {
  targetUserAccountId: number;
  read: boolean | string;
}

export enum UserNotificationType {
  Error = 'Error',
  Info = 'Info',
  Success = 'Success',
  Warning = 'Warning'
}

export enum UserNotificationPriorities {
  Low = 'Low',
  Normal = 'Normal',
  High = 'High'
}
