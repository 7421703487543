import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subject, timer, zip} from 'rxjs';
import {UserNotificationService} from '../../services/user-notification.service';
import {concatMap, mergeMap, share, takeUntil} from 'rxjs/operators';
import {UserNotification, UserNotificationType} from 'src/app/interfaces/user-notification';
import {SessionService} from '../../services/session.service';
import {SessionData} from '../../interfaces/session';
import {EmployeeService} from '../../services/employee.service';
import {DateTimeService} from '../../services/date-time.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-notification-panel',
  templateUrl: './user-notification-panel.component.html',
  styleUrls: ['./user-notification-panel.component.less']
})
export class UserNotificationPanelComponent implements OnInit, OnDestroy {

  sessionData = this.sessionService.getData() as SessionData;
  userNotifications: UserNotification[] = [];
  cacheUserNotifications: UserNotification[] = [];
  userNotificationTypes: string[] = [];
  userNotificationFilterTypes: string[] = [];
  selectedFilter = 'All';
  destroy$ = new Subject<void>();
  pageSize = 3;
  currentPage = 1;
  totalItems = 0;
  currentPageNumberOfElements = 0;
  isError = false;
  refreshInterval = 60; // seconds
  refreshTimer$: Observable<number> = timer(0, this.refreshInterval * 1000);
  refreshTimerSubject$: Subject<number> = new Subject<number>();  // used to trigger refresh directly

  constructor(private userNotificationService: UserNotificationService,
              private sessionService: SessionService,
              private employeeService: EmployeeService,
              public dateTimeService: DateTimeService,
              private router: Router) {
    this.refreshTimer$.subscribe(this.refreshTimerSubject$);
  }

  ngOnInit(): void {
    this.buildNotificationTypes();
    this.loadUserNotifications();
  }

  buildNotificationTypes(): void {
    for (const type in UserNotificationType) {
      if (type) {
        this.userNotificationTypes.push(type);
      }
    }

    this.userNotificationFilterTypes = ['All'].concat(this.userNotificationTypes);
  }

  loadUserNotifications(): void {
    this.refreshTimerSubject$
      .pipe(
        takeUntil(this.destroy$),
        mergeMap(() => {
          this.isError = false;
          return this.userNotificationService.getUserNotifications({
              targetUserAccountId: this.sessionData.currentUser.id
            },
            {
              page: this.currentPage,
              size: this.pageSize
            })
            .pipe(
              takeUntil(this.destroy$)
            );
        }),
        mergeMap(value => {
          this.cacheUserNotifications.length = 0;
          this.totalItems = value.totalElements;
          this.currentPageNumberOfElements = value.numberOfElements;
          return value.content;
        }),
        concatMap(notification => {
          return zip(of(notification),
            this.employeeService.getEmployee(notification.sourceUserAccountId, false)
              .pipe(
                takeUntil(this.destroy$)
              )
          );
        }),
        share()
      )
      .subscribe(
        ([notification, sourceEmployee]) => {
          if (sourceEmployee) {
            notification.sourceUserName = sourceEmployee.firstName + ' ' + sourceEmployee.lastName;
          }
          // cache each notification
          this.cacheUserNotifications.push(notification);
          // until we've got all notifications on current page
          if (this.cacheUserNotifications.length === this.currentPageNumberOfElements) {
            this.userNotifications = this.cacheUserNotifications;
            this.cacheUserNotifications = [];
          }
        },
        error => {
          this.isError = true;
        }
      );
  }

  onPageChange(newPageNumber: number): void {
    this.refreshTimerSubject$.next(500);
  }

  displayIcon(notification: UserNotification): string {
    if (notification.type !== null) {
      switch (notification.type) {
        case UserNotificationType.Error:
          return 'cancel';
        case UserNotificationType.Info:
          return 'info';
        case UserNotificationType.Success:
          return 'check_circle';
        case UserNotificationType.Warning:
          return 'warning';
      }
    } else {
      return '';
    }
  }

  displayIconColor(notification: UserNotification): string {
    if (notification.type !== null) {
      switch (notification.type) {
        case UserNotificationType.Error:
          return 'warn';
        case UserNotificationType.Info:
          return 'basic';
        case UserNotificationType.Success:
          return 'primary';
        case UserNotificationType.Warning:
          return 'accent';
      }
    } else {
      return 'basic';
    }
  }

  displayHeader(notification: UserNotification): string {
    if (notification === null || notification === undefined) {
      return '';
    } else {
      return notification.subject as string;
    }
  }

  parse(notification: UserNotification): [number, string][] {
    const ans = [] as [number, string][];
    if (notification !== null && notification !== undefined &&  notification.message !== null) {
      let message = notification.message;

      if (!notification.fullDisplay) {
        // limit the message length
        message = message.substring(0, 250);
        const lastIndexOfSpace = message.lastIndexOf(' ');
        const lastIndexOfLink = message.lastIndexOf('http');
        // remove any link at the end that might be partial
        if (lastIndexOfSpace < lastIndexOfLink) {
          message = message.substring(0, lastIndexOfLink);
        }
      }

      message.split(' ') // split into words
        .forEach(word => {
          if (/(\b(https?|ftp):\/\/[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|])/gim.test(word)) { // http(s) and ftp links
            ans.push([1, word + ' ']);
          } else if (/(([a-z0-9\-_.])+@[a-z_]+?(\.[a-z]+)+)/gim.test(word)) { // mail addresses
            ans.push([2, word + ' ']);
          } else { // anything else
            ans.push([0, word + ' ']);
          }
        });
    }

    return ans;
  }

  getNotifications(filter: string): UserNotification[] {
    if (filter === 'All') {
      return this.userNotifications;
    } else {
      return this.userNotifications.filter(notification => notification.type === filter);
    }
  }

  addNotification(): void {
    this.router.navigate(['users/user-notification']);
  }

  notificationBelongsToCurrentUser(notification: UserNotification): boolean {
    return notification.sourceUserAccountId === this.sessionData.currentUser.id;
}

  toggleNotificationRead(notification: UserNotification): void {
    notification.read = !notification.read;
    this.updateNotification(notification);
  }

  openNotification(notification: UserNotification): void {
    notification.fullDisplay = true;
  }

  editNotification(notification: UserNotification): void {
   this.router.navigate(['users//user-notification/' + notification.id]);
  }

  updateNotification(notification: UserNotification): void {
    this.userNotificationService.updateUserNotification(notification)
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe();
  }

  deleteNotification(notification: UserNotification): void {
    this.userNotificationService.deleteUserNotification(notification.id)
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        this.refreshTimerSubject$.next(500);
        this.userNotifications.splice(this.userNotifications.indexOf(notification), 1);
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
