import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LeaveService} from '../../../services/leave.service';
import {endOfDay, isSameDay, isSameMonth, startOfDay} from 'date-fns';
import {forkJoin, Observable, of, Subject, throwError} from 'rxjs';
import {CalendarEvent, CalendarView, DAYS_OF_WEEK} from 'angular-calendar';
import {Leave, LeaveFilter, LeaveStatus, LeaveType} from '../../../interfaces/leave';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {catchError, map, mergeAll, mergeMap, takeUntil} from 'rxjs/operators';
import {EmployeeService} from '../../../services/employee.service';
import {Employee} from '../../../interfaces/employee';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../../services/language.service';
import {DateTimeService} from '../../../services/date-time.service';
import {SessionService} from '../../../services/session.service';
import {ServiceConfigurationProperties} from '../../../interfaces/service-configuration';
import {ServiceConfigurationService} from '../../../services/service-configuration.service';
import {GitsCalendarView} from "../../../interfaces/calendar";
import {LeaveManagerComponent} from "../leave-manager/leave-manager.component";

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  green: {
    primary: '#008000',
    secondary: '#d0f0c0'
  },
  grey: {
    primary: '#808080',
    secondary: '#dcdcdc'
  }
};

@Component({
  selector: 'app-leaves-calendar',
  templateUrl: './leaves-calendar.component.html',
  styleUrls: ['./leaves-calendar.component.less'],
  providers: [DatePipe],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeavesCalendarComponent implements OnInit, OnDestroy {

  readonly CalendarView = CalendarView;
  readonly GitsCalendarView = GitsCalendarView;
  readonly LeaveType = LeaveType;

  private leaveCalendarDisplayFormat: string | null = ServiceConfigurationService.getPropertyValue(
    ServiceConfigurationProperties.LeaveCalendarDisplayFormat, GitsCalendarView.Month);
  private leaveShowCanceledInCalendar: boolean | null = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.LeaveShowCanceledInCalendar, false);
  readonly weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  locale: string = this.languageService.getCurrentLocale();
  filter: string | null = null;
  view: GitsCalendarView = GitsCalendarView.Week;
  calendarView: CalendarView  = CalendarView.Week;
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  events$: Observable<CalendarEvent<{ leave: Leave }>[]> = new Observable<CalendarEvent<{ leave: Leave }>[]>();
  leaveFilter: Partial<LeaveFilter> = {};
  activeDayIsOpen = true;
  destroy$ = new Subject<void>();
  @ViewChild('leaveManager', { static: false }) leaveManager!: LeaveManagerComponent;

  constructor(private activatedRoute: ActivatedRoute,
              private leaveService: LeaveService,
              private employeeService: EmployeeService,
              private translateService: TranslateService,
              private languageService: LanguageService,
              private router: Router,
              private datePipe: DatePipe,
              private dateTimeService: DateTimeService,
              private sessionService: SessionService) {
  }

  ngOnInit(): void {
    this.loadPageParameters(this.activatedRoute);
    this.subscribeForServiceConfigurations();
    if (this.leaveCalendarDisplayFormat !== null &&
      Object.values(CalendarView).indexOf(this.leaveCalendarDisplayFormat as CalendarView) >= 0) {
      this.view = this.leaveCalendarDisplayFormat as GitsCalendarView;
      this.calendarView = this.leaveCalendarDisplayFormat as CalendarView;
    }
    this.loadLeaves(this.viewDate, this.view);
    this.subscribeToLanguageChanges();
  }

  subscribeToLanguageChanges(): void {
    this.languageService.getCurrentLocaleObservable()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(localeId => {
        this.locale = localeId;
        // TODO: re-compute events instead or re-load?
        this.loadLeaves(this.viewDate, this.view);
      });
  }

  dayClicked({date, events}: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  handleEvent(action: string, eventOrDate: CalendarEvent | Date): void {
    if (action === 'Clicked') {
      if (eventOrDate == null) {
        return;
      }
      if (eventOrDate instanceof Date) {
        this.addLeave(eventOrDate);
      } else {
        this.editLeave(eventOrDate.id as number);
      }
    }
  }

  addLeave(eventDate?: Date): void {
    let navigationParams: Partial<NavigationExtras> = {
      queryParams: {}
    };
    if (eventDate) {
      navigationParams = {
        queryParams:
          {startDate: this.datePipe.transform(eventDate, 'yyyy-MM-dd')}
      };
    }
    if (this.filter) {
      (navigationParams.queryParams as any).filter = this.filter;
    }
    this.router.navigate(['/leaves/leave'], navigationParams);
  }

  editLeave(id: number): void {
    this.router.navigate(['/leaves/leave/' + id]);
  }

  loadLeaves(referenceDate: Date, period: GitsCalendarView): void {
    const leavesFilter: Partial<LeaveFilter> = {};
    if (this.filter === LeaveType.Business) {
      leavesFilter.typeIn = [ LeaveType.Business];
    } else {
      leavesFilter.typeNotIn = [ LeaveType.Business];
    }

    if (this.leaveShowCanceledInCalendar === null || !this.leaveShowCanceledInCalendar) {
      leavesFilter.statusLessThan = LeaveStatus.Canceled;
    }
    if (period === GitsCalendarView.Month) {
      leavesFilter.startDateLessThanOrEqualTo = this.dateTimeService.formatDate(this.dateTimeService.endOfMonth(referenceDate), 'yyyy-MM-dd');
      leavesFilter.endDateGreaterThanOrEqualTo = this.dateTimeService.formatDate(this.dateTimeService.startOfMonth(referenceDate), 'yyyy-MM-dd');
    }
    if (period === GitsCalendarView.Week) {
      leavesFilter.startDateLessThanOrEqualTo = this.dateTimeService.formatDate(this.dateTimeService.endOfWeek(referenceDate, {weekStartsOn: 1}), 'yyyy-MM-dd');
      leavesFilter.endDateGreaterThanOrEqualTo = this.dateTimeService.formatDate(this.dateTimeService.startOfWeek(referenceDate, {weekStartsOn: 1}), 'yyyy-MM-dd');
    }
    if (period === GitsCalendarView.Day) {
      leavesFilter.startDateLessThanOrEqualTo = this.dateTimeService.formatDate(referenceDate, 'yyyy-MM-dd');
      leavesFilter.endDateGreaterThanOrEqualTo = this.dateTimeService.formatDate(referenceDate, 'yyyy-MM-dd');
    }
    const pastLeavesQuery = this.leaveService.getLeaves(leavesFilter);
    this.events$ = of(pastLeavesQuery)
      .pipe(
        mergeAll(),
        mergeMap(page => {
          if (page.numberOfElements === 0) {
            return of([]);
          }
          const leaveEvent = page.content.map((leave: Leave) =>
            this.computeEventFromLeave(leave));
          return forkJoin(...leaveEvent);
        }),
        catchError(error => {
            return throwError(this.translateService.instant('leave.messages.errLoading', {error}));
          }
        ),
        takeUntil(this.destroy$)
      );
  }

  computeEventFromLeave(leave: Leave): Observable<CalendarEvent<{ leave: Leave }>> {
    return this.employeeService.getEmployee(leave.employeeBadgeNumber, false)
      .pipe(
        map((employee: Employee) => {
          return {
            id: leave.id,
            start: startOfDay(new Date(leave.startDate as string)),
            end: endOfDay(new Date(leave.endDate as string)),
            title: this.translateService.instant('leave.types.' + leave.type) + ': ' + employee.lastName + ' ' + employee.firstName +
              ' (' + String(leave.employeeBadgeNumber) + ') ' +
              ' [' + this.dateTimeService.formatDate(leave.startDate) + ' - ' + this.dateTimeService.formatDate(leave.endDate) + '] ' +
              ' - ' + this.translateService.instant('leave.statuses.' + leave.status),
            allDay: true,
            color: this.computeLeaveColor(leave),
            draggable: false,
            resizable: {
              beforeStart: true,
              afterEnd: true,
            },
            meta: {leave}
          };
        }),
        takeUntil(this.destroy$)
      );
  }

  onViewChange(newView: GitsCalendarView): void {
    if (newView === undefined) {
      return;
    }
    if (Object.values<string>(CalendarView).includes(newView)) {
      this.calendarView = newView as unknown as CalendarView;
    }
    if (Object.values<string>(GitsCalendarView).includes(newView)) {
      this.view = newView;
    }

    this.loadLeaves(this.viewDate, this.view);
  }

  onViewDateChange(newDate: Date): void {
    this.loadLeaves(newDate, this.view);
    this.activeDayIsOpen = false;
  }

  private computeLeaveColor(leave: Leave): any {
    switch (leave.status) {
      case LeaveStatus.New:
        return colors.blue;
      case LeaveStatus.Planned:
      case LeaveStatus.SubstituteApproved:
      case LeaveStatus.ManagerApproved:
        return colors.yellow;
      case LeaveStatus.SubstituteRejected:
      case LeaveStatus.ManagerRejected:
      case LeaveStatus.HumanResourcesRejected:
        return colors.red;
      case LeaveStatus.HumanResourcesApproved:
      case LeaveStatus.Finalized:
        return colors.green;
      case LeaveStatus.Canceled:
        return colors.red;
      default:
        return colors.grey;
    }
  }

  private loadPageParameters(route: ActivatedRoute): void {
    route.queryParamMap.subscribe(
      params => {
        const newFilter = params.get('filter');
        if (this.filter !== newFilter) {
          this.filter = newFilter;
          this.loadLeaves(this.viewDate, this.view);
        }
      });
  }

  private subscribeForServiceConfigurations(): void {
    this.sessionService.getServiceConfigurationsObservable()
      .subscribe(
        configurations => {
          this.leaveCalendarDisplayFormat = ServiceConfigurationService.getPropertyValue(
            ServiceConfigurationProperties.LeaveCalendarDisplayFormat, this.leaveCalendarDisplayFormat as string, configurations);
          this.leaveShowCanceledInCalendar = ServiceConfigurationService.getBooleanPropertyValue(
            ServiceConfigurationProperties.LeaveShowCanceledInCalendar, this.leaveShowCanceledInCalendar as boolean, configurations);
        }
      )
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
