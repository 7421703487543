<div>
  <h1>{{"reports.paidLeavesReport.title" | translate}}</h1>
  <div class="row justify-content-md-left">
    <div class="col-md-1">
      <mat-form-field appearance="fill" class="full-fill">
        <mat-label>{{"reports.paidLeavesReport.year" | translate}}</mat-label>
        <mat-select [(value)]="yearMax" (selectionChange)="yearChange($event.value)">
          <mat-option *ngFor="let year of yearList" [value]="year">
            {{year}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div div class="col-md-1">
      <mat-form-field appearance="fill" class="full-fill">
        <mat-label>{{"reports.paidLeavesReport.month" | translate}}</mat-label>
        <mat-select [(value)]="currentMonth" (selectionChange)="monthChange($event.value)">
          <mat-option *ngFor="let month of monthList" [value]="month">
            {{month}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-1">
      <mat-form-field appearance="fill" class="full-fill">
        <mat-label>{{"reports.paidLeavesReport.department" | translate}}</mat-label>
        <mat-select value="all" (selectionChange)="departmentChange($event.value)">
          <mat-option value="all">{{"reports.paidLeavesReport.all" | translate | uppercase}}</mat-option>
          <mat-option *ngFor="let department of departmentList" [value]="department">
            {{department.department}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2">
      <mat-form-field appearance="fill" class="full-fill">
        <mat-label>{{"reports.paidLeavesReport.workstation" | translate}}</mat-label>
        <mat-select value="all" (selectionChange)="workstationChange($event.value)">
          <mat-option value="all">{{"reports.paidLeavesReport.all" | translate | uppercase}}</mat-option>
          <mat-option *ngFor="let workstation of workstationList" [value]="workstation">
            {{workstation.workstation}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2">
      <mat-form-field appearance="fill" class="full-fill">
        <mat-label>{{"reports.paidLeavesReport.position" | translate}}</mat-label>
        <mat-select value="all" (selectionChange)="positionChange($event.value)">
          <mat-option value="all">{{"reports.paidLeavesReport.all" | translate | uppercase}}</mat-option>
          <mat-option *ngFor="let position of positionList" [value]="position">
            {{position.position}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="fill">
        <mat-label>{{"reports.paidLeavesReport.referenceDate" | translate}}</mat-label>
        <input (dateInput)="referenceDateChange($event.value)" matInput [matDatepicker]="picker" [(ngModel)]="referenceDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-md-1">
      <button mat-flat-button color="secondary" class="float-right" (click)="exporter.exportTable('xlsx', {fileName:'raport_co'})">Export Excel</button>
    </div>
  </div>
  <div matSort class="mat-elevation-z8">
    <table mat-table matTableExporter [dataSource]="dataSource" #exporter="matTableExporter">

      <ng-container matColumnDef="badgeNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by badgeNumber">
          {{"reports.paidLeavesReport.badge" | translate}}
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: left;"> {{element.badgeNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by lastName">
          {{"reports.paidLeavesReport.lastName" | translate}}
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: left;"> {{element.lastName}} </td>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by firstName">
          {{"reports.paidLeavesReport.firstName" | translate}}
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: left;"> {{element.firstName}} </td>
      </ng-container>

      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by position">
          {{"reports.paidLeavesReport.position" | translate}}
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: left;"> {{element.position}} </td>
      </ng-container>

      <ng-container matColumnDef="pid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by pid">
          {{"reports.paidLeavesReport.pid" | translate}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.pid}} </td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by startDate">
          {{"reports.paidLeavesReport.startDate" | translate}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.startDate}} </td>
      </ng-container>

      <ng-container matColumnDef="allowedDays">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by allowedDays">
          {{"reports.paidLeavesReport.allowedDays" | translate}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.allowedDays}} </td>
      </ng-container>

      <ng-container matColumnDef="residualDays">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by residualDays">
          {{"reports.paidLeavesReport.residualDays" | translate}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.residualDays}} </td>
      </ng-container>

      <ng-container matColumnDef="totalDays">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by totalDays">
          {{"reports.paidLeavesReport.totalDays" | translate}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.totalDays}} </td>
      </ng-container>

      <ng-container matColumnDef="usedDays">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by usedDays">
          {{"reports.paidLeavesReport.usedDays" | translate}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.usedDays}} </td>
      </ng-container>

      <ng-container matColumnDef="availableDays">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by availableDays">
          {{"reports.paidLeavesReport.availableDays" | translate}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.availableDays}} </td>
      </ng-container>

      <ng-container matColumnDef="allowedDaysOnReferenceDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by allowedDaysOnReferenceDate">
          {{"reports.paidLeavesReport.allowedDaysOnReferenceDate" | translate}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.allowedDaysOnReferenceDate}} </td>
      </ng-container>

      <ng-container matColumnDef="department">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by department">
          {{"reports.paidLeavesReport.department" | translate}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.department}} </td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by endDate">
          {{"reports.paidLeavesReport.endDate" | translate}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.endDate}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons>
    </mat-paginator>

  </div>
