import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Department, DepartmentFilter} from '../interfaces/department';
import {ErrorService} from './error.service';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  constructor(private http: HttpClient) {
  }

  static departmentApi: string = environment.backendServiceUrl + '/' + environment.backendApiContext + '/' + 'departments';

  get(id: number): Observable<Department> {
    return this.http.get<Department>(DepartmentService.departmentApi + '/' + id)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  filter(departmentFilter?: Partial<DepartmentFilter>): Observable<Department[]> {
    let params = new HttpParams();
    if (departmentFilter !== undefined) {
      Object.entries(departmentFilter).forEach(([key, value]) => params = params.append(key, value as string));
    }
    return this.http.get<Department[]>(DepartmentService.departmentApi + '/', {params})
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  getFlatDepartmentTree(departmentList: Department[]): Department[] {
    if (departmentList === null || departmentList === undefined) {
      return [];
    }

    const children = departmentList.map(
      department => this.getFlatDepartmentTree(department.children)
    )
      .reduce( // flatten from [][] to []
        (accumulator, value) =>
          accumulator.concat(value), []
      );

    const fullList = departmentList.concat(children);
    const uniqueDepartmentNames = new Set();

    return fullList.filter(department => {
      if (uniqueDepartmentNames.has(department.name)) {
        return false;
      }
      uniqueDepartmentNames.add(department.name);
      return true;
    });
  }
}
