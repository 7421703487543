import {ColumnsTranslationsConfig, IColumnDef} from '@gits/ngx-tabulator-tables';
import {DebugConfigModel} from '@gits/ngx-tabulator-tables/lib/models/debug-config.model';
import {AggregatedSummaryReportComponent} from './aggregated-summary-report.component';

export const GRID_LEAVE_REQUEST_REPORT_OPTIONS: any = {
  renderHorizontal: 'basic',
  layout: "fitData",
  movableRows: false,
  movableColumns: true,
  selectable: false,
  height: 400,
  // sortMode: 'remote', // todo wait for backend implementation

  // by default, Tabulator will wait 300 milliseconds after a keystroke before triggering the filter.
  // you can customise this delay by using the [headerFilterLiveFilterDelay] setting
  headerFilterLiveFilterDelay: 500,
  bufferingTimeForTableEvents: 450, // accepted interval >= 150 <= 500 (below or above the default value of 500ms will be used)
  columnDefaults: {
    minWidth: 90
  }
};

export const GRID_LEAVE_REQUEST_REPORT_DEBUG_OPTIONS: DebugConfigModel = {
  pauseTableRenderingOnInit: false,
  showDebugPanel: false,
  debugRxjsAssets: false,
  debugTableHeaderFilters: false,
  debugTableEventsBuffering: false
};

export const GRID_LEAVE_REQUEST_REPORT_LABELS: ColumnsTranslationsConfig = {
  languages: ['en-GB', 'ro-RO'],
  columnsWithLabels: {
    __rowActions: 'generic.tasks', // special ACTIONS column
    __dataTree: 'NO_LABEL/NOT_IN_USE', // special DATA_TREE column
    employeeBadgeNumber: 'employee.badgeNumber',
    employeeLastName: 'generic.firstName',
    employeeFirstName: 'generic.lastName',
    departmentName: 'employee.department',
    startDate: 'generic.startDate',
    endDate: 'generic.endDate',
    timesheetTotalDays: 'timesheet.totalDays',
    timesheetTotalMissingDays: 'timesheet.totalMissingDays',
    timesheetTotalHours: 'timesheet.totalHours',
    officeTimesheetHours: 'timesheet.officeHours',
    remoteTimesheetHours: 'timesheet.remoteHours',
    businessLeaveTimesheetHours: 'timesheet.businessLeaveHours',
    leaveTotalDays: 'leave.totalDays',
    businessLeaveTotalDays: 'leave.totalBusinessLeaveDays'
  }
};

export function getGridLeaveRequestColumnDefs(scope: AggregatedSummaryReportComponent): IColumnDef[] {
  return [
    {
      title: 'Employee Badge No',
      field: 'employeeBadgeNumber',
      backendFilterName: '',
      minWidth: 60
    },
    {
      title: 'Employee Last Name',
      field: 'employeeLastName',
      backendFilterName: ''
    },
    {
      title: 'Employee First Name',
      field: 'employeeFirstName',
      backendFilterName: ''
    },
    {
      title: 'Department',
      field: 'departmentName',
      backendFilterName: ''
    },
    {
      title: 'Total leaves (days)',
      field: 'leaveTotalDays',
      backendFilterName: '',
      bottomCalc: "sum"
    },
    {
      title: 'Total business leaves (days)',
      field: 'businessLeaveTotalDays',
      backendFilterName: '',
      bottomCalc: "sum"
    },
    {
      title: 'Total timesheets (days)',
      field: 'timesheetTotalDays',
      backendFilterName: '',
      bottomCalc: "sum"
    },
    {
      title: 'Total missing timesheets (days)',
      field: 'timesheetTotalMissingDays',
      backendFilterName: '',
      bottomCalc: "sum"
    },
    {
      title: 'Total timesheets (hours)',
      field: 'timesheetTotalHours',
      backendFilterName: '',
      bottomCalc: "sum"
    },
    {
      title: 'Total office timesheets (hours)',
      field: 'officeTimesheetHours',
      backendFilterName: '',
      bottomCalc: "sum"
    },
    {
      title: 'Total remote timesheets (hours)',
      field: 'remoteTimesheetHours',
      backendFilterName: '',
      bottomCalc: "sum"
    },
    {
      title: 'Total business leave timesheets (hours)',
      field: 'businessLeaveTimesheetHours',
      backendFilterName: '',
      bottomCalc: "sum"
    }
  ];
}
