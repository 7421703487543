<mat-card>
  <mat-card-title>
    <mat-icon>calendar_today</mat-icon>
    {{"event.eventsThisMonth" | translate}}
  </mat-card-title>
  <mat-card-content *ngIf="leaveLoadComplete">
    <mat-calendar [dateFilter]="weekendsAndHolidaysFilter" [dateClass]="computeDateClass()"
    (selectedChange)="onSelectChange($event)" id="events-calendar"></mat-calendar>
  </mat-card-content>
</mat-card>
