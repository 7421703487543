import {Injectable} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {DateAdapter} from '@angular/material/core';
import {Locale} from 'date-fns';
import {SessionService} from './session.service';
import ro_RO from '@angular/common/locales/ro';
import en_GB from '@angular/common/locales/en-GB';
import {ro, enGB} from 'date-fns/locale';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private dateFnsLocales: Map<string, any> = new Map<string, any>();
  private localeId = new BehaviorSubject(environment.language);

  constructor(private translateService: TranslateService,
              private dateAdapter: DateAdapter<Date>,
              private sessionService: SessionService) {
  }

  localeInitializer(locales: string[]): void {
    if (locales && locales.length > 0) {
      this.translateService.addLangs(locales);
      locales.forEach(locale => {
        switch (locale) {
          case 'ro-RO' :
            registerLocaleData(ro_RO);
            this.dateFnsLocales.set(locale, ro);
            break;
          case 'en-GB':
            registerLocaleData(en_GB);
            this.dateFnsLocales.set(locale, enGB);
            break;
        }
      });
    }
  }

  setCurrentLocale(localeId: string): void {
    this.translateService.use(localeId);
    this.dateAdapter.setLocale(localeId);
    this.sessionService.updateData({currentLocale: localeId});
    this.localeId.next(localeId);
  }

  getCurrentFnsLocale(): Locale {
    return this.dateFnsLocales.get(this.translateService.currentLang);
  }

  getCurrentLocale(): string {
    return this.translateService.currentLang;
  }

  getCurrentLocaleObservable(): Observable<string> {
    return this.localeId.asObservable();
  }
}
