import {Component, OnInit} from '@angular/core';
import {LeaveType} from '../../interfaces/leave';
import {TimesheetType} from '../../interfaces/timesheet';
import {LocationType} from '../../interfaces/location';
import {addWeeks, endOfWeek, startOfWeek, subDays} from 'date-fns';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {UserRole} from 'src/app/interfaces/user';
import {ReportService} from '../../services/report.service';
import {saveAs} from 'file-saver';
import {ErrorService} from '../../services/error.service';
import {MatDialog} from '@angular/material/dialog';
import {EmploymentCertificateDialog} from '../../pages/reports/employment-certificate/employment-certificate-dialog';
import {ReportTemplateType} from '../../interfaces/report-template';
import {ServiceConfigurationService} from "../../services/service-configuration.service";
import {ServiceConfigurationProperties} from "../../interfaces/service-configuration";
import {SessionService} from "../../services/session.service";

@Component({
  selector: 'app-quick-access-panel',
  templateUrl: './quick-access-panel.component.html',
  styleUrls: ['./quick-access-panel.component.less']
})
export class QuickAccessPanelComponent implements OnInit {

  readonly LeaveType = LeaveType;
  readonly LocationType = LocationType;
  readonly TimesheetType = TimesheetType;
  displayPaidLeaveReport = false;
  leaveEnabled: boolean | null = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.LeaveEnabled, true);
  timesheetEnabled: boolean | null = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.TimesheetEnabled, true);
  businessLeaveEnabled: boolean | null = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.BusinessLeaveEnabled, true);

  constructor(public dialog: MatDialog,
              private authenticationService: AuthenticationService,
              private errorService: ErrorService,
              private reportService: ReportService,
              private sessionService: SessionService) {
  }

  ngOnInit(): void {
    this.displayReport();
    this.subscribeForServiceConfigurations();
  }

  computeStartOfNextWeek(): Date {
    const nextWeek = addWeeks(new Date(), 1);
    return startOfWeek(nextWeek, {weekStartsOn: 1});
  }

  computeEndOfNextWeek(): Date {
    const nextWeek = addWeeks(new Date(), 1);
    return subDays(endOfWeek(nextWeek, {weekStartsOn: 1}), 2);
  }

  downloadReport(): void {
    const dialogRef = this.dialog.open(EmploymentCertificateDialog);

    dialogRef.afterClosed()
      .subscribe(reason => {
        if (reason !== undefined) {
          const params: Map<string, object> = new Map<string, object>();
          params.set('reason', reason);
          this.reportService.get(ReportTemplateType.HumanResourcesEmploymentCertificate, params)
            .subscribe(response => {
                saveAs(response.body as Blob);
              },
              error => this.errorService.showError('reports.messages.unableToGenerate', {error}));
        }
      });
  }

  private displayReport(): void {
    if (this.authenticationService.currentUserHasRole(UserRole.departmentManager) ||
      this.authenticationService.currentUserHasRole(UserRole.humanResourceManager)) {
      this.displayPaidLeaveReport = true;
    }
  }

  private subscribeForServiceConfigurations(): void {
    this.sessionService.getServiceConfigurationsObservable()
      .subscribe(
        configurations => {
          this.leaveEnabled = ServiceConfigurationService.getBooleanPropertyValue(
            ServiceConfigurationProperties.LeaveEnabled, this.leaveEnabled as boolean, configurations);
          this.timesheetEnabled = ServiceConfigurationService.getBooleanPropertyValue(
            ServiceConfigurationProperties.TimesheetEnabled, this.timesheetEnabled as boolean, configurations);
        }
      )
  }
}
