export const environment = {
  name: 'test',
  production: false,
  backendServiceUrl: 'https://hr-api-test.gits.ro',
  backendApiContext: 'hr/api/v1',
  documentMangerServiceUrl: 'https://dm-api-test.gits.ro',
  documentMangerApiContext: 'dm/api/v1',
  language: 'ro-RO',
  features: {
    dateTimeIntervals: 'relative', // "distance", "relative", "datetime"
    languages: ['ro-RO', 'en-GB']
  }
};
