import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {switchMap, takeUntil} from 'rxjs/operators';
import {DepartmentService} from '../../services/department.service';
import {concatAll, EMPTY, Observable, Subject} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TimesheetFilter, TimesheetStatus} from '../../interfaces/timesheet';
import {Employee} from '../../interfaces/employee';
import {UserRole} from '../../interfaces/user';
import {EmployeeService} from '../../services/employee.service';
import {SessionData} from '../../interfaces/session';
import {SessionService} from '../../services/session.service';
import {AuthenticationService} from '../../services/authentication.service';
import {Department, DepartmentFilter} from '../../interfaces/department';
import {DateTimeService} from '../../services/date-time.service';
import {endOfMonth, startOfMonth} from 'date-fns';
import {LocationType} from '../../interfaces/location';
import {MatSelect} from '@angular/material/select';
import {MatOption} from '@angular/material/core';

@Component({
  selector: 'app-timesheet-filter',
  templateUrl: './timesheet-filter.component.html',
  styleUrls: ['./timesheet-filter.component.less']
})
export class TimesheetFilterComponent implements OnDestroy, OnInit {

  readonly TimesheetStatus = TimesheetStatus;
  readonly LocationType = LocationType;
  readonly ALL_VALUE = 'ALL';
  readonly defaultStatusSelection = [this.ALL_VALUE, ...Object.values(TimesheetStatus)];

  private currentUserIsDeptManger = this.authenticationService.currentUserHasRole(UserRole.departmentManager);
  private currentUserIsHrManger = this.authenticationService.currentUserHasRole(UserRole.humanResourceManager);
  private destroy$ = new Subject<void>();
  private sessionData: SessionData = this.sessionService.getData();

  departments: any[] = [];
  employees: Employee[] = [];
  formGroup: FormGroup;

  @Output()
  selectionChanged = new EventEmitter<Partial<TimesheetFilter>>();

  constructor(private authenticationService: AuthenticationService,
              private dateTimeService: DateTimeService,
              private departmentService: DepartmentService,
              private employeeService: EmployeeService,
              private formBuilder: FormBuilder,
              private sessionService: SessionService) {
    this.formGroup = this.formBuilder.group({
      departments: '',
      employeeBadgeNumbers: [[this.sessionData.currentUser.id]],
      dateInRange: this.formBuilder.group({
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date())
      }),
      locations: [[LocationType.BusinessLeave, LocationType.Office, LocationType.Remote]],
      statuses: [this.defaultStatusSelection, Validators.required],
    });
  }

  ngOnInit(): void {
    this.makeDefaultSelections();
    this.populateDepartments();
    this.populateEmployeeLists();
    this.onAnySelectionChange(false);
  }

  getFilter(): Partial<TimesheetFilter> {
    const filter: Partial<TimesheetFilter> = {
      departments: this.formGroup.controls.departments.value,
      employeeBadgeNumbers: this.formGroup.controls.employeeBadgeNumbers.value,
      dateTimeInLessThanOrEqualTo: this.dateTimeService.formatDate(this.formGroup.get('dateInRange.endDate')?.value, 'yyyy-MM-ddT23:59:59'),
      dateTimeOutGreaterThanOrEqualToOrNull: this.dateTimeService.formatDate(this.formGroup.get('dateInRange.startDate')?.value, 'yyyy-MM-ddT00:00:00'),
      locations: this.formGroup.controls.locations.value,
      statusIn: this.formGroup.controls.statuses.value
    };

    if (this.formGroup.controls.employeeBadgeNumbers.value.length === 0) {
      if (this.formGroup.controls.departments.value.length === 0) {
        filter.employeeBadgeNumbers = [-1];
        delete filter.departments;
      } else {
        delete filter.employeeBadgeNumbers;
      }
    }

    if (this.formGroup.controls.employeeBadgeNumbers.value[0] === this.ALL_VALUE) {
      delete filter.employeeBadgeNumbers;
    }

    if (this.formGroup.controls.departments.value.length === 0 ||
      this.formGroup.controls.departments.value[0] === this.ALL_VALUE) {
      delete filter.departments;
    }

    if (this.formGroup.controls.statuses.value[0] === this.ALL_VALUE) {
      filter.statusIn = this.formGroup.controls.statuses.value.slice(1);
    }

    return filter;
  }

  onAnySelectionChange(isChanging: boolean): void {
    if (isChanging) {
      return;
    }
    this.selectionChanged.emit(this.getFilter());
  }

  toggleAllSelection(matSelect: MatSelect): void {
    const isSelected: boolean = matSelect.options
      .filter((item: MatOption) => item.value === this.ALL_VALUE)
      .map((item: MatOption) => item.selected)[0];

    if (isSelected) {
      matSelect.options.forEach((item: MatOption) => item.select());
    } else {
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  private makeDefaultSelections(): void {
    if (this.currentUserIsHrManger) {
      this.formGroup.controls.employeeBadgeNumbers.setValue([-1]);
      this.formGroup.controls.departments.setValue([this.ALL_VALUE]);
    }
  }

  private populateDepartments(): void {
    const filter: Partial<DepartmentFilter> = {active: true};

    this.employeeService.getEmployee(this.sessionData.currentUser.id)
      .pipe(
        switchMap((employee: Employee) => {
          this.formGroup.controls.departments.setValue([employee.departmentName]);
          if (this.currentUserIsDeptManger && !this.currentUserIsHrManger) {
            filter.managerBadgeNumber = this.sessionData.currentUser.id;
          }
          if (!this.currentUserIsDeptManger && !this.currentUserIsHrManger) {
            filter.nameExact = employee.departmentName;
          }
          return this.departmentService.filter(filter);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        response => {
          response = this.sortDepartments(response);
          response.forEach(department => {
            this.departments.push(department.name);
          });
          if (this.currentUserIsHrManger) {
            this.formGroup.controls.departments.setValue([this.ALL_VALUE, ...this.departments]);
          }
        }
      );
  }

  private populateEmployeeLists(): void {
    // employee list will only have current user, by default
    let employeesList$: Observable<Employee[]> = this.employeeService.getActiveEmployees({
      employeeBadgeNumberList: [this.sessionData.currentUser.id]
    });
    // employee list will have all department active members, for dept mangers, from all managed depts
    if (this.currentUserIsDeptManger && !this.currentUserIsHrManger) {
      const departmentFilter = {
        managerBadgeNumber: this.sessionData.currentUser.id
      };
      employeesList$ = this.departmentService.filter(departmentFilter)
        .pipe(
          switchMap((departments: Department[]) => {
            if (departments === null || departments.length < 1) {
              return EMPTY;
            }
            const departmentEmployees$: Observable<Employee[]>[] = [];
            departments.forEach(department => {
              departmentEmployees$.push(this.employeeService.getActiveEmployees({
                departmentNameExact: department.name,
                recurseDepartments: true
              }));
            });
            return departmentEmployees$;
          }),
          concatAll()
        );
    }
    // employee list will have all active employees, for HR mangers
    if (this.currentUserIsHrManger) {
      employeesList$ = this.employeeService.getActiveEmployees({});
    }

    employeesList$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(employees => {
          this.employees.push(...employees);
          this.employees = this.makeEmployeeSet(this.employees);
          this.employees = this.sortEmployees(this.employees);
        }
      );
  }

  private makeEmployeeSet(employees: Employee[]): Employee[] {
    if (employees === undefined || employees === null) {
      return [];
    }
    return [...new Map(employees.map((item) => [item["badgeNumber"], item])).values()];
  }

  private sortDepartments(departments: Department[]): Department[] {
    if (departments === undefined || departments === null) {
      return [];
    }
    return departments.sort((a, b) => a.name.localeCompare(b.name));
  }

  private sortEmployees(employees: Employee[]): Employee[] {
    if (employees === undefined || employees === null) {
      return [];
    }
    return employees.sort((a, b) => a.lastName.localeCompare(b.lastName));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
