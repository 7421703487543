<div class="container-fluid">
  <ng-container [formGroup]="formGroup">

    <div class="row">
      <div class="col-md-2">
        <mat-form-field appearance="fill" class="full-fill">
          <mat-label>{{"employee.department" | translate}}</mat-label>
          <mat-select #selDepartment (openedChange)="onDepartmentSelectionChange(!$event)"
                      formControlName="departments" multiple>
            <mat-option (click)="toggleAllSelection(selDepartment)"
                        [value]="ALL_VALUE">{{"generic.all-f" | translate}}</mat-option>
            <mat-option *ngFor="let department of departments"
                        [value]="department.name">
              {{department.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <mat-form-field appearance="fill" class="full-fill">
          <mat-label>{{"employee.employee" | translate}}</mat-label>
          <mat-select #selBadgeNumber (openedChange)="onAnySelectionChange(!$event)" formControlName="employeeBadgeNumbers"
                      multiple>
            <mat-option (click)="toggleAllSelection(selBadgeNumber)"
                        [value]="ALL_VALUE">{{"generic.all-m" | translate}}</mat-option>
            <mat-option *ngFor="let employee of employees"
                        [value]="employee.badgeNumber">
              {{employee.fullName}} ({{employee.badgeNumber}})
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-2">
        <mat-form-field appearance="fill" class="full-fill">
          <mat-label>{{"leave.type" | translate}}</mat-label>
          <mat-select #selType (openedChange)="onAnySelectionChange(!$event)" formControlName="types" multiple [disabled]="lockTypeSelection">
            <mat-option (click)="toggleAllSelection(selType)"
                        [value]="ALL_VALUE">{{"generic.all-f" | translate}}</mat-option>
            <mat-option *ngFor="let type of LeaveType | keyvalue" [value]="type.value">
              {{"leave.types." + type.key | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-2">
        <mat-form-field appearance="fill" class="full-fill">
          <mat-label>{{"leave.status" | translate}}</mat-label>
          <mat-select #selStatus (openedChange)="onAnySelectionChange(!$event)" formControlName="statuses"
                      multiple>
            <mat-option (click)="toggleAllSelection(selStatus)"
                        [value]="ALL_VALUE">{{"generic.all-f" | translate}}</mat-option>
            <mat-option *ngFor="let status of LeaveStatus | keyvalue" [value]="status.value">
              {{"leave.statuses." + status.key | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <mat-form-field appearance="fill">
          <mat-label>{{"leave.range" | translate}}</mat-label>
          <mat-date-range-input formGroupName="dateRange" [rangePicker]="picker">
            <input formControlName="startDate"  matStartDate placeholder="Start date">
            <input formControlName="endDate" matEndDate placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
          <mat-date-range-picker #picker (closed)="onAnySelectionChange(true)"></mat-date-range-picker>
          <mat-error
            *ngIf="formGroup.get('dateRange.startDate')?.hasError('matStartDateInvalid')">{{"leave.messages.invalidStartDate" | translate}}</mat-error>
          <mat-error
            *ngIf="formGroup.get('dateInRange.endDate')?.hasError('matEndDateInvalid')">{{"leave.messages.invalidEndDate" | translate}}</mat-error>
        </mat-form-field>

        <mat-checkbox formControlName="includePartial"
                      (change)="onAnySelectionChange(true)">
          {{"leave.includePartial" | translate}}
        </mat-checkbox>
      </div>
    </div>

  </ng-container>
</div>
