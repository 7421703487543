import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  differenceInHours,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfWeek
} from 'date-fns';
import locale from 'date-fns/esm/locale/ro';
import {SessionService} from '../../../services/session.service';
import {SessionData} from '../../../interfaces/session';
import {CalendarEvent, CalendarView, DAYS_OF_WEEK} from 'angular-calendar';
import {concat, Observable, of, Subject, throwError} from 'rxjs';
import {LanguageService} from '../../../services/language.service';
import {Router} from '@angular/router';
import {DatePipe, KeyValue} from '@angular/common';
import {GitsCalendarView} from '../../../interfaces/calendar';
import {catchError, concatAll, switchMap, takeUntil, toArray} from 'rxjs/operators';
import {TimesheetService} from '../../../services/timesheet.service';
import {Timesheet, TimesheetFilter, TimesheetStatus, TimesheetType} from '../../../interfaces/timesheet';
import {LocationType} from '../../../interfaces/location';
import {TranslateService} from '@ngx-translate/core';
import {EmployeeService} from '../../../services/employee.service';
import {DateTimeService} from '../../../services/date-time.service';
import {PageFilter} from '../../../interfaces/page';
import {TimesheetManagerComponent} from '../timesheet-manager/timesheet-manager.component';
import {ServiceConfigurationProperties} from '../../../interfaces/service-configuration';
import {ServiceConfigurationService} from '../../../services/service-configuration.service';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  lightblue: {
    primary: '#80bfff',
    secondary: '#e6f2ff'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  green: {
    primary: '#008000',
    secondary: '#d0f0c0'
  },
  lightgreen: {
    primary: '#80ff80',
    secondary: '#e6ffe6'
  },
  orange: {
    primary: '#ff8c00',
    secondary: '#FED8B1'
  },
  lightorange: {
    primary: '#ffb347',
    secondary: '#ffefd5'
  },
  grey: {
    primary: '#808080',
    secondary: '#dcdcdc'
  }
};

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheets-calendar.component.html',
  styleUrls: ['./timesheets-calendar.component.less']
})
export class TimesheetsCalendarComponent implements OnDestroy, OnInit {

  CalendarView = CalendarView;
  GitsCalendarView = GitsCalendarView;

  readonly unpagedPageFilter = {unpaged: true};
  readonly weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

  private timesheetShowCanceledInCalendar: boolean | null = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.TimesheetShowCanceledInCalendar,false);

  locale: string = this.languageService.getCurrentLocale();
  view: GitsCalendarView = GitsCalendarView.Week;
  calendarView: CalendarView  = CalendarView.Week;
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  events$: Observable<CalendarEvent<never[] | {timesheet: Timesheet}>[]> = new Observable<CalendarEvent<{timesheet: Timesheet}>[]>();
  activeDayIsOpen = true;
  destroy$ = new Subject<void>();
  filter: Partial<TimesheetFilter> = {};

  calendar: Date[] = [];
  today: Date = new Date();
  thisMonth = '';
  // @ts-ignore
  readonly weekdays = [...Array(7).keys()].map(i => locale.localize.day(i, { width: 'abbreviated' }));
  sessionData = this.sessionService.getData() as SessionData;
  @ViewChild('timesheetManager', { static: false }) timesheetManager!: TimesheetManagerComponent;

  // order events in the day view list
  orderEvents = (a: KeyValue<number, CalendarEvent<Timesheet>>, b: KeyValue<number, CalendarEvent<Timesheet>>): number => {
    return a.value.start.getTime() > b.value.start.getTime() ? -1 : 1;
  }

  constructor(private datePipe: DatePipe,
              private dateTimeService: DateTimeService,
              private employeeService: EmployeeService,
              private languageService: LanguageService,
              private router: Router,
              private serviceConfigurationService: ServiceConfigurationService,
              private sessionService: SessionService,
              private timesheetService: TimesheetService,
              private translateService: TranslateService) { }

  ngOnInit(): void {
    this.calendar = eachDayOfInterval( {start: startOfMonth(this.today), end: endOfMonth(this.today)});
    this.thisMonth = locale.localize?.month(this.today.getMonth());
    this.subscribeForServiceConfigurations();
    this.loadTimesheets(this.view);
    this.subscribeForLanguageChanges();
  }

  loadTimesheets(period: GitsCalendarView, page?: Partial<PageFilter>): void {
    const [startTimesheetFilter, endTimesheetFilter] = this.computeFilters(this.viewDate, period);
    const startQuery = this.timesheetService.getTimesheets(startTimesheetFilter, this.unpagedPageFilter);
    const endQuery = endTimesheetFilter === undefined ? of() : this.timesheetService.getTimesheets(endTimesheetFilter, this.unpagedPageFilter);

    this.events$ = concat(startQuery, endQuery)
      .pipe(
        switchMap(timesheetsPage  => {
          if (timesheetsPage.numberOfElements === 0) {
            return of([]);
          } else {
            const timesheetsEvent = period === GitsCalendarView.Custom ?
              timesheetsPage.content :
              timesheetsPage.content.map((timesheet: Timesheet) => this.computeEventFromTimesheet(timesheet));
            return of(timesheetsEvent);
          }}
        ),
        concatAll(),
        toArray(),
        catchError(error => {
            return throwError(this.translateService.instant('timesheet.messages.errLoading', {error}));
          }
        ),
        takeUntil(this.destroy$)
      );
  }

  addTimesheet(eventDate?: Date): void {
    let navigationParams = {};
    if (eventDate) {
      navigationParams = {
        queryParams:
          {dateTimeIn: this.datePipe.transform(eventDate, 'yyyy-MM-dd')}
      };
    }
    this.router.navigate(['/timesheets/timesheet'], navigationParams);
  }

  calculateHoursDuration(timesheet: Timesheet): number | null {
    if (timesheet.dateTimeOut === null || timesheet.dateTimeIn === null) {
      return null;
    }
    return differenceInHours(timesheet.dateTimeOut as Date, timesheet.dateTimeIn as Date);
  }

  dayClicked({date, events}: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  editTimesheet(id: number): void {
    this.router.navigate(['/timesheets/timesheet/' + id]);
  }

  handleEvent(action: string, eventOrDate: CalendarEvent | Date): void {
    if (action === 'Clicked') {
      if (eventOrDate == null) {
        return;
      }
      if (eventOrDate instanceof Date) {
        this.addTimesheet(eventOrDate);
      } else {
        this.editTimesheet(eventOrDate.id as number);
      }
    }
  }

  /*
  Converts extended view type to CalendarView, for angular-calendar
   */
  onViewChange(newView: GitsCalendarView): void {
    if (newView === GitsCalendarView.Custom) {
      return;
    }
    if (Object.values<string>(CalendarView).includes(newView)) {
      this.calendarView = newView as unknown as CalendarView;
    }
    this.loadTimesheets(newView);
  }

  onViewDateChange(newDate: Date): void {
    this.loadTimesheets(this.calendarView as unknown as GitsCalendarView);
    this.activeDayIsOpen = false;
  }

  private computeEventFromTimesheet(timesheet: Timesheet): CalendarEvent<{ timesheet: Timesheet }> {
    const duration = this.calculateHoursDuration(timesheet);
    const calendarEvent: CalendarEvent = {
      id: timesheet.id,
      start: new Date(timesheet.dateTimeIn as string),
      title: this.translateService.instant('timesheet.types.' + timesheet.type) + ' ' +
        this.translateService.instant('location.types.' + timesheet.location) +
        ': ' + timesheet.employeeFullName + ' (' + String(timesheet.employeeBadgeNumber) + ') ' +
        ' [' + this.dateTimeService.formatDate(timesheet.dateTimeIn, 'HH:mm') + ' - ' +
        this.dateTimeService.formatDate(timesheet.dateTimeOut, 'HH:mm') + '] ' +
        (duration === null ? '' : duration + 'h') +
        ' - ' + this.translateService.instant('timesheet.statuses.' + timesheet.status),
      allDay: true,
      color: this.computeTimesheetColor(timesheet),
      draggable: false,
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
      meta: {timesheet}
    };

    if (timesheet.dateTimeOut !== null) {
      calendarEvent.end = new Date(timesheet.dateTimeOut as string);
    }

    return calendarEvent;
  }

  private computeFilters(referenceDate: Date, period: GitsCalendarView): [Partial<TimesheetFilter>, Partial<TimesheetFilter> | undefined] {
    let startTimesheetFilter: Partial<TimesheetFilter> = {};
    let endTimesheetFilter: Partial<TimesheetFilter> | undefined = {};
    if (period === GitsCalendarView.Month) {
      startTimesheetFilter = {
        dateTimeInLessThanOrEqualTo: this.dateTimeService.formatDate(endOfMonth(referenceDate), 'yyyy-MM-ddT23:59:59'),
        dateTimeOutGreaterThanOrEqualTo: this.dateTimeService.formatDate(startOfMonth(referenceDate), 'yyyy-MM-ddT00:00:00')
      };
      endTimesheetFilter = {
        dateTimeInLessThanOrEqualTo: this.dateTimeService.formatDate(endOfMonth(referenceDate), 'yyyy-MM-ddT23:59:59'),
        dateTimeInGreaterThanOrEqualTo: this.dateTimeService.formatDate(startOfMonth(referenceDate), 'yyyy-MM-ddT00:00:00'),
        dateTimeOutIsNull: true
      };
    }
    if (period === GitsCalendarView.Week) {
      startTimesheetFilter = {
        dateTimeInLessThanOrEqualTo: this.dateTimeService.formatDate(endOfWeek(referenceDate, {weekStartsOn: 1}), 'yyyy-MM-ddT23:59:59'),
        dateTimeOutGreaterThanOrEqualTo: this.dateTimeService.formatDate(startOfWeek(referenceDate, {weekStartsOn: 1}), 'yyyy-MM-ddT00:00:00')
      };
      endTimesheetFilter = {
        dateTimeInLessThanOrEqualTo: this.dateTimeService.formatDate(endOfWeek(referenceDate, {weekStartsOn: 1}), 'yyyy-MM-ddT23:59:59'),
        dateTimeInGreaterThanOrEqualTo: this.dateTimeService.formatDate(startOfWeek(referenceDate, {weekStartsOn: 1}), 'yyyy-MM-ddT00:00:00'),
        dateTimeOutIsNull: true
      };
    }
    if (period === GitsCalendarView.Day) {
      startTimesheetFilter = {
        dateTimeInLessThanOrEqualTo: this.dateTimeService.formatDate(referenceDate, 'yyyy-MM-ddT23:59:59'),
        dateTimeOutGreaterThanOrEqualTo: this.dateTimeService.formatDate(referenceDate, 'yyyy-MM-ddT00:00:00')
      };
      endTimesheetFilter = {
        dateTimeInLessThanOrEqualTo: this.dateTimeService.formatDate(referenceDate, 'yyyy-MM-ddT23:59:59'),
        dateTimeInGreaterThanOrEqualTo: this.dateTimeService.formatDate(referenceDate, 'yyyy-MM-ddT00:00:00'),
        dateTimeOutIsNull: true
      };
    }

    if (!this.timesheetShowCanceledInCalendar) {
      startTimesheetFilter.statusNotIn = [TimesheetStatus.Canceled];
      endTimesheetFilter.statusNotIn = [TimesheetStatus.Canceled];
    }

    return [startTimesheetFilter, endTimesheetFilter];
  }

  private computeTimesheetColor(timesheet: Timesheet): any {
    if (timesheet.dateTimeOut === undefined || timesheet.dateTimeOut === null) {
      return colors.yellow;
    }

    switch (timesheet.status) {
      case TimesheetStatus.Finalized:
        return colors.grey;
      case TimesheetStatus.Canceled:
        return colors.red;
    }

    switch (timesheet.type) {
      case TimesheetType.Work:
        switch (timesheet.location) {
          case LocationType.Office:
            return colors.green;
          case LocationType.Remote:
            return colors.blue;
          case LocationType.BusinessLeave:
            return colors.orange;
        }
      case TimesheetType.Project:
        switch (timesheet.location) {
          case LocationType.Office:
            return colors.lightgreen;
          case LocationType.Remote:
            return colors.lightblue;
          case LocationType.BusinessLeave:
            return colors.lightorange;
        }
      default:
        return colors.grey;
    }
  }

  private computeTimesheetLocationColor(locationType: LocationType) {
    if (locationType === LocationType.Office) {
      return colors.green
    }
  }

  private subscribeForLanguageChanges(): void {
    this.languageService.getCurrentLocaleObservable()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(localeId => {
        this.locale = localeId;
        // TODO: re-compute events instead or re-load?
        this.loadTimesheets(this.view);
      });
  }

  private subscribeForServiceConfigurations(): void {
    this.sessionService.getServiceConfigurationsObservable()
      .subscribe(
        configurations => {
          this.timesheetShowCanceledInCalendar = ServiceConfigurationService.getBooleanPropertyValue(
            ServiceConfigurationProperties.TimesheetShowCanceledInCalendar, this.timesheetShowCanceledInCalendar as boolean,
            configurations);
        }
      )
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
