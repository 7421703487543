import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';

import {environment} from '../../environments/environment';
import {SessionService} from '../services/session.service';
import {SessionData} from '../interfaces/session';

@Injectable({
  providedIn: 'root'
})
export class BasicAuthInterceptor implements HttpInterceptor {

  constructor(private sessionService: SessionService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add header with basic auth credentials if user is logged in and request is to the api url
    const sessionData: SessionData = this.sessionService.getData();
    const isLoggedIn = sessionData.loggedIn && sessionData.currentUser.id;
    const isApiUrl = request.url.startsWith(environment.backendServiceUrl);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Basic ' + sessionData.authenticationToken
        }
      });
    }

    return next.handle(request);
  }
}
