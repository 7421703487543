import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Leave, LeaveFilter, LeaveStatus, LeaveType} from '../interfaces/leave';
import {LeaveSummary} from '../interfaces/leave-summary';
import {EntityVersion} from '../interfaces/entity-version';
import {TranslateService} from '@ngx-translate/core';
import {ErrorService} from './error.service';
import {PaidLeaveReportFilter} from '../interfaces/paid-leave-report';
import {Page, PageFilter, UnpagedFilter} from '../interfaces/page';

@Injectable({
  providedIn: 'root'
})
export class LeaveService {

  // required for the handleError callback method
  private static translateService: TranslateService;

  constructor(private http: HttpClient,
              private translateService: TranslateService) {
    LeaveService.translateService = this.translateService;
  }

  static leaveApi: string = environment.backendServiceUrl + '/' + environment.backendApiContext + '/' + 'leaves';
  static leaveSummaryApi: string = environment.backendServiceUrl + '/' + environment.backendApiContext + '/' + 'leaves/summary';
  static leaveJournalApiName = 'journal';
  static paidLeaveReportApi: string = environment.backendServiceUrl + '/' + environment.backendApiContext + '/' + 'leaves/paidLeaveReport';
  static leaveRequestsReport: string = environment.backendServiceUrl + '/' + environment.backendApiContext + '/' + 'leaves/leaveRequestsReport';

  approveLeave(leaveId: number,
               status: LeaveStatus.SubstituteApproved | LeaveStatus.ManagerApproved | LeaveStatus.HumanResourcesApproved,
               details?: string): Observable<Leave> {
    const patchedLeave: any = {
      id: leaveId,
      status
    };
    if (details) {
      patchedLeave.details = details;
    }
    return this.patchLeave(patchedLeave);
  }

  cancelLeave(leaveId: number): Observable<Leave> {
    return this.patchLeave({id: leaveId, status: LeaveStatus.Canceled});
  }

  createLeave(leave: Leave): Observable<Leave> {
    return this.http.post<Leave>(LeaveService.leaveApi, leave)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  deleteLeave(id: number): Observable<Response> {
    return this.http.delete<Response>(LeaveService.leaveApi + '/' + id)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  getLeave(id: number): Observable<Leave> {
    return this.http.get<Leave>(LeaveService.leaveApi + '/' + id)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  getLeaveJournal(leaveId: number, numberOfVersions?: number): Observable<EntityVersion[]> {
    let params = new HttpParams();
    if (numberOfVersions) {
      params = params.append('numberOfVersions', String(numberOfVersions));
    }
    return this.http.get<EntityVersion[]>(LeaveService.leaveApi + '/' + leaveId + '/' + LeaveService.leaveJournalApiName, {params})
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  getLeaveRequestsReport(leaveFilter?: Partial<LeaveFilter>, pageFilter?: Partial<PageFilter>): Observable<Page> {
    let params = new HttpParams();
    if (leaveFilter !== undefined) {
      Object.entries(leaveFilter).forEach(([key, value]) =>
        params = params.append(key, value as string));
    }
    if (pageFilter === undefined) {
      pageFilter = UnpagedFilter
    }
    Object.entries(pageFilter).forEach(([key, value]) =>
      params = params.append(key, value));
    return this.http.get<Page>(LeaveService.leaveRequestsReport + '/', {params})
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  getLeaves(leaveFilter?: Partial<LeaveFilter>, pageFilter?: Partial<PageFilter>): Observable<Page> {
    let params = new HttpParams();
    if (leaveFilter !== undefined) {
      Object.entries(leaveFilter)
        .forEach(([key, value]) =>
          params = params.append(key, value as string)
        );
    }
    if (pageFilter === undefined) {
      pageFilter = UnpagedFilter
    }
    Object.entries(pageFilter)
    .forEach(([key, value]) =>
      params = params.append(key, value)
    );

    return this.http.get<Page>(LeaveService.leaveApi + '/', {params})
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  getLeaveSummary(badgeNumber: number): Observable<LeaveSummary[]> {
    return this.http.get<LeaveSummary[]>(LeaveService.leaveSummaryApi + '/' + badgeNumber)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  getPaidLeaveReport(paidLeaveReportFilter?: Partial<PaidLeaveReportFilter>, pageFilter?: PageFilter): Observable<Page> {
    let params = new HttpParams();
    if (paidLeaveReportFilter !== undefined) {
      Object.entries(paidLeaveReportFilter).forEach(([key, value]) =>
        params = params.append(key, value as string));
    }
    if (pageFilter !== undefined) {
      Object.entries(pageFilter).forEach(([key, value]) =>
        params = params.append(key, value as string));
    }
    return this.http.get<Page>(LeaveService.paidLeaveReportApi + '/', {params})
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  isLeaveAvailableDaysExceeded(leaveSummary: LeaveSummary | undefined, additionalNumberOfDays: number = 0): boolean {
    if (leaveSummary) {
      const usedNumberOfDays = leaveSummary.used + additionalNumberOfDays;
      if (leaveSummary.type === LeaveType.Paid &&
        usedNumberOfDays > leaveSummary.allowed) {
        return true;
      }
    }

    return false;
  }

  patchLeave(leave: Partial<Leave>): Observable<Leave> {
    if (leave == null || leave.id === undefined) {
      throwError('invalid input');
    }
    return this.http.patch<Leave>(LeaveService.leaveApi, leave)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  rejectLeave(leaveId: number,
              status: LeaveStatus.SubstituteRejected | LeaveStatus.ManagerRejected | LeaveStatus.HumanResourcesRejected,
              details?: string): Observable<Leave> {
    const patchedLeave: any = {
      id: leaveId,
      status
    };
    if (details) {
      patchedLeave.details = details;
    }
    return this.patchLeave(patchedLeave);
  }

  requestLeave(leaveId: number): Observable<Leave> {
    return this.patchLeave({id: leaveId, status: LeaveStatus.Planned});
  }

  updateLeave(leave: Leave): Observable<Leave> {
    return this.http.put<Leave>(LeaveService.leaveApi, leave)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }
}
