import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ErrorService} from './error.service';
import {DepartmentManager, DepartmentManagerFilter} from '../interfaces/department-manager';

@Injectable({
  providedIn: 'root'
})
export class DepartmentManagerService {

  constructor(private http: HttpClient) {
  }

  static departmentManagerApi: string = environment.backendServiceUrl + '/' + environment.backendApiContext + '/' + 'departments/managers';

  get(id: number): Observable<DepartmentManager> {
    return this.http.get<DepartmentManager>(DepartmentManagerService.departmentManagerApi + '/' + id)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  filter(departmentManagerFilter?: Partial<DepartmentManagerFilter>): Observable<DepartmentManager[]> {
    let params = new HttpParams();
    if (departmentManagerFilter !== undefined) {
      Object.entries(departmentManagerFilter).forEach(([key, value]) => params = params.append(key, value as string));
    }
    return this.http.get<DepartmentManager[]>(DepartmentManagerService.departmentManagerApi + '/', {params})
      .pipe(
        catchError(ErrorService.handleError)
      );
  }
}
