import {ColumnsTranslationsConfig, IColumnDef} from '@gits/ngx-tabulator-tables';
import {DebugConfigModel} from '@gits/ngx-tabulator-tables/lib/models/debug-config.model';
import {LeaveManagerComponent} from './leave-manager.component';

export const GRID_LEAVE_REQUEST_REPORT_OPTIONS: any = {
  renderHorizontal: 'basic',
  layout: "fitData",
  movableRows: false,
  movableColumns: true,
  selectable: false,
  height: 400,
  // sortMode: 'remote', // todo wait for backend implementation

  // by default, Tabulator will wait 300 milliseconds after a keystroke before triggering the filter.
  // you can customise this delay by using the [headerFilterLiveFilterDelay] setting
  headerFilterLiveFilterDelay: 500,
  bufferingTimeForTableEvents: 450, // accepted interval >= 150 <= 500 (below or above the default value of 500ms will be used)
  columnDefaults: {
    minWidth: 90
  }
};

export const GRID_LEAVE_REQUEST_REPORT_DEBUG_OPTIONS: DebugConfigModel = {
  pauseTableRenderingOnInit: false,
  showDebugPanel: false,
  debugRxjsAssets: false,
  debugTableHeaderFilters: false,
  debugTableEventsBuffering: false
};

export const GRID_LEAVE_REQUEST_REPORT_LABELS: ColumnsTranslationsConfig = {
  languages: ['en-GB', 'ro-RO'],
  columnsWithLabels: {
    __rowActions: 'generic.tasks', // special ACTIONS column
    __dataTree: 'NO_LABEL/NOT_IN_USE', // special DATA_TREE column
    leaveId: 'reports.leaveRequestsReport.grid.columnLabels.LEAVE_ID',
    type: 'reports.leaveRequestsReport.grid.columnLabels.TYPE',
    employeeBadgeNumber: 'reports.leaveRequestsReport.grid.columnLabels.EMPLOYEE_BADGE_NUMBER',
    employeeLastName: 'reports.leaveRequestsReport.grid.columnLabels.EMPLOYEE_LAST_NAME',
    employeeFirstName: 'reports.leaveRequestsReport.grid.columnLabels.EMPLOYEE_FIRST_NAME',
    startDate: 'reports.leaveRequestsReport.grid.columnLabels.START_DATE',
    endDate: 'reports.leaveRequestsReport.grid.columnLabels.END_DATE',
    totalDays: 'reports.leaveRequestsReport.grid.columnLabels.TOTAL_DAYS',
    status: 'reports.leaveRequestsReport.grid.columnLabels.STATUS',
    details: 'reports.leaveRequestsReport.grid.columnLabels.DETAILS',
    substituteFullName: 'reports.leaveRequestsReport.grid.columnLabels.SUBSTITUTE_FULL_NAME',
    approverFullName: 'reports.leaveRequestsReport.grid.columnLabels.APPROVER_FULL_NAME',
    lastModifierFullName: 'reports.leaveRequestsReport.grid.columnLabels.LAST_MODIFIER_FULL_NAME',
    lastModifiedDate: 'reports.leaveRequestsReport.grid.columnLabels.MODIFICATION_DATE'
  }
};

export function getGridLeaveRequestColumnDefs(scope: LeaveManagerComponent): IColumnDef[] {
  return [
    {
      title: 'Leave ID',
      field: 'leaveId',
      backendFilterName: '',
      minWidth: 60
    },
    {
      title: 'Leave type',
      field: 'type',
      backendFilterName: '',
      formatter: scope.gridTypeColumnFormatter
    },
    {
      title: 'Employee Badge No',
      field: 'employeeBadgeNumber',
      backendFilterName: '',
      minWidth: 60
    },
    {
      title: 'Employee Last Name',
      field: 'employeeLastName',
      backendFilterName: ''
    },
    {
      title: 'Employee First Name',
      field: 'employeeFirstName',
      backendFilterName: ''
    },
    {
      title: 'Start Date',
      field: 'startDate',
      backendFilterName: '',
      formatter: scope.gridDateFormatter,
      formatterParams: {
        useTimeFrom: 'startTime'
      },
      bottomCalc: scope.minDateCalculation,
      bottomCalcFormatter: scope.gridDateFormatter
    },
    {
      title: 'End Date',
      field: 'endDate',
      backendFilterName: '',
      formatter: scope.gridDateFormatter,
      formatterParams: {
        useTimeFrom: 'endTime'
      },
      bottomCalc: scope.maxDateCalculation,
      bottomCalcFormatter: scope.gridDateFormatter
    },
    {
      title: 'Total Days/Hours',
      field: 'totalDays',
      backendFilterName: '',
      bottomCalc: "sum"
    },
    {
      title: 'Status',
      field: 'status',
      backendFilterName: '',
      formatter: scope.gridStatusFormatter
    },
    {
      title: 'Details',
      field: 'details',
      backendFilterName: ''
    },
    {
      title: 'Substitute Full Name',
      field: 'substituteFullName',
      backendFilterName: ''
    },
    {
      title: 'Manager Name',
      field: 'approverFullName',
      backendFilterName: ''
    },
    {
      title: 'User Last Modified',
      field: 'lastModifierFullName',
      backendFilterName: ''
    },
    {
      title: 'Last Modified Date',
      field: 'lastModifiedDate',
      backendFilterName: '',
      formatter: scope.gridDateFormatter,
      formatterParams: {
        useTimeFrom: null
      }
    }
  ];
}
