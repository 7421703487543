import { Component, OnInit } from '@angular/core';
import { FeedType } from 'src/app/interfaces/feed';
import {ServiceConfigurationService} from '../../../services/service-configuration.service';
import {ServiceConfigurationProperties} from '../../../interfaces/service-configuration';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent {

  dailyEventsEnabled: boolean | null = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.DailyEventsEnabled, true);
  readonly FeedType = FeedType;
}
