import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ErrorService} from './error.service';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {ReportFileType, ReportFilter} from "../interfaces/report";
import {Page, PageFilter} from "../interfaces/page";

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  static apiUrl: string = environment.backendServiceUrl + '/' + environment.backendApiContext + '/' + 'reports';
  static aggregatedSummaryApi: string = environment.backendServiceUrl + '/' + environment.backendApiContext + '/' + 'reports/aggregated-summary';

  constructor(private http: HttpClient, ) { }

  get(name: string, params: Map<string, object>, fileType: ReportFileType = ReportFileType.Pdf): Observable<HttpResponse<Blob>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('name', name);
    httpParams = httpParams.append('type', fileType);
    if (params !== undefined) {
      for (const [key, value] of params) {
        httpParams = httpParams.append(key, JSON.stringify(value));
      }
    }

    return this.http.get(ReportService.apiUrl + '/', {
      params: httpParams,
      responseType: 'blob',
      observe: 'response'})
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  getAggregatedSummary(reportFilter?: Partial<ReportFilter>, pageFilter?: Partial<PageFilter>): Observable<Page> {
    let params = new HttpParams();
    if (reportFilter !== undefined) {
      Object.entries(reportFilter).forEach(([key, value]) =>
        params = params.append(key, value as string));
    }
    if (pageFilter !== undefined) {
      Object.entries(pageFilter).forEach(([key, value]) =>
        params = params.append(key, value));
    }
    return this.http.get<Page>(ReportService.aggregatedSummaryApi + '/', {params})
      .pipe(
        catchError(ErrorService.handleError)
      );
  }
}
