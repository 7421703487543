export interface Employee {
  badgeNumber: number;
  firstName: string;
  lastName: string;
  fullName: string;
  departmentName: string;
  workstation: string;
  position: string;
  identificationCardNumber: string;
  identificationCardIssuer: string;
  identificationCardIssueDate: string;
}

export interface EmployeeFilter {
  active: boolean;
  birthDateAnniversary: string;
  departmentNameExact: string;
  departmentNameList: string[];
  recurseDepartments: boolean;
  emailAddress: string;
  employmentDateAnniversary: string;
  firstName: string;
  fullName: string;
  lastName: string;
  employeeBadgeNumberList: number[];
}

export enum EmployeeSelectionType {
  All = 'All',
  Department = 'Department',
  ManagedDepartments = 'ManagedDepartments',
  Self = 'Self'
}
