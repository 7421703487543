import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable, OnInit} from '@angular/core';
import {AuthenticationService} from './authentication.service';
import {ServiceConfigurationService} from './service-configuration.service';
import {ServiceConfigurationProperties} from '../interfaces/service-configuration';
import {SessionService} from './session.service';
import {ErrorService} from './error.service';
import {UserRole} from '../interfaces/user';

@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild {

  businessLeaveEnabled: boolean | null = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.BusinessLeaveEnabled, false);
  leaveEnabled: boolean | null = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.LeaveEnabled, true);
  timesheetEnabled: boolean | null = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.TimesheetEnabled, true);

  constructor(private authService: AuthenticationService,
              private errorService: ErrorService,
              private router: Router,
              private sessionService: SessionService) {
    this.subscribeForServiceConfigurations();
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const isAuthenticated = this.authService.isAuthenticated();
    if (!isAuthenticated) {
      return this.router.navigate(['/login'], {queryParams: {redirectURL: state.url}});
    }

    if (route.url.length > 0) {
      if (!this.leaveEnabled && route.url[0].path === 'leaves') {
        this.errorService.showError('leave.messages.moduleDisabled');
        return this.router.navigate(['/home']);
      }
      if (!this.timesheetEnabled && route.url[0].path === 'timesheets') {
        this.errorService.showError('timesheet.messages.moduleDisabled');
        return this.router.navigate(['/home']);;
      }
      if (!this.businessLeaveEnabled && route.url[0].path === 'businessLeaves') {
        this.errorService.showError('businessLeave.messages.moduleDisabled');
        return this.router.navigate(['/home']);;
      }
    }

    if (route.url[0].path === 'application-configuration' &&
      !this.authService.currentUserHasRole(UserRole.humanResourceManager)) {
      this.errorService.showError('settings.messages.appConfigUnauthorized');
      return this.router.navigate(['/home']);
    }

    return isAuthenticated;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }

  private subscribeForServiceConfigurations(): void {
    this.sessionService.getServiceConfigurationsObservable()
      .subscribe(
        configurations => {
          this.businessLeaveEnabled = ServiceConfigurationService.getBooleanPropertyValue(
            ServiceConfigurationProperties.BusinessLeaveEnabled, this.businessLeaveEnabled as boolean, configurations);
          this.leaveEnabled = ServiceConfigurationService.getBooleanPropertyValue(
            ServiceConfigurationProperties.LeaveEnabled, this.leaveEnabled as boolean, configurations);
          this.timesheetEnabled = ServiceConfigurationService.getBooleanPropertyValue(
            ServiceConfigurationProperties.TimesheetEnabled, this.timesheetEnabled as boolean, configurations);
        }
      )
  }
}
