import {EventEmitter, Injectable} from '@angular/core';
import {SessionData} from '../interfaces/session';
import {BehaviorSubject, Observable} from 'rxjs';
import {ServiceConfiguration} from "../interfaces/service-configuration";

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private sessionData = new BehaviorSubject({loggedIn: false} as SessionData);
  private serviceConfigurations: EventEmitter<ServiceConfiguration[]> = new EventEmitter();

  constructor() {
    const currentSessionData = localStorage.getItem('sessionData');
    if (currentSessionData) {
      const sessionData: SessionData = JSON.parse(currentSessionData);
      this.sessionData.next(sessionData);
      if (sessionData.loggedIn !== undefined) {
        this.isUserLoggedIn.next(sessionData.loggedIn);
      }
    }
  }

  setData(sessionData: Partial<SessionData>): void {
    const currentSessionData = this.sessionData.getValue();
    const currentLoginState = currentSessionData.loggedIn;
    this.sessionData.next(sessionData as SessionData);
    localStorage.setItem('sessionData', JSON.stringify(this.sessionData.getValue()));
    if (sessionData.loggedIn !== undefined && currentLoginState !== sessionData.loggedIn) {
      this.isUserLoggedIn.next(sessionData.loggedIn);
    }
    if (sessionData.serviceConfiguration !== undefined) {
      this.serviceConfigurations.emit(sessionData.serviceConfiguration);
    }
  }

  updateData(sessionData: Partial<SessionData>): void {
    const currentSessionData = this.sessionData.getValue();
    const mergedSessionData = Object.assign(currentSessionData, sessionData);
    this.setData(mergedSessionData);
  }

  getData(): SessionData {
    return this.sessionData.getValue();
  }

  getDataObservable(): Observable<SessionData> {
    return this.sessionData.asObservable();
  }

  getServiceConfigurationsObservable(): Observable<ServiceConfiguration[]> {
    return this.serviceConfigurations.asObservable();
  }

  removeData(): void {
    this.setData({loggedIn: false});
  }

}
