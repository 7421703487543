<div class="container-fluid">
  <form [formGroup]="formGroup" (ngSubmit)="saveTimesheet()">
    <div class="row">
      <div class="col-md-12">
        <h1>{{"timesheet.reportTimesheet" | translate}}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field appearance="fill" class="full-fill">
          <mat-label>{{"employee.employee" | translate}}</mat-label>
          <mat-select formControlName="employeeBadgeNumber" (openedChange)="onEmployeeChanged($event)">
            <mat-option *ngFor="let employee of employees" [value]="employee.badgeNumber">
              {{employee.lastName + ' ' + employee.firstName + ' (' + employee.badgeNumber + ')'}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-chip-list formControlName="status" aria-label="status">
          <mat-label>{{"generic.status" | translate}}: </mat-label>
          <mat-chip *ngIf="formGroup.controls.status.value === TimesheetStatus.Open" color="primary" [value]="formGroup.controls.status.value">{{"timesheet.statuses.Open" | translate}}</mat-chip>
          <mat-chip *ngIf="formGroup.controls.status.value === TimesheetStatus.Finalized" color="success" [value]="formGroup.controls.status.value">{{"timesheet.statuses.Finalized" | translate}}</mat-chip>
          <mat-chip *ngIf="formGroup.controls.status.value === TimesheetStatus.Canceled" color="warn" [value]="formGroup.controls.status.value">{{"timesheet.statuses.Canceled" | translate}}</mat-chip>
        </mat-chip-list>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <section>
          <mat-label>{{"location.location" | translate}} : </mat-label>
          <mat-radio-group formControlName="location" [attr.aria-label]="'location.location' | translate" (change)="onTypeChange($event)">
            <mat-radio-button value="{{LocationType.Office}}" class="margin-left-right">{{"location.types.Office" | translate}}</mat-radio-button>
            <mat-radio-button value="{{LocationType.Remote}}" class="margin-left-right">{{"location.types.Remote" | translate}}</mat-radio-button>
            <mat-radio-button value="{{LocationType.BusinessLeave}}" class="margin-left-right">{{"location.types.BusinessLeave" | translate}}</mat-radio-button>
          </mat-radio-group>
        </section>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-checkbox formControlName="checkin" (change)="onCheckinChanged($event.checked)">{{"timesheet.checkin" | translate}}</mat-checkbox>
        <mat-form-field appearance="fill" class="full-fill">
          <mat-label>{{"timesheet.checkinDate" | translate}}</mat-label>
          <input matInput formControlName="dateTimeIn" [matDatepicker]="dateTimeIn" (dateChange)="onCheckinDateChanged($event.value)"
                 (keydown)="$event.preventDefault();">
          <mat-datepicker-toggle matSuffix [for]="dateTimeIn"></mat-datepicker-toggle>
          <mat-datepicker #dateTimeIn></mat-datepicker>
          <mat-error *ngIf="formGroup.controls.dateTimeIn.errors?.['leaveTypeOther']">
            {{"timesheet.messages.employeeOnLeave" | translate}}
          </mat-error>
          <mat-error *ngIf="formGroup.controls.dateTimeIn.errors?.['leaveTypeBusinessLeave']">
            {{"timesheet.messages.employeeOnBusinessLeave" | translate}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-checkbox formControlName="checkout" (change)="onCheckoutChanged($event.checked)">{{"timesheet.checkout" | translate}}</mat-checkbox>
        <mat-form-field appearance="fill" class="full-fill">
          <mat-label>{{"timesheet.checkoutDate" | translate}}</mat-label>
          <input matInput formControlName="dateTimeOut" [matDatepicker]="dateTimeOut" (dateChange)="onCheckoutDateChanged($event.value)"
                 (keydown)="$event.preventDefault();">
          <mat-datepicker-toggle matSuffix [for]="dateTimeOut"></mat-datepicker-toggle>
          <mat-datepicker #dateTimeOut></mat-datepicker>
          <mat-error *ngIf="formGroup.controls.dateTimeOut.invalid">
            {{"timesheet.messages.employeeOnLeave" | translate}}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="fill" class="full-fill">
          <mat-label>{{"timesheet.checkinTime" | translate}}</mat-label>
          <input type="time" matInput formControlName="hourIn" placeholder="08:00" (change)="onHourInChanged($event.target)">
          <mat-error *ngIf="formGroup.controls.hourIn.errors">{{"timesheet.messages.timeFormat" | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="fill" class="full-fill">
          <mat-label>{{"timesheet.checkoutTime" | translate}}</mat-label>
          <input type="time" matInput formControlName="hourOut" placeholder="17:00" (change)="onHourOutChanged($event.target)">
          <mat-error *ngIf="!formGroup.controls.hourOut.errors">{{"timesheet.messages.timeFormat" | translate }}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill" class="full-fill">
          <mat-label>{{"generic.description" | translate | titlecase}} ({{"generic.optional" | translate | lowercase}})</mat-label>
          <input matInput formControlName="description" type="text">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button mat-flat-button color="primary" class="m-1" type="submit"
                [disabled]="formGroup.invalid || actionInProgress || formGroup.controls.status.value !== TimesheetStatus.Open">
          {{"timesheet.reportTimesheet" | translate}}
        </button>
        <button mat-stroked-button color="warn" class="m-1" type="button" *ngIf="canCancelTimesheet()" (click)="cancelTimesheet()"
                [disabled]="actionInProgress || formGroup.controls.status.value === TimesheetStatus.Finalized">
          {{"generic.cancel" | translate}}
        </button>
      </div>
    </div>

  </form>
</div>
