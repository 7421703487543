import {ModifyAuditable} from './modify-auditable';

export interface EntityVersion {
  entity: any;
  audit: ModifyAuditable;
  revision: number;
  type: RevisionType;
}

export enum RevisionType {
  ADD = 'ADD',
  MOD = 'MOD',
  DEL = 'DEL'
}
