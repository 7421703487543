import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Employee, EmployeeFilter} from '../interfaces/employee';
import {environment} from '../../environments/environment';
import {ErrorService} from './error.service';
import {TranslateService} from '@ngx-translate/core';
import {PageFilter, UnpagedFilter} from '../interfaces/page';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(private http: HttpClient,
              private translateService: TranslateService) {
  }

  static employeesApi: string = environment.backendServiceUrl + '/' + environment.backendApiContext + '/' + 'employees';

  getEmployee(badgeNumber: number, reportError = true): Observable<Employee> {
    if (reportError) {
      return this.getEmployeeFromAPI(badgeNumber);
    }

    const unknownEmployee = {
      badgeNumber: 0,
      firstName: '',
      lastName: this.translateService.instant('employee.unknownEmployee'),
      fullName: this.translateService.instant('employee.unknownEmployee'),
      departmentName: this.translateService.instant('generic.unknown'),
      workstation: '',
      position: '',
      identificationCardNumber: '',
      identificationCardIssuer: '',
      identificationCardIssueDate: ''
    };
    if (badgeNumber === undefined || isNaN(badgeNumber) || badgeNumber === 0 || badgeNumber === null) {
      return of(unknownEmployee);
    }
    return this.getEmployeeFromAPI(badgeNumber).pipe(
      map(employee => {
        if (employee) {
          return employee;
        } else {
          return unknownEmployee;
        }
      })
    );
  }

  private getEmployeeFromAPI(badgeNumber: number): Observable<Employee> {
    return this.http.get<Employee>(EmployeeService.employeesApi + '/' + badgeNumber)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  getEmployees(employeeFilter?: Partial<EmployeeFilter>, pageFilter?: Partial<PageFilter>): Observable<Employee[]> {
    let params = new HttpParams();
    if (employeeFilter !== undefined) {
      Object.entries(employeeFilter)
        .forEach(([key, value]) =>
          params = params.append(key, value as string)
        );
    }
    if (pageFilter === undefined) {
      pageFilter = UnpagedFilter
    }
    Object.entries(pageFilter)
    .forEach(([key, value]) =>
      params = params.append(key, value)
    );

    return this.http.get<Employee[]>(EmployeeService.employeesApi + '/', {params})
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  getActiveEmployees(employeeFilter?: Partial<EmployeeFilter>, pageFilter?: Partial<PageFilter>): Observable<Employee[]> {
    if (employeeFilter === null || employeeFilter === undefined) {
      employeeFilter = {};
    }
    if (!employeeFilter.hasOwnProperty('active')) {
      employeeFilter.active = true;
    }

    return this.getEmployees(employeeFilter, pageFilter);
  }

  filterEmployeesInList = (partialEmployeeNameOrBadge: string, employeeList: Employee[]): Employee[] => {
    if (partialEmployeeNameOrBadge === null || partialEmployeeNameOrBadge === undefined ||
      typeof partialEmployeeNameOrBadge !== 'string' ||
      employeeList === null || employeeList === undefined) {
      return [];
    }
    const filterValue = partialEmployeeNameOrBadge.toLowerCase();
    return employeeList.filter(
      employee =>
        employee.fullName.toLowerCase().includes(filterValue) ||
        employee.badgeNumber.toString().includes(filterValue)
    );
  }

  formatEmployeeName = (employee: Employee): string  => {
    let formattedEmployeeName = '';
    if (employee === null || employee === undefined) {
      return '';
    }

    if (employee.hasOwnProperty('fullName')) {
      formattedEmployeeName = employee.fullName;
      if (employee.hasOwnProperty('badgeNumber') && employee.badgeNumber > 0) {
        formattedEmployeeName += ' (' + employee.badgeNumber + ')';
      }
    }

    return formattedEmployeeName;
  }
}
