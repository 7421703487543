import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EmployeePhoto } from '../interfaces/employee-photo';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeePhotoService {

  constructor(private http: HttpClient) {
  }

  static employeePhotoApi: string = environment.backendServiceUrl + '/' + environment.backendApiContext + '/' + 'employees/photo';

  getEmployeePhoto(badgeNumber: number): Observable<EmployeePhoto> {
    return this.http.get<EmployeePhoto>(EmployeePhotoService.employeePhotoApi + '/' + badgeNumber)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  createEmployeePhoto(employeePhoto: EmployeePhoto): Observable<EmployeePhoto> {
    return this.http.post<EmployeePhoto>(EmployeePhotoService.employeePhotoApi, employeePhoto)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  deleteEmployeePhoto(id: number): Observable<EmployeePhoto> {
    return this.http.delete<EmployeePhoto>(EmployeePhotoService.employeePhotoApi + '/' + id)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }
}
