import {DatePipe} from '@angular/common';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CustomMatPaginatorIntl} from 'src/app/config/custom-mat-paginator-int';
import {PaidLeaveReport} from 'src/app/interfaces/paid-leave-report';
import {DepartmentService} from 'src/app/services/department.service';
import {EmployeeService} from 'src/app/services/employee.service';
import {LeaveService} from 'src/app/services/leave.service';

@Component({
  selector: 'app-paid-leave-report',
  templateUrl: './paid-leave-report.component.html',
  styleUrls: ['./paid-leave-report.component.less'],
  providers: [{
    provide: MatPaginatorIntl,
    useClass: CustomMatPaginatorIntl
  }]
})
export class PaidLeaveReportComponent implements OnDestroy, OnInit {

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  paidLeaveReport: PaidLeaveReport [] = [];
  totalItems = 0;
  departmentList: any[] = [];
  workstationList: any[] = [];
  positionList: any[] = [];
  yearList: number[] = [];
  monthList: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  yearMax = new Date().getFullYear();
  currentMonth = new Date().getMonth() + 1;
  selectedDepartment = '';
  selectedWorkstation = '';
  selectedPosition = '';
  referenceDate = new Date(this.yearMax, 12, 0);
  filter = {};
  isError = false;
  private destroy$ = new Subject<void>();
  dataSource = new MatTableDataSource<PaidLeaveReport>(this.paidLeaveReport);
  displayedColumns: string[] = ['badgeNumber', 'lastName', 'firstName', 'position', 'pid', 'startDate', 'allowedDays',
    'residualDays', 'totalDays', 'usedDays', 'availableDays', 'allowedDaysOnReferenceDate', 'department', 'endDate'];

  constructor(private leaveService: LeaveService,
              private departmentService: DepartmentService,
              private employeeService: EmployeeService,
              private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.loadPaidLeaveReport();
    this.getDepartments();
    this.getWorkstationsAndPositions();
    this.getYears();
  }

  loadPaidLeaveReport(): void {
    this.leaveService.getPaidLeaveReport(this.filter)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        response => {
          this.totalItems = response.totalElements;
          this.dataSource.data = response.content as PaidLeaveReport[];
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        },
        error => {
          this.isError = true;
        }
      );
  }

  getDepartments(): void {
    const filter = { active: true };
    this.departmentService.filter(filter)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        response => response.forEach(item => { this.departmentList.push({ department: item.name }); })
      );
  }

  getWorkstationsAndPositions(): void {
    this.employeeService.getActiveEmployees()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        response => {
          response.forEach(item => { this.workstationList.push({ workstation: item.workstation }); });
          // remove duplicates
          this.workstationList = this.workstationList.filter((test, index, array) =>
            index === array.findIndex((findTest) =>
              findTest.workstation === test.workstation));
          response.forEach(item => { this.positionList.push({ position: item.position }); });
          // remove duplicates
          this.positionList = this.positionList.filter((test, index, array) =>
            index === array.findIndex((findTest) =>
              findTest.position === test.position));
        });
  }

  getYears(): void {
    for (let year = this.yearMax; year >= this.yearMax - 5; year--) {
      this.yearList.push(year);
    }
  }

  applyFilter(): void{
    this.filter = {year: this.yearMax, month: this.currentMonth, department: this.selectedDepartment,
      workstation: this.selectedWorkstation, position: this.selectedPosition,
      referenceDate: this.datePipe.transform(this.referenceDate, 'yyyy-MM-dd')};
    this.loadPaidLeaveReport();
  }

  yearChange(year: number): void {
    this.yearMax = year;
    this.applyFilter();
  }

  monthChange(month: number): void {
    this.currentMonth = month;
    this.applyFilter();
  }

  departmentChange(department: string): void {
    if (department === 'all') {
      this.selectedDepartment = '';
    } else {
      this.selectedDepartment = Object.values(department)[0];
    }
    this.applyFilter();
  }

  workstationChange(workstation: string): void {
    if (workstation === 'all') {
      this.selectedWorkstation = '';
    } else {
      this.selectedWorkstation = Object.values(workstation)[0];
    }
    this.applyFilter();
  }

  positionChange(position: string): void {
    if (position === 'all') {
      this.selectedPosition = '';
    } else {
      this.selectedPosition = Object.values(position)[0];
    }
    this.applyFilter();
  }

  referenceDateChange(referenceDate: Date): void {
    this.referenceDate = referenceDate;
    this.applyFilter();
  }

  refresh(): void {
    window.location.reload();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
