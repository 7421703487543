import {Component, OnDestroy, OnInit} from '@angular/core';
import {Employee} from '../../interfaces/employee';
import {EmployeeService} from '../../services/employee.service';
import {LeaveService} from '../../services/leave.service';
import {mergeMap, switchMap, takeUntil} from 'rxjs/operators';
import {from, of, Subject} from 'rxjs';
import {Leave, LeaveStatus} from '../../interfaces/leave';
import {DateTimeService} from '../../services/date-time.service';
import {ServiceConfigurationService} from "../../services/service-configuration.service";
import {ServiceConfigurationProperties} from "../../interfaces/service-configuration";
import {Page} from "../../interfaces/page";

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.less']
})

export class EventsListComponent implements OnInit, OnDestroy {

  dailyEventsShowBirthAnniversary: boolean | null = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.DailyEventsShowBirthAnniversary, true);
  destroy$ = new Subject<void>();
  today = this.dateTimeService.formatDate(new Date().toISOString(), 'yyyy-MM-dd');
  employeesBornToday: Employee[] = [];
  employeesEmployedToday: Employee[] = [];
  employeesOnLeaveToday: Employee[] = [];

  constructor(private employeeService: EmployeeService,
              private leaveService: LeaveService,
              private dateTimeService: DateTimeService) {
  }

  ngOnInit(): void {
    this.subscribeToEmployeesBorn();
    this.subscribeToEmployeesEmployed();
    this.subscribeToLeaves();
  }

  subscribeToEmployeesBorn(): void {
    const employeesBornFilter = {
      birthDateAnniversary: this.today,
      active: true
    };
    this.employeeService.getActiveEmployees(employeesBornFilter)
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(
      response => this.employeesBornToday = response
    );
  }

  subscribeToEmployeesEmployed(): void {
    const employeesEmployedFilter = {
      employmentDateAnniversary: this.today
    };
    this.employeeService.getActiveEmployees(employeesEmployedFilter)
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(
      response => this.employeesEmployedToday = response
    );
  }

  subscribeToLeaves(): void {
    const leavesFilter = {
      startDateLessThanOrEqualTo: this.today,
      endDateGreaterThanOrEqualTo: this.today,
      statusGreaterThan: LeaveStatus.ManagerRejected
    };
    this.leaveService.getLeaves(leavesFilter)
      .pipe(
        switchMap((page: Page) => {
          // remove duplicates
          let leaves: Leave[] = [];
          if (page.numberOfElements > 0) {
            leaves = page.content.filter((leave, index) =>
              leaves.findIndex(item =>
                item.employeeBadgeNumber === leave.employeeBadgeNumber) === index);
            return from(leaves)
              .pipe(
                mergeMap(leave => this.employeeService.getEmployee(leave.employeeBadgeNumber))
              );
          } else {
            return of();
          }
        }),
        takeUntil(this.destroy$)
      ).subscribe(
      response => this.employeesOnLeaveToday.push(response)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
