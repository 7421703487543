import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, Validators} from '@angular/forms';
import {SessionService} from '../../../services/session.service';
import {EmployeeService} from '../../../services/employee.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {User} from '../../../interfaces/user';
import {first} from 'rxjs/operators';
import {ErrorService} from '../../../services/error.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  username = new FormControl('', [Validators.required]);
  password = new FormControl('');
  redirectURL: string|null = null;

  constructor(private activatedRoute: ActivatedRoute,
              private authService: AuthenticationService,
              private employeeService: EmployeeService,
              private errorService: ErrorService,
              private router: Router,
              private sessionService: SessionService) {
  }

  ngOnInit(): void {
    this.sessionService.removeData();
  }

  login(): void {
    const params = this.activatedRoute.snapshot.queryParams;
    if (params.redirectURL) {
      this.redirectURL = params.redirectURL;
    }
    this.username.markAsTouched();
    if (this.username.valid) {
      this.authService.authenticateWithRealm(this.username.value, this.password.value)
        .pipe(
          first()
        )
        .subscribe((result: User) => {
            if (result === undefined || result === null) {
              this.errorService.showError('security.messages.errAuthenticationFailure');
            }
            if (this.redirectURL) {
              this.router.navigateByUrl(this.redirectURL)
                .catch(() => this.router.navigate(['/home']));
            } else {

              this.router.navigate(['/home']);
            }
          },
          error => {
            this.errorService.showError(error);
          }
        );
    }
  }

  logout(): void {
    this.authService.logout();
  }
}
