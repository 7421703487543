<div class="container-fluid">
  <form [formGroup]="formGroup" (ngSubmit)="saveLeave()">
    <div class="row">
      <div class="col">
        <mat-horizontal-stepper labelPosition="bottom" [selectedIndex]="getStepperIndexByLeaveStatus()">
          <ng-template matStepperIcon="error">
            <mat-icon>cancel</mat-icon>
          </ng-template>
          <mat-step label="{{'leave.planning' | translate}}" editable="false"
                    completed="{{getStatusIndex() >= getStatusIndex(LeaveStatus.Planned)}}">
          </mat-step>
          <mat-step *ngIf="formGroup.controls.type.value !== LeaveType.Business"
                    label="{{'leave.substituteApproval' | translate}}" editable="false" optional
                    state="{{formGroup.controls.status.value === LeaveStatus.SubstituteApproved ? 'done' :
                    formGroup.controls.status.value === LeaveStatus.SubstituteRejected ? 'error' : 'number'}}"
                    completed="{{getStatusIndex() >= getStatusIndex(LeaveStatus.SubstituteApproved) &&
                                formGroup.controls.status.value !== LeaveStatus.SubstituteRejected &&
                                (formGroup.controls.type.value === LeaveType.Paid || formGroup.controls.type.value === LeaveType.Absence)}}">
          </mat-step>
          <mat-step label="{{'leave.departmentManagerApproval' | translate}}" editable="false"
                    optional="{{formGroup.controls.type.value === LeaveType.Medical && !leaveMedicalManagerApproval}}"
                    state="{{formGroup.controls.status.value === LeaveStatus.ManagerApproved ? 'done' :
                    formGroup.controls.status.value === LeaveStatus.ManagerRejected ? 'error' : 'number'}}"
                    completed="{{getStatusIndex() >= getStatusIndex(LeaveStatus.ManagerApproved) &&
                                formGroup.controls.status.value !== LeaveStatus.ManagerRejected}}">
          </mat-step>
          <mat-step label="{{'leave.humanResourcesApproval' | translate}}" editable="false"
                    state="{{formGroup.controls.status.value === LeaveStatus.HumanResourcesApproved ? 'done' :
                    formGroup.controls.status.value === LeaveStatus.HumanResourcesRejected ? 'error' : 'number'}}"
                    completed="{{getStatusIndex() >= getStatusIndex(LeaveStatus.HumanResourcesApproved) &&
                                formGroup.controls.status.value !== LeaveStatus.HumanResourcesRejected}}">
          </mat-step>
          <mat-step label="{{'leave.finalized' | translate}}" editable="false"
                    state="{{formGroup.controls.status.value === LeaveStatus.Finalized ? 'done' :
                    formGroup.controls.status.value === LeaveStatus.Canceled ? 'error' : 'number'}}"
                    completed="{{getStatusIndex() === getStatusIndex(LeaveStatus.Finalized)}}">
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-label> {{"leave.type" | translate}} :  </mat-label>
        <mat-radio-group formControlName="type" id="type" [attr.aria-label]="'leave.type' | translate"
                         (change)="onLeaveTypeChange($event)">
          <mat-radio-button *ngIf="filter !== LeaveType.Business"
            [value]=LeaveType.Paid>{{"leave.types." + LeaveType.Paid | translate}}
          </mat-radio-button>
          <mat-radio-button *ngIf="filter !== LeaveType.Business"
            [value]=LeaveType.Absence>{{"leave.types." + LeaveType.Absence | translate}}
          </mat-radio-button>
          <mat-radio-button *ngIf="filter !== LeaveType.Business"
            [value]=LeaveType.Bereavement>{{"leave.types." + LeaveType.Bereavement | translate}}
          </mat-radio-button>
          <mat-radio-button *ngIf="filter !== LeaveType.Business"
            [value]=LeaveType.Medical>{{"leave.types." + LeaveType.Medical | translate}}
          </mat-radio-button>
          <mat-radio-button  *ngIf="filter !== LeaveType.Business"
            [value]=LeaveType.Maternity>{{"leave.types." + LeaveType.Maternity | translate}}
          </mat-radio-button>
          <mat-radio-button  *ngIf="filter !== LeaveType.Business"
            [value]=LeaveType.Paternity>{{"leave.types." + LeaveType.Paternity | translate}}
          </mat-radio-button>
          <mat-radio-button *ngIf="filter !== LeaveType.Business"
            [value]=LeaveType.ChildCare>{{"leave.types." + LeaveType.ChildCare | translate}}
          </mat-radio-button>
          <mat-radio-button *ngIf="filter !== LeaveType.Business"
            [value]=LeaveType.Unpaid>{{"leave.types." + LeaveType.Unpaid | translate}}
          </mat-radio-button>
          <mat-radio-button *ngIf="filter === LeaveType.Business"
                            [value]=LeaveType.Business>{{"leave.types." + LeaveType.Business | translate}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2">
        <mat-form-field appearance="fill">
          <mat-label>{{"leave.range" | translate}}</mat-label>
          <mat-date-range-input [rangePicker]="leavePeriodPicker" [min]="minDateRange" [dateFilter]="weekendsAndHolidaysFilter">
            <input matStartDate formControlName="startDate" (dateChange)="onLeavePeriodPickerStartDateChange($event.value)"
                   (keydown)="$event.preventDefault();">
            <input matEndDate formControlName="endDate" (dateChange)="onLeavePeriodPickerEndDateChange($event.value)"
                   (keydown)="$event.preventDefault();">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="leavePeriodPicker" disabled="false"></mat-datepicker-toggle>
          <mat-date-range-picker #leavePeriodPicker disabled="false" [startAt]="minDateRange"></mat-date-range-picker>
          <mat-error *ngIf="formGroup.controls.startDate.invalid">
            {{"leave.messages.invalidStartDate" | translate}}
          </mat-error>
          <mat-error *ngIf="formGroup.controls.endDate.invalid && !formGroup.controls.endDate.hasError('exceeded')">
            {{"leave.messages.invalidEndDate" | translate}}
          </mat-error>
          <mat-error *ngIf="formGroup.controls.type.value === LeaveType.Paid &&
                            formGroup.controls.endDate.hasError('exceeded') &&
                            employeePaidLeaveSummary &&
                            isLeaveAvailableDaysExceeded(employeePaidLeaveSummary)">
            {{"leave.messages.availableQuotaExceeded" | translate: {
            selected: leaveEditorUtils.getNumberOfBusinessDays(formGroup.controls.startDate.value, formGroup.controls.endDate.value),
            available: employeePaidLeaveSummary.allowed - employeePaidLeaveSummary.used
          } }}
          </mat-error>
          <mat-hint *ngIf="formGroup.controls.type.value === LeaveType.Paid &&
                            leaveAllowRequestOnCredit &&
                            employeePaidLeaveSummary &&
                            isLeaveAvailableDaysExceeded(employeePaidLeaveSummary)">
            {{"leave.messages.availableQuotaExceeded" | translate: {
            selected: leaveEditorUtils.getNumberOfBusinessDays(formGroup.controls.startDate.value, formGroup.controls.endDate.value),
            available: employeePaidLeaveSummary.allowed - employeePaidLeaveSummary.used
          } }}
          </mat-hint>
          <mat-hint
            *ngIf="formGroup.controls.type.value === LeaveType.Paid &&
                    employeePaidLeaveSummary &&
                    !isLeaveAvailableDaysExceeded(employeePaidLeaveSummary)">
            {{"leave.messages.paidLeaveStatsShort" | translate: {
            available: employeePaidLeaveSummary.allowed - employeePaidLeaveSummary.used -
              (formGroup.controls.id.value === 0 ?
                leaveEditorUtils.getNumberOfBusinessDays(formGroup.controls.startDate.value, formGroup.controls.endDate.value) :
                  leaveEditorUtils.getNumberOfBusinessDaysDifference(leaveEditorUtils.originalLeave.startDate,
                    leaveEditorUtils.originalLeave.endDate, formGroup.controls.startDate.value, formGroup.controls.endDate.value)),
            allowed: employeePaidLeaveSummary.allowed
          } }}
          </mat-hint>
          <mat-hint
            *ngIf="formGroup.controls.type.value === LeaveType.Medical && employeeMedicalLeaveSummary">
            {{"leave.messages.medicalLeaveStatsShort" | translate: {used: employeeMedicalLeaveSummary.used} }}
          </mat-hint>
        </mat-form-field>
      </div>
      <div *ngIf="formGroup.controls.type.value === LeaveType.Absence" class="col-md-8">
        <mat-form-field appearance="fill" class="margin-left">
          <mat-label>{{"leave.startTime" | translate}}</mat-label>
          <input type="time" matInput formControlName="startTime">
        </mat-form-field>
        <mat-form-field appearance="fill" class="margin-left">
          <mat-label>{{"leave.endTime" | translate}}</mat-label>
          <input type="time" matInput formControlName="endTime">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="fill" class="full-fill">
          <mat-label>{{"employee.employee" | translate}}</mat-label>
          <gits-select-autocomplete formControlName="employeeBadgeNumber"
                                    optionalTextTranslateKey="leave.approvalNotRequired"
                                    placeholder="test"
                                    [options]="employees"
                                    [formatOptionFn]="employeeService.formatEmployeeName"
                                    [filterOptionsFn]="employeeService.filterEmployeesInList"
                                    (optionSelected)="onEmployeeSelectionChange($event?.badgeNumber)">
          </gits-select-autocomplete>
          <mat-hint>
            {{"employee.department" | translate}} : {{this.getEmployeeDepartmentName(formGroup.controls.employeeBadgeNumber.value)}}
          </mat-hint>
          <mat-error>
            {{"employee.messages.missingSelection" | translate}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6" *ngIf="filter !== LeaveType.Business">
        <mat-form-field appearance="fill" class="full-fill">
          <mat-label>{{"leave.substitute" | translate}}</mat-label>
          <gits-select-autocomplete formControlName="substituteBadgeNumber"
                                    [optional]="!leaveSubstituteRequired"
                                    optionalTextTranslateKey="leave.approvalNotRequired"
                                    [options]="departmentEmployees"
                                    [formatOptionFn]="employeeService.formatEmployeeName"
                                    [filterOptionsFn]="employeeService.filterEmployeesInList"
                                    (optionSelected)="onSubstituteSelectionChange($event?.badgeNumber)">
          </gits-select-autocomplete>
          <mat-hint *ngIf="isSubstituteBusy && leaveAllowBusySubstitute">
            {{"leave.messages.errEmployeeBusyDuringPeriod" | translate}}
          </mat-hint>
          <mat-error *ngIf="formGroup.controls.substituteBadgeNumber.hasError('busy')">
            {{"leave.messages.errEmployeeBusyDuringPeriod" | translate}}
          </mat-error>
          <mat-error *ngIf="!formGroup.controls.substituteBadgeNumber.hasError('busy')">
            {{"leave.messages.errMissingSubstitute" | translate}}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="fill" class="full-fill">
          <mat-label>{{"leave.departmentManager" | translate}}</mat-label>
          <gits-select-autocomplete formControlName="approverBadgeNumber"
                                    [optional]="!leaveMedicalManagerApproval && formGroup.controls.type.value === LeaveType.Medical"
                                    optionalTextTranslateKey="leave.approvalNotRequired"
                                    [options]="departmentManagers"
                                    [formatOptionFn]="employeeService.formatEmployeeName"
                                    [filterOptionsFn]="employeeService.filterEmployeesInList"
                                    (optionSelected)="onApproverSelectionChange($event?.badgeNumber)">
          </gits-select-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="fill" class="full-fill">
          <mat-label>{{"leave.humanResourcesManager" | translate}}</mat-label>
          <gits-select-autocomplete formControlName="hrApproverBadgeNumber"
                                    [optional]="formGroup.controls.type.value !== LeaveType.Medical ||
                                    (!leaveMedicalHrManagerApproval && formGroup.controls.type.value === LeaveType.Medical)"
                                    optionalTextTranslateKey="leave.approvalNotRequired"
                                    [options]="hrManagers"
                                    [formatOptionFn]="employeeService.formatEmployeeName"
                                    [filterOptionsFn]="employeeService.filterEmployeesInList"
                                    (optionSelected)="onHrApproverSelectionChange($event?.badgeNumber)">
          </gits-select-autocomplete>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <table class="full-width">
          <tr>
            <td>
              <mat-form-field class="full-fill">
                <mat-label>{{"leave.details" | translate}}</mat-label>
                <textarea matInput formControlName="details"></textarea>
                <mat-hint *ngIf="formGroup.controls.type.value === LeaveType.Bereavement">{{"leave.messages.bereavementDetailsHint" | translate}}</mat-hint>
                <mat-error
                  *ngIf="formGroup.controls.details.errors?.required">{{"leave.messages.missingDetailsOnReject" | translate}}
                </mat-error>
              </mat-form-field>
            </td>
          </tr>
        </table>
        <mat-label>{{"leave.attachments" | translate}} ({{formGroup.controls.documentsList.value.length}})</mat-label>
        <table mat-table [dataSource]="formGroup.controls.documentsList.value"
               class="mat-elevation-z0 full-width transparent-container">
          <ng-container matColumnDef="icon">
            <td mat-cell *matCellDef="let document" class="transparent-container margin-left icon-cell-bottom">
              <div *ngIf="document.id !== '' && !canEditLeave()">
                <mat-icon>
                  {{leaveEditorUtils.computeFileTypeIcon(document.fileType)}}
                </mat-icon>
              </div>
              <div *ngIf="document.id !== '' && canEditLeave()">
                <button mat-icon-button type="button" (click)="deleteAttachment(document.id)" [disabled]="actionInProgress">
                  <mat-icon matTooltip="{{'leave.actions.deleteAttachment' | translate}}">
                    {{leaveEditorUtils.computeFileTypeIcon(document.fileType)}}
                  </mat-icon>
                </button>
              </div>
              <div *ngIf="document.id === ''">
                <button mat-icon-button type="button" (click)="cancelAttachDocument()" [disabled]="actionInProgress">
                  <mat-progress-spinner matTooltip="{{'leave.actions.cancelAttachDocument' | translate}}"
                                        formControlName="attachmentProgressSpinner"
                                        [value]="attachProgressValue"
                                        [diameter]="24">
                  </mat-progress-spinner>
                </button>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <td mat-cell *matCellDef="let document" class="transparent-container item"
                (click)="downloadAttachment(document.id)">
              <a class="row-link"> {{document.fileName}} </a>
            </td>
          </ng-container>
          <tr mat-row *matRowDef="let row; columns: ['icon', 'name'];" class="transparent-container"></tr>
        </table>
        <div *ngIf="canEditLeave()">
          <button mat-icon-button [attr.aria-label]="'leave.actions.attachDocument' | translate"
                  type="button" (click)="attachDocumentInput.click()" class="light-text first-of-type" [disabled]="actionInProgress">
            <mat-icon matTooltip="{{'leave.actions.attachDocument' | translate}}">attach_file</mat-icon>
            {{'leave.actions.attachDocument' | translate}}
          </button>
          <input hidden #attachDocumentInput type="file" (change)="attachDocument($event)">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <button mat-flat-button color="primary" class="m-1" type="submit" [disabled]="formGroup.pristine || !canEditLeave() || actionInProgress">
          {{"generic.save" | translate}}
        </button>
        <button mat-stroked-button color="warn" class="m-1" type="button" *ngIf="canCancelLeave()" (click)="cancelLeave()">
          {{"generic.cancel" | translate}}
        </button>
        <button mat-flat-button color="primary" class="m-1" type="button" *ngIf="canRequestLeave()" (click)="requestLeave()"
                [disabled]="!formGroup.pristine || actionInProgress">
          {{"generic.sendRequest" | translate}}
        </button>
        <button mat-flat-button color="primary" class="m-1" type="button" *ngIf="canApproveLeave()" (click)="approveLeave()"
                [disabled]="actionInProgress || !leaveEditorUtils.isFormPristineExceptDetails(formGroup)">
          {{"generic.approve" | translate}}
        </button>
        <button mat-stroked-button color="warn" class="m-1" type="button" *ngIf="canApproveLeave()" (click)="rejectLeave()"
                [disabled]="actionInProgress || !leaveEditorUtils.isFormPristineExceptDetails(formGroup)">
          {{"generic.reject" | translate}}
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-expansion-panel (opened)="journalPanelOpenState = true; loadLeaveJournal(formGroup.controls.id.value);"
                             (closed)="journalPanelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{"journal.activitiesJournal" | translate}}
            </mat-panel-title>
            <mat-panel-description *ngIf="journalActivities.length > 0">
              <div *ngIf="journalPanelOpenState === false">
                <ng-container
                  *ngTemplateOutlet="journalActivityTemplate; context: {journalActivity: journalActivities[0]}"></ng-container>
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <!-- use ng-template for lazy loading on opening the panel -->
          <ng-template matExpansionPanelContent>
            <ng-container *ngIf="(journalPanelLoadComplete | async) === true; else loading"></ng-container>
            <mat-grid-list [hidden]="(journalPanelLoadComplete | async) === false" cols="1" rowHeight="1em">
              <mat-grid-tile
                *ngFor="let journalActivity of journalActivities">
                <div class="w-100">
                  <ng-container
                    *ngTemplateOutlet="journalActivityTemplate; context: {journalActivity: journalActivity}"></ng-container>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </ng-template>
        </mat-expansion-panel>

        <ng-template #journalActivityTemplate
                     let-journalActivity="journalActivity">
          <div [ngSwitch]="journalActivity.type">
            <ng-template [ngSwitchCase]="RevisionType.ADD">
              {{ "leave.messages.journalAddActivity" | translate: {
              employeeName: journalActivity.audit.lastModifiedByFullName,
              date: dateTimeService.formatDateTimeInterval(journalActivity.audit.lastModifiedDate),
              leaveType: "leave.types.articled." + journalActivity.entity.type |  translate | lowercase
            } }}
            </ng-template>
            <ng-template [ngSwitchCase]="RevisionType.MOD">
              {{ "leave.messages.journalChangedActivity" | translate: {
              employeeName: journalActivity.audit.lastModifiedByFullName
            } }}
              <ng-container
                *ngTemplateOutlet="journalFieldsListTemplate; context: {fields: journalActivity.entity}"></ng-container>
              {{ dateTimeService.formatDateTimeInterval(journalActivity.audit.lastModifiedDate) }}
            </ng-template>
          </div>
        </ng-template>

        <ng-template #journalFieldsListTemplate
                     let-fields="fields">
          <div *ngFor="let field of fields | keyvalue; last as isLast" style="display: inline-block;">
            <div *ngIf="! $any(field.key).includes('BadgeNumber')">
              <span
                class="field-name">{{"leave." + field.key | translate | lowercase}}</span> {{"generic.to" | translate}}
              <span [ngSwitch]="field.key">
                <span *ngSwitchCase="'status'">"{{"leave.statuses." + field.value | translate}}"</span>
                <span *ngSwitchCase="'type'">"{{"leave.types." + field.value | translate}}"</span>
                <span *ngSwitchCase="'startDate'">"{{dateTimeService.formatDate(field.value)}}"</span>
                <span *ngSwitchCase="'endDate'">"{{dateTimeService.formatDate(field.value)}}"</span>
                <span *ngSwitchCase="'approverFullName'">
                  <span *ngTemplateOutlet="journalEmployeeField; context: {employeeFullName: field.value}"></span>
                </span>
                <span *ngSwitchCase="'substituteFullName'">
                  <span *ngTemplateOutlet="journalEmployeeField; context: {employeeFullName: field.value}"></span>
                </span>
                <span *ngSwitchCase="'hrApproverFullName'">
                  <span *ngTemplateOutlet="journalEmployeeField; context: {employeeFullName: field.value}"></span>
                </span>
                <span *ngSwitchCase="'documentsList'">
                  "<span *ngFor="let file of $any(field).value; last as isLast">
                    <span *ngIf="!isLast">{{file.fileName}}, </span>
                    <span *ngIf="isLast">{{file.fileName}}</span>
                  </span>"
                </span>
                <span *ngSwitchDefault>"{{field.value}}"</span>
              </span>
              <span *ngIf="!isLast">,&nbsp;</span>
            </div>
          </div>
        </ng-template>

        <ng-template #journalEmployeeField
                     let-employeeFullName="employeeFullName">
          "{{employeeFullName}}"
        </ng-template>

        <ng-template #loading>
          <div class="row text-center">
            <div class="col-md-12">
              <mat-spinner class="text-center"></mat-spinner>
            </div>
          </div>
        </ng-template>

      </div>
    </div>
  </form>
</div>
