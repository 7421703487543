<mat-card>
  <mat-card-title>
    {{employee.lastName}} {{employee.firstName}} ({{employee.badgeNumber}})
  </mat-card-title>
  <mat-card-subtitle>
    {{employee.departmentName}} @ {{sessionData.currentUser.organizationName}}
  </mat-card-subtitle>
  <mat-card-content>
    <table class="full-fill">
      <tr>
        <td class="profile-image-border">
          <div class="hoverable-container">
            <img *ngIf="employeePhoto" class="profile-image hoverable-content"
                 src="{{'data:image/jpg;base64,' + employeePhoto.photo}}">
            <img *ngIf="!employeePhoto" class="profile-image hoverable-content"
                 src="/assets/default-profile-picture.png">
            <div class="hoverable-action-content action-icon-left-bottom">
              <mat-icon matTooltip="{{'employee.addProfilePhoto' | translate}}" (click)="addPhotoInput.click()">add_a_photo</mat-icon>
              <input type="file" name="imageFile" accept="image/*" class="invisible" (change)="onPhotoUpload($event)" #addPhotoInput>
            </div>
            <div *ngIf="employeePhoto" class="hoverable-action-content action-icon-right-bottom">
              <mat-icon matTooltip="{{'employee.deleteProfilePhoto' | translate}}" (click)="deleteEmployeePhoto(employeePhoto)">delete</mat-icon>
            </div>
          </div>
        </td>
        <td>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <section *ngFor="let leave of leaveSummary">
            <div *ngIf="leave.type === LeaveType.Paid">
              {{"leave.messages.paidLeaveStats" | translate : {
                available: leave.allowed - leave.used,
                allowed: leave.allowed}
              }}<span *ngIf="leave.type === LeaveType.Paid && (!leaveShowResidualSummary || leave.residual === 0)">.</span>
              <span *ngIf="leave.type === LeaveType.Paid && leaveShowResidualSummary && leave.residual !== 0">
                {{"leave.messages.paidLeaveResidual" | translate : {residual: leave.residual} }}.
              </span>
            </div>
            <div *ngIf="leave.type === LeaveType.Medical && leave.used !== 0">
              {{"leave.messages.medicalLeaveStats" | translate : {used: leave.used} }}.
            </div>
            <div *ngIf="leave.type === LeaveType.Absence && leave.used !== 0">
              {{"leave.messages.absenceLeaveStats" | translate : {used: leave.used} }}.
            </div>
            <div *ngIf="leave.type === LeaveType.Compensatory">
              {{"leave.messages.additionalLeaveStats" | translate : {
              additionalLeave: leave.allowed,
              date: leave.endDate
            } }}.
            </div>
            <div *ngIf="leave.type === LeaveType.PublicHoliday && leave.nextHoliday !== null">
              <div *ngIf="leave.endDate === null">{{"leave.messages.nextHoliday" | translate}} :
                {{"leave.messages.holidayDescription" | translate : {
                  description: leave.nextHoliday,
                  date: leave.startDate | date:'d MMM yyyy'
                } }}.
              </div>
            </div>
          </section>
        </td>
      </tr>
    </table>
    <mat-error *ngIf="!leaveSummary">
      {{"leave.messages.errGetStats" | translate}}
    </mat-error>
  </mat-card-content>
</mat-card>
