import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {FeedFilter} from '../interfaces/feed';
import {Page, PageFilter} from '../interfaces/page';
import {ErrorService} from './error.service';

@Injectable({
  providedIn: 'root'
})
export class FeedService {

  constructor(private http: HttpClient) {
  }

  static feedApi: string = environment.backendServiceUrl + '/' + environment.backendApiContext + '/feeds';

  getFeeds(feedFilter?: FeedFilter, pageFilter?: PageFilter): Observable<Page> {
    let params = new HttpParams();
    if (feedFilter !== undefined) {
      Object.entries(feedFilter).forEach(([key, value]) =>
        params = params.append(key, value as string));
    }
    if (pageFilter !== undefined) {
      Object.entries(pageFilter).forEach(([key, value]) =>
        params = params.append(key, value as string));
    }
    return this.http.get<Page>(FeedService.feedApi + '/', {params})
      .pipe(
        catchError(ErrorService.handleError)
      );
  }
}
