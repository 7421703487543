<footer>
  <div class="SiteFooter__container SiteFooter__container--main">
    <span>
      <a href="/privacy-policy/" target="_blank">Privacy Policy</a>
    </span>
    &nbsp;·&nbsp;
    <span>
      <a href="/terms-of-service/" target="_blank">Terms of Service</a>
    </span>
    &nbsp;&nbsp;
    <span>
      © 2021 <a href="https://www.gitscom.ro" target="_blank">GITS</a> All rights reserved
      &nbsp;·&nbsp;
      v 1.1.0-snapshot-test
    </span>
  </div>
</footer>
