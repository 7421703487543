  <input matInput type="text" onfocus="this.select()"
         [formControl]="inputControl" [matAutocomplete]="gitsAutocomplete" (focus)="onFocus()" (focusout)="onFocusOut()">
  <mat-autocomplete #gitsAutocomplete="matAutocomplete" autoActiveFirstOption
                    [displayWith]="formatOptionFn"
                    (optionSelected)="onOptionSelected($event.option.value)">
    <mat-option *ngFor="let option of filteredOptions" [value]="option" [id]="option.badgeNumber">
      {{formatOptionFn(option)}}
    </mat-option>
  </mat-autocomplete>
  <!--<mat-hint>{{"employee.department" | translate}}
    : {{this.getEmployeeDepartmentName(formGroup.value.employeeBadgeNumber)}}
  </mat-hint>
  <mat-error
    *ngIf="formGroup.value.employeeBadgeNumber == 0">{{"employee.messages.missingSelection" | translate}}
  </mat-error>-->
