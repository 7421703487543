<div>
  <mat-icon class="mat-18">perm_identity</mat-icon>
  <h1>Employees stuff...</h1>
</div>

<nav mat-tab-nav-bar>
  <a mat-tab-link [routerLink]="['list']" routerLinkActive="active">Employees stuff</a>
  <a mat-tab-link [routerLink]="['org-chart']" routerLinkActive="active">Organization Chart</a>
  <a mat-tab-link disabled>Disabled Link</a>
</nav>
<router-outlet></router-outlet>

<!--<mat-tab-group>
  <mat-tab label="Employees list">
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar></div>
        <mat-card-title>Employees stuff</mat-card-title>
        <mat-card-subtitle>Some stuff here...</mat-card-subtitle>
      </mat-card-header>
      <div mat-card-image></div>
      <mat-card-content>
        <p>
          Bla bla bla...
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button>LIKE</button>
        <button mat-button>SHARE</button>
      </mat-card-actions>
    </mat-card>
  </mat-tab>
  <mat-tab label="Organization Chart">
    <app-employees-orgchart></app-employees-orgchart>
  </mat-tab>
</mat-tab-group>-->
