import {Component, Input, OnDestroy} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {TimesheetFilter} from '../../../interfaces/timesheet';
import {Page, PageFilter, Paginator} from '../../../interfaces/page';
import {takeUntil} from 'rxjs/operators';
import {SessionService} from '../../../services/session.service';
import {TimesheetService} from '../../../services/timesheet.service';
import {Subject, throwError} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {differenceInHours, differenceInMinutes, hoursToMinutes, minutesToHours} from 'date-fns';
import {DateTimeService} from '../../../services/date-time.service';
import {saveAs} from 'file-saver';
import {ReportService} from '../../../services/report.service';
import {ErrorService} from '../../../services/error.service';
import {ReportTemplateType} from '../../../interfaces/report-template';
import {ReportFileType} from '../../../interfaces/report';
import {
  getGridTimesheetManagerColumnDefs,
  GRID_TIMESHEET_MANAGER_DEBUG_OPTIONS,
  GRID_TIMESHEET_MANAGER_LABELS,
  GRID_TIMESHEET_MANAGER_OPTIONS
} from './timesheet-manager-grid.config';
import {PAGINATION_OPTIONS} from '../../../@utils/constants/constants';
import {MatPaginatorOptions} from '@gits/ngx-tabulator-tables/lib/models/pagination-config.model';
import {ColumnsTranslationsConfig, IColumnDef, RowButton} from '@gits/ngx-tabulator-tables';
import {DebugConfigModel} from '@gits/ngx-tabulator-tables/lib/models/debug-config.model';
import {TableApiService} from '@gits/ngx-tabulator-tables/lib/services/table-api.service';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-timesheet-manager',
  templateUrl: './timesheet-manager.component.html',
  styleUrls: ['./timesheet-manager.component.less']
})
export class TimesheetManagerComponent implements OnDestroy {

  @Input()
  get filter(): TimesheetFilter {
    return this.timesheetFilter as TimesheetFilter;
  }

  set filter(filter: Partial<TimesheetFilter>) {
    this.timesheetFilter = filter;
    this.loadTimesheets();
  };

  destroyed$: Subject<void> = new Subject<void>();
  timesheetFilter: Partial<TimesheetFilter> = {};
  timesheetPaginator: Paginator = new Paginator();

  /*** START OF grid config */
  tableId: string = 'timesheetCalendarGrid';
  gridApi: TableApiService | undefined;
  currentLang: string;
  gridOptions: any = GRID_TIMESHEET_MANAGER_OPTIONS;
  paginationOptions: MatPaginatorOptions = PAGINATION_OPTIONS;
  rowControls: RowButton[] = [
    {
      el: 'span',
      title: 'Edit',
      label: 'generic.edit',
      classList: ['table-btn', 'me-2', 'pe-2', 'bi', 'bi-pencil-fill'],
      callback: this.onRowEdit,
      bindToScope: this
    },
  ];
  debugConfig: DebugConfigModel = GRID_TIMESHEET_MANAGER_DEBUG_OPTIONS;
  gridData: any = {};

  gridDateFormatter = (cell: any, formatterParams: any): string => {
    let value = cell.getValue();
    if (value === undefined || value === null || value === '') {
      return '';
    }

    if (!(value instanceof Date)) {
      value = new Date(Number(value));
    }

    return DateTime.fromJSDate(value).toFormat('dd-MM-yyyy HH:mm');
  };
  gridLocationFormatter = (cell: any): string => {
    const value = cell.getValue();
    if (value === undefined || value === null) {
      return this.translateService.instant(`generic.unknown`);
    }

    return this.translateService.instant(`location.types.${value}`);
  };
  gridStatusFormatter = (cell: any): string => {
    const value = cell.getValue();
    if (value === undefined || value === null) {
      return this.translateService.instant(`generic.unknown`);
    }

    return this.translateService.instant(`timesheet.statuses.${value}`);
  };
  gridDurationFormatter = (cell: any): string => {
    const rowData = cell.getRow().getData();
    if (rowData.duration !== undefined && rowData.duration !== null) {
      return rowData.duration;
    }
    if (rowData.dateTimeOut === null || rowData.dateTimeIn === null) {
      return '';
    }

    const hours = differenceInHours(rowData.dateTimeOut as Date, rowData.dateTimeIn as Date);
    const minutes = differenceInMinutes(rowData.dateTimeOut as Date, rowData.dateTimeIn as Date);

    return String(hours).padStart(2, '0') + ":" + String((minutes - (hours * 60))).padStart(2, '0');
  };

  durationCalculation = (values: any[], data: any[], calcParams: any): number => {
    return data.filter(current => current.dateTimeOut !== undefined && current.dateTimeOut !== null )
      .reduce((sum, current) =>
        sum + differenceInMinutes(current.dateTimeOut as Date, current.dateTimeIn as Date), 0)
  };

  formatMinutesToHours(cell: any): String {
    const minutes = cell.getValue();
    if (Number.isNaN(minutes) || minutes === undefined || minutes === null) {
      return '00:00';
    }
    const formatHours = minutesToHours(minutes);
    const formatMinutes = minutes - hoursToMinutes(formatHours);
    return String(formatHours).padStart(2, '0') + ':' + String(formatMinutes).padStart(2, '0');
  }

  columnLabels: ColumnsTranslationsConfig = GRID_TIMESHEET_MANAGER_LABELS;
  columnsDefs: IColumnDef[] = getGridTimesheetManagerColumnDefs(this);

  /*** END OF grid config */

  constructor(private dateTimeService: DateTimeService,
              private errorService: ErrorService,
              private reportService: ReportService,
              private router: Router,
              private sessionService: SessionService,
              private timesheetService: TimesheetService,
              private translateService: TranslateService) {

    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(() => {
      if (this.gridApi) {
        // redraw table to apply the translations in formatters
        this.gridApi.getTableReference(this.tableId).redraw(true);
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  onTableApiAvailable(gridApi: TableApiService) {
    this.gridApi = gridApi;
  }

  onRowEdit(rowData: any) {
    this.editTimesheet(rowData.id);
  }

  // todo decide if we need to implement user privileges access
  userHasPrivileges(privileges: string[]): boolean {
    return true;
  }

  onPageChanged(event: PageEvent) {
    this.loadPage(event);
  }

  editTimesheet(id: number): void {
    this.router.navigate(['/timesheets/timesheet/' + id]);
  }

  exportToExcel() {
    const params: Map<string, object> = new Map<string, object>();
    params.set('filter', this.timesheetFilter);
    this.reportService.get(ReportTemplateType.HumanResourcesTimesheets, params, ReportFileType.Xlsx)
      .subscribe(response => {
          const contentDisposition = response.headers.get('content-disposition');
          let filename;
          if (contentDisposition != null) {
            filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          }
          saveAs(response.body as Blob, filename);
        },
        error => this.errorService.showError('reports.messages.unableToGenerate', {error}));

    return;
  }

  exportToPdf(): void {
    const params: Map<string, object> = new Map<string, object>();
    params.set('filter', this.timesheetFilter);

    this.reportService.get(ReportTemplateType.HumanResourcesTimesheets, params)
      .subscribe(
        response => {
          const contentDisposition = response.headers.get('content-disposition');
          let filename;
          if (contentDisposition != null) {
            filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          }
          saveAs(response.body as Blob, filename);
        },
        error => {
          this.errorService.showError('reports.messages.unableToGenerate', {error})
        })

  }

  loadPage(newPage: PageEvent): void {
    this.loadTimesheets({
      page: newPage.pageIndex + 1,
      size: newPage.pageSize,
      unpaged: false
    });
  }

  loadTimesheets(page?: Partial<PageFilter>): void {
    const defaultPage =
      {
        page: this.timesheetPaginator.page + 1,
        size: 25
      };
    const pageFilter = page ? page : defaultPage;
    this.timesheetService.getTimesheets(this.timesheetFilter, pageFilter)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (timesheetsPage: Page) => {
          const columnCalcModule = this.gridApi?.getTableReference(this.tableId).module('columnCalcs');
          if (this.timesheetFilter.employeeBadgeNumbers?.length === 1) {
            columnCalcModule.reinitializeCalcs();
          } else {
            columnCalcModule.removeCalcs();
          }
          this.gridData = timesheetsPage;
          this.timesheetPaginator.page = timesheetsPage.number;
        },
        error => {
          throwError(this.translateService.instant('timesheet.messages.errLoading', {error}));
        });
  }
}
