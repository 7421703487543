// Angular components
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatStepperModule} from '@angular/material/stepper';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule} from '@angular/material/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTableModule} from '@angular/material/table';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableExporterModule} from 'mat-table-exporter';
// https://mattlewis92.github.io/angular-calendar
import {CalendarModule as MLCalendarModule, DateAdapter as MLDateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormatDistancePipeModule, FormatDistanceToNowPipeModule} from 'ngx-date-fns';
import {DatePipe} from '@angular/common';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {NgxPaginationModule} from 'ngx-pagination';

// GITS components
import {NgxTabulatorTableModule} from '@gits/ngx-tabulator-tables';
import {NgxGitsProgressBarModule} from '@gits/ngx-gits-progress-bar';

// app components
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ConfirmationDialogComponent} from './components/confirmation-dialog/confirmation-dialog.component';
import {DashboardComponent} from './pages/activities/dashboard/dashboard.component';
import {EmployeesComponent} from './components/employees/employees.component';
import {AppFooterComponent} from './components/app-footer/app-footer.component';
import {AppHeaderComponent} from './components/app-header/app-header.component';
import {EmployeesListComponent} from './components/employees/employees-list/employees-list.component';
import {EmployeesOrgchartComponent} from './components/employees/employees-orgchart/employees-orgchart.component';
import {EmployeePageComponent} from './components/employees/employee-page/employee-page.component';
import {EmployeeDetailsComponent} from './components/employees/employee-page/employee-details/employee-details.component';
import {EmployeeNotesComponent} from './components/employees/employee-page/employee-notes/employee-notes.component';
import {EmployeeDocumentsComponent} from './components/employees/employee-page/employee-documents/employee-documents.component';
import {EmployeePanelComponent} from './components/employee-panel/employee-panel.component';
import {QuickAccessPanelComponent} from './components/quick-access-panel/quick-access-panel.component';
import {EventsCalendarComponent} from './components/events-calendar/events-calendar.component';
import {FeedPanelComponent} from './components/feed-panel/feed-panel.component';
import {EventsListComponent} from './components/events-list/events-list.component';
import {LeaveEditorComponent} from './pages/leaves/leave-editor/leave-editor.component';
import {SessionService} from './services/session.service';
import {LoginComponent} from './pages/security/login/login.component';
import {LeavesCalendarComponent} from './pages/leaves/leaves-calendar/leaves-calendar.component';
import {BasicAuthInterceptor} from './config/basic-auth.interceptor';
import {AuthenticationGuard} from './services/authentication-guard.service';
import {UserNotificationPanelComponent} from './components/user-notification-panel/user-notification-panel.component';
import {UserNotificationEditorComponent} from './pages/users/user-notification-editor/user-notification-editor.component';
import {UserProfileComponent} from './pages/users/user-profile/user-profile.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {PaidLeaveReportComponent} from './pages/reports/paid-leave-report/paid-leave-report.component';
import {GitsSelectAutocompleteComponent} from './components/gits-select-autocomplete/gits-select-autocomplete.component';
import {EmploymentCertificateDialog} from './pages/reports/employment-certificate/employment-certificate-dialog';
import {TimesheetsCalendarComponent} from './pages/timesheets/timesheets-calendar/timesheets-calendar.component';
import {TimesheetEditorComponent} from './pages/timesheets/timesheet-editor/timesheet-editor.component';
import {TimesheetFilterComponent} from './components/timesheet-filter/timesheet-filter.component';
import {LeaveFilterComponent} from './components/leave-filter/leave-filter.component';
import {LeaveManagerComponent} from './pages/leaves/leave-manager/leave-manager.component';
import {TimesheetManagerComponent} from './pages/timesheets/timesheet-manager/timesheet-manager.component';
import {ApplicationConfigurationComponent} from './pages/config/application-configuration/application-configuration.component';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import {UtilsModule} from './@utils/utils.module';
import {AggregatedSummaryReportComponent} from "./pages/reports/aggregated-summary/aggregated-summary-report.component";

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new MultiTranslateHttpLoader(httpClient, [
    {prefix: "../assets/i18n/", suffix: ".json"},
    {prefix: "../assets/i18n/ngx-tabulator-tables/i18n/", suffix: ".json"},
  ]);
}

@NgModule({
  declarations: [
    AppComponent,
    AppFooterComponent,
    AppHeaderComponent,
    ConfirmationDialogComponent,
    DashboardComponent,
    EmployeesComponent,
    EmployeesListComponent,
    EmployeesOrgchartComponent,
    EmployeePageComponent,
    EmployeeDetailsComponent,
    EmployeeNotesComponent,
    EmployeeDocumentsComponent,
    EmployeePanelComponent,
    EmploymentCertificateDialog,
    QuickAccessPanelComponent,
    EventsCalendarComponent,
    EventsListComponent,
    FeedPanelComponent,
    LeaveEditorComponent,
    LeavesCalendarComponent,
    LeaveFilterComponent,
    LeaveManagerComponent,
    LoginComponent,
    UserNotificationPanelComponent,
    UserNotificationEditorComponent,
    UserProfileComponent,
    GitsSelectAutocompleteComponent,
    PageNotFoundComponent,
    PaidLeaveReportComponent,
    TimesheetsCalendarComponent,
    TimesheetEditorComponent,
    TimesheetFilterComponent,
    TimesheetManagerComponent,
    ApplicationConfigurationComponent,
    AggregatedSummaryReportComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    UtilsModule,
    MLCalendarModule.forRoot({
      provide: MLDateAdapter,
      useFactory: adapterFactory
    }),
    MatToolbarModule, MatSidenavModule, MatListModule, MatButtonModule, MatIconModule, MatCardModule, MatTabsModule,
    MatTooltipModule, MatFormFieldModule, MatSelectModule, MatRadioModule, MatInputModule, MatDatepickerModule,
    MatNativeDateModule, MatSnackBarModule, MatStepperModule, MatProgressSpinnerModule, MatButtonToggleModule,
    MatCheckboxModule, MatExpansionModule, MatGridListModule, MatMenuModule, MatTableModule, MatProgressBarModule,
    MatSlideToggleModule, FormsModule, ReactiveFormsModule, MatSortModule, MatAutocompleteModule, MatPaginatorModule,
    MatDialogModule, MatTableExporterModule, MatTableModule, MatChipsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'ro-RO',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    FormatDistancePipeModule,
    FormatDistanceToNowPipeModule,
    NgxPaginationModule,
    NgxTabulatorTableModule,
    NgxGitsProgressBarModule
  ],
  providers: [SessionService,
    AuthenticationGuard,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BasicAuthInterceptor,
      multi: true,
    }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
