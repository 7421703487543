export interface User {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  locked: boolean;
  active: boolean;
  position: string;
  organizationName: string;
  authorities: {authority: string}[];
}

export enum UserRole {
  user = 'ROLE_USER',
  departmentManager = 'ROLE_DEPARTMENT_MANAGER',
  humanResourceManager = 'ROLE_HUMAN_RESOURCES_MANAGER'
}
