<h1 mat-dialog-title>{{'reports.employmentCertificate' | translate}}</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>{{'reports.employmentCertificateReason' | translate}}</mat-label>
    <input matInput cdkFocusInitial [(ngModel)]="reason">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-flat-button color="primary" type="submit" [mat-dialog-close]="reason">{{'generic.generate' | translate}}</button>
  <button mat-flat-button (click)="onNoClick()">{{'generic.cancel' | translate}}</button>
</div>
