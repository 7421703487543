import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ErrorService} from './error.service';
import {ServiceConfiguration, ServiceConfigurationFilter, ServiceType} from '../interfaces/service-configuration';
import {SessionService} from './session.service';
import {OrganizationType} from '../interfaces/organization';

@Injectable({
  providedIn: 'root'
})
export class ServiceConfigurationService {

  private static translateService: TranslateService;
  private static sessionService: SessionService;

  constructor(private http: HttpClient,
              private sessionService: SessionService,
              private translateService: TranslateService) {
    ServiceConfigurationService.translateService = this.translateService;
    ServiceConfigurationService.sessionService = this.sessionService;
  }

  static serviceConfigurationApi: string = environment.backendServiceUrl + '/' + environment.backendApiContext + '/' + 'config/service';

  getServiceConfiguration(property: string, service: string, organization: string): Observable<ServiceConfiguration> {
    return this.http.get<ServiceConfiguration>(ServiceConfigurationService.serviceConfigurationApi + '/' + property +
      '/' + service + '/' + organization)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  getServiceConfigurations(serviceConfigurationFilter?: Partial<ServiceConfigurationFilter>): Observable<ServiceConfiguration[]> {
    let params = new HttpParams();
    if (serviceConfigurationFilter !== undefined) {
      Object.entries(serviceConfigurationFilter)
        .forEach(([key, value]) =>
          params = params.append(key, value as string)
        );
    }
    return this.http.get<ServiceConfiguration[]>(ServiceConfigurationService.serviceConfigurationApi + '/', {params})
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  createServiceConfiguration(serviceConfiguration: ServiceConfiguration): Observable<ServiceConfiguration> {
    return this.http.post<ServiceConfiguration>(ServiceConfigurationService.serviceConfigurationApi, serviceConfiguration)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  patchServiceConfiguration(serviceConfigurations: Partial<ServiceConfiguration>[]): Observable<ServiceConfiguration[]> {
    return this.http.patch<ServiceConfiguration[]>(ServiceConfigurationService.serviceConfigurationApi, serviceConfigurations)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  updateServiceConfigurations(serviceConfigurations: ServiceConfiguration[]): Observable<ServiceConfiguration[]> {
    return this.http.put<ServiceConfiguration[]>(ServiceConfigurationService.serviceConfigurationApi, serviceConfigurations)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  static getBooleanPropertyValue(propertyName: string, defaultValue?: boolean, configurations?: ServiceConfiguration[]): boolean | null {
    const value = ServiceConfigurationService.getPropertyValue(propertyName, '', configurations);
    if (value !== null) {
      if (value.toLowerCase() === 'true') {
        return true;
      }
      if (value.toLowerCase() === 'false') {
        return false;
      }
    }

    return defaultValue !== undefined ? defaultValue : null;
  }

  static getNumberPropertyValue(propertyName: string, defaultValue?: number, configurations?: ServiceConfiguration[]): number | null {
    const value = Number(ServiceConfigurationService.getPropertyValue(propertyName, '', configurations));
    if (isNaN(value) || value === null) {
      return defaultValue !== undefined ? defaultValue : null;
      ;
    }

    return value;
  }

  static getPropertyValue(propertyName: string, defaultValue?: string, configurations?: ServiceConfiguration[]): string | null {
    const sessionData = this.sessionService.getData();
    if (sessionData === undefined || sessionData === null) {
      return null;
    }

    if (configurations === undefined || configurations === null) {
      if (sessionData.serviceConfiguration === undefined || sessionData.serviceConfiguration === null) {
        return null;
      }
      configurations = sessionData.serviceConfiguration;
    }

    // find exact service, exact org
    let property = configurations.find(config =>
      config.id.service === ServiceType.HumanResources &&
      config.id.organization === sessionData.currentUser.organizationName &&
      config.id.property === propertyName);
    // find exact service, org default
    if (property === undefined) {
      property = configurations.find(config =>
        config.id.service === ServiceType.HumanResources &&
        config.id.organization === OrganizationType.Default &&
        config.id.property === propertyName);
    }
    // find default service, exact org
    if (property === undefined) {
      property = configurations.find(config =>
        config.id.service === ServiceType.Default &&
        config.id.organization === sessionData.currentUser.organizationName &&
        config.id.property === propertyName);
    }
    // find default service, default org
    if (property === undefined) {
      property = configurations.find(config =>
        config.id.service === ServiceType.Default &&
        config.id.organization === OrganizationType.Default &&
        config.id.property === propertyName);
    }

    if (property === undefined) {
      return defaultValue !== undefined ? defaultValue : null;
    }

    return property.value;
  }
}
