import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {UserNotification, UserNotificationFilter} from '../interfaces/user-notification';
import {Page, PageFilter} from '../interfaces/page';
import {ErrorService} from './error.service';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationService {

  static userNotificationApi: string = environment.backendServiceUrl + '/' + environment.backendApiContext + '/' + 'users/notifications';

  constructor(private http: HttpClient) {
  }

  getUserNotification(id: number): Observable<UserNotification> {
    return this.http.get<UserNotification>(UserNotificationService.userNotificationApi + '/' + id)
      .pipe(
        map(this.replaceTokens),
        catchError(ErrorService.handleError)
      );
  }

  getUserNotifications(userNotificationFilter?: Partial<UserNotificationFilter>, pageFilter?: Partial<PageFilter>): Observable<Page> {
    let params = new HttpParams();
    if (userNotificationFilter !== undefined) {
      Object.entries(userNotificationFilter)
        .forEach(([key, value]) => {
          if (value) {
            params = params.append(key, value.toString());
          }
        });
    }
    if (pageFilter !== undefined) {
      Object.entries(pageFilter)
        .forEach(([key, value]) => {
          if (value) {
            params = params.append(key, value.toString());
          }
        });
    }

    return this.http.get<Page>(UserNotificationService.userNotificationApi + '/', {params})
      .pipe(
        map(page => {
          page.content.forEach(this.replaceTokens);
          return page;
        }),
        catchError(ErrorService.handleError)
      );
  }

  createUserNotification(userNotification: UserNotification): Observable<UserNotification> {
    return this.http.post<UserNotification>(UserNotificationService.userNotificationApi, userNotification)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  updateUserNotification(userNotification: UserNotification): Observable<UserNotification> {
    return this.http.put<UserNotification>(UserNotificationService.userNotificationApi, userNotification)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  patchUserNotification(userNotification: Partial<UserNotification>): Observable<UserNotification> {
    if (userNotification == null || userNotification.id === undefined) {
      throwError('invalid input');
    }
    return this.http.patch<UserNotification>(UserNotificationService.userNotificationApi, userNotification)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  deleteUserNotification(id: number): Observable<UserNotification> {
    return this.http.delete<UserNotification>(UserNotificationService.userNotificationApi + '/' + id)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  replaceTokens = (notification: UserNotification) => {
    if (notification.message) {
      notification.message = notification.message.replace('[[url]]',
        window.location.protocol + '//' + window.location.host);
    }
    return notification;
  }
}
