<div class="container-fluid">
  <form [formGroup]="formGroup" (ngSubmit)="saveUserNotification()">
    <div class="row justify-content-md-center">
      <div class="col-md-2">
        <mat-form-field appearance="fill" class="full-fill">
          <mat-label>{{'generic.type' | translate}}</mat-label>
          <mat-select formControlName="type">
            <mat-option *ngFor="let key of keysType" [value]="key">
              {{'userNotification.types.' + key | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field appearance="fill" class="full-fill">
          <mat-label>{{'userNotification.priority' | translate}}</mat-label>
          <mat-select formControlName="priority">
            <mat-option *ngFor="let key of keysPriorities" [value]="key">
              {{'userNotification.priorities.' + key | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row justify-content-md-center">
      <div class="col-md-4">
        <mat-form-field class="full-fill">
          <mat-label>{{'userNotification.to' | translate}}</mat-label>
          <mat-select formControlName="targetUserAccountId">
            <mat-option *ngFor="let employee of employees" [value]="employee.badgeNumber">
              {{employee.lastName}} {{employee.firstName}} ({{employee.badgeNumber}})
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row justify-content-md-center">
        <div class="col-md-4">
          <mat-form-field class="full-fill">
            <mat-label>{{'userNotification.subject' | translate}}</mat-label>
            <textarea matInput formControlName="subject"></textarea>
          </mat-form-field>
        </div>
    </div>
    <div class="row justify-content-md-center">
      <div class="col-md-4">
        <mat-form-field class="full-fill">
          <mat-label>{{'userNotification.message' | translate}}</mat-label>
          <textarea matInput formControlName="message"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="row justify-content-md-center">
      <div class="col-md-2">
        <mat-form-field appearance="fill">
          <mat-label>{{"userNotification.expirationDate" | translate}}</mat-label>
          <input matInput formControlName="expirationDate" [matDatepicker]="picker">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="formGroup.controls.expirationDate.invalid">
            {{"userNotification.messages.invalidEndDate" | translate}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <button mat-flat-button type="button" class="float-right" (click)="navigateBack()">{{'generic.cancel' | translate}}</button>
        <button mat-flat-button color="primary" class="float-right" type="submit" [disabled]="formGroup.pristine">
          {{"generic.save" | translate}}
        </button>
      </div>
    </div>
  </form>
</div>
