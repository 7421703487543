<div>
  <h1>{{"reports.aggregatedSummary.title" | translate}}</h1>

  <ng-container [formGroup]="formGroup">
  <div class="row justify-content-md-left">
    <div class="col-md-2">
      <mat-form-field appearance="fill" class="full-fill">
        <mat-label>{{"employee.department" | translate}}</mat-label>
        <mat-select #selDepartment value="all" (openedChange)="departmentChange(!$event)" formControlName="departments" multiple>
          <mat-option [value]="ALL_VALUE"
                      (click)="toggleAllSelection(selDepartment)">
            {{"generic.all-f" | translate}}
          </mat-option>
          <mat-option *ngFor="let department of departments" [value]="department.name">
            {{department.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-3">
      <mat-form-field appearance="fill" class="full-fill">
        <mat-label>{{"employee.employee" | translate}}</mat-label>
        <mat-select #selBadgeNumber value="all" (openedChange)="employeeChange(!$event)" formControlName="employeeBadgeNumbers" multiple>
          <mat-option [value]="ALL_VALUE"
                      (click)="toggleAllSelection(selBadgeNumber)">
            {{"generic.all-m" | translate }}
          </mat-option>
          <mat-option *ngFor="let employee of employees" [value]="employee.badgeNumber">
            {{employee.fullName}} ({{employee.badgeNumber}})
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-3">
      <mat-form-field appearance="fill">
        <mat-label>{{"leave.range" | translate}}</mat-label>
        <mat-date-range-input formGroupName="dateRange" [rangePicker]="picker">
          <input formControlName="startDate"  matStartDate placeholder="Start date">
          <input formControlName="endDate" matEndDate placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
        <mat-date-range-picker #picker (closed)="dateRangeChange(true)"></mat-date-range-picker>
        <mat-error
          *ngIf="formGroup.get('dateRange.startDate')?.hasError('matStartDateInvalid')">{{"leave.messages.invalidStartDate" | translate}}</mat-error>
        <mat-error
          *ngIf="formGroup.get('dateInRange.endDate')?.hasError('matEndDateInvalid')">{{"leave.messages.invalidEndDate" | translate}}</mat-error>
      </mat-form-field>
    </div>

    <div class="col-md-4">
        <span class="outer-action-button float-right">
          <button mat-icon-button type="button" [matMenuTriggerFor]="exportMenu"
                  matTooltip="{{'generic.export' | translate}}">
            <mat-icon>list_alt</mat-icon>
          </button>
          <mat-menu #exportMenu="matMenu" xPosition="before">
            <button mat-menu-item (click)="exportToExcel()">
              <mat-icon>filter_none</mat-icon>
              <span>{{"generic.exportXls" | translate}}</span>
            </button>
            <button mat-menu-item (click)="exportToPdf()">
              <mat-icon>picture_as_pdf</mat-icon>
              <span>{{"generic.exportPdf" | translate}}</span>
            </button>
          </mat-menu>
        </span>
    </div>
  </div>

  <div class="row">
    <gits-tabulator-table [tableId]="tableId"
                          [eventsSubscriptions]="gridSubscriptions"
                          [initLang]="currentLang"
                          [initWithoutTranslationsCheck]="true"
                          [columnsDefs]="columnDefs"
                          [columnLabels]="columnLabels"
                          [options]="gridOptions"
                          [data]="gridData"
                          [paginatorOptions]="paginationOptions"
                          [addRowButtons]="rowControls"
                          [groupByFieldsConfig]=""
                          [rowFormatter]=""
                          [customValidators]=""
                          [debugConfig]=""
                          [showHelp]=""
                          (apiAvailable)="onTableApiAvailable($event)"
                          (pageChanged)="onPageChanged($event)">
    </gits-tabulator-table>
  </div>
  </ng-container>

</div>
