import {Component, OnDestroy, OnInit} from '@angular/core';
import {DateTimeService} from '../../services/date-time.service';
import {map, takeUntil} from 'rxjs/operators';
import {Leave, LeaveStatus, LeaveType} from '../../interfaces/leave';
import {concat, Subject} from 'rxjs';
import {LeaveService} from '../../services/leave.service';
import {MatCalendarCellCssClasses} from '@angular/material/datepicker';
import {SessionData} from '../../interfaces/session';
import {SessionService} from '../../services/session.service';
import {Router} from '@angular/router';
import {Page} from "../../interfaces/page";

@Component({
  selector: 'app-events-calendar',
  templateUrl: './events-calendar.component.html',
  styleUrls: ['./events-calendar.component.less']
})
export class EventsCalendarComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();
  private employeeLeaves: Leave[] = [];
  sessionData = this.sessionService.getData() as SessionData;
  public leaveLoadComplete = false;

  weekendsAndHolidaysFilter = (date: Date | null): boolean => {
    const day = (date || new Date()).getDay();
    return day !== 0 && day !== 6 && !this.dateTimeService.isPublicHoliday(date);
  };

  constructor(private dateTimeService: DateTimeService,
              private leaveService: LeaveService,
              private sessionService: SessionService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.loadLeaves();
  }

  loadLeaves(): void {
    const today = new Date();
    const startOfMonth = this.dateTimeService.formatDate(this.dateTimeService.startOfMonth(today), 'yyyy-MM-dd');
    const endOfMonth = this.dateTimeService.formatDate(this.dateTimeService.endOfMonth(today), 'yyyy-MM-dd');

    const employeePastLeaveFilter = {
      employeeBadgeNumber: this.sessionData.currentUser.id,
      startDateLessThanOrEqualTo: endOfMonth
    };
    const employeeFutureLeaveFilter = {
      employeeBadgeNumber: this.sessionData.currentUser.id,
      endDateGreaterThanOrEqualTo: startOfMonth
    };
    const employeePastLeaves = this.leaveService.getLeaves(employeePastLeaveFilter)
      .pipe(
        map((page: Page) => {
          if (page.numberOfElements > 0) {
            return page.content;
          } else {
            return [];
          }
        }),
        takeUntil(this.destroy$),
      );
    const employeeFutureLeaves = this.leaveService.getLeaves(employeeFutureLeaveFilter)
      .pipe(
        map((page: Page) => {
          if (page.numberOfElements > 0) {
            return page.content;
          } else {
            return [];
          }
        }),
        takeUntil(this.destroy$),
      );

    const substitutePastLeavesFilter = {
      substituteBadgeNumber: this.sessionData.currentUser.id,
      startDateLessThanOrEqualTo: endOfMonth
    };
    const substituteFutureLeavesFilter = {
      substituteBadgeNumber: this.sessionData.currentUser.id,
      startDateLessThanOrEqualTo: endOfMonth
    };
    const substitutePastLeaves = this.leaveService.getLeaves(substitutePastLeavesFilter)
      .pipe(
        map((page: Page) => {
          if (page.numberOfElements > 0) {
            return page.content;
          } else {
            return [];
          }
        }),
        takeUntil(this.destroy$),
      );
    const substituteFutureLeaves = this.leaveService.getLeaves(substituteFutureLeavesFilter)
      .pipe(
        map((page: Page) => {
          if (page.numberOfElements > 0) {
            return page.content;
          } else {
            return [];
          }
        }),
        takeUntil(this.destroy$),
      );

    concat(employeePastLeaves, employeeFutureLeaves, substitutePastLeaves, substituteFutureLeaves)
    .subscribe({
      next: response => this.employeeLeaves.push(...response),
      complete: () => this.leaveLoadComplete = true
    });
  }

  onSelectChange(selectedDate: Date | null): void {
    if (selectedDate === null) {
      return;
    }
    const existingLeave = this.getLeaveOnDate(selectedDate);
    if (existingLeave) {
      this.router.navigate(['/leaves/leave/' + existingLeave.id]);
    }
  }

  computeDateClass(): (cellDate: Date, view: string) => MatCalendarCellCssClasses {
    return (cellDate: Date, view: string): MatCalendarCellCssClasses => {
      if (!cellDate) {
        return '';
      }
      if (view === 'month') {
        if (cellDate.getDay() === 0 || cellDate.getDay() === 6) {
          return '';
        }
        if (this.dateTimeService.isPublicHoliday(cellDate)) {
          return '';
        }
        const leave = this.getLeaveOnDate(cellDate);
        if (leave == null) {
          return '';
        }
        if (leave.type === LeaveType.Business && (leave.status === LeaveStatus.HumanResourcesApproved || leave.status === LeaveStatus.Finalized)) {
          return 'leave-day-business-leave';
        }
        if (leave.substituteBadgeNumber === this.sessionData.currentUser.id) {
          return 'leave-day-substitute';
        }
        if (leave.status != null) {
          switch (leave.status) {
            case LeaveStatus.HumanResourcesApproved:
            case LeaveStatus.Finalized:
              return 'leave-day-approved';
            case LeaveStatus.New:
              return 'leave-day-new';
            case LeaveStatus.Planned:
            case LeaveStatus.SubstituteApproved:
            case LeaveStatus.ManagerApproved:
              return 'leave-day-pending';
            case LeaveStatus.SubstituteRejected:
            case LeaveStatus.ManagerRejected:
            case LeaveStatus.HumanResourcesRejected:
            case LeaveStatus.Canceled:
              return 'leave-day-rejected';
          }
        }
      }

      return '';
    };
  }

  getLeaveOnDate(date: Date): Leave | undefined {
    date.setHours(12, 0, 0);
    return this.employeeLeaves.find( leave =>
      leave.startDate && leave.endDate &&
      this.dateTimeService.isBetween(date, leave.startDate, leave.endDate, false)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
