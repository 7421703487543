import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ErrorService} from './error.service';
import {HttpClient} from '@angular/common/http';
import {EmployeeAddress} from '../interfaces/employee-address';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmployeeAddressService {

  constructor(private http: HttpClient) {
  }

  static employeeAddressApi: string = environment.backendServiceUrl + '/' + environment.backendApiContext + '/' + 'employees/addresses';

  patch(employeeAddress: Partial<EmployeeAddress>): Observable<EmployeeAddress> {
    if (employeeAddress == null || employeeAddress.badgeNumber === undefined) {
      throwError('invalid input');
    }
    return this.http.patch<EmployeeAddress>(EmployeeAddressService.employeeAddressApi, employeeAddress)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }
}
