import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HumanResourcesManager} from '../interfaces/human-resources-manager';
import {ErrorService} from './error.service';

@Injectable({
  providedIn: 'root'
})
export class HumanResourcesManagerService {

  constructor(private http: HttpClient) {
  }

  static hrManagerApi: string = environment.backendServiceUrl + '/' + environment.backendApiContext + '/' + 'hr-managers';

  get(id: number): Observable<HumanResourcesManager> {
    return this.http.get<HumanResourcesManager>(HumanResourcesManagerService.hrManagerApi + '/' + id)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  getAll(): Observable<HumanResourcesManager[]> {
    return this.http.get<HumanResourcesManager[]>(HumanResourcesManagerService.hrManagerApi + '/')
      .pipe(
        catchError(ErrorService.handleError)
      );
  }
}
