<div class="m-0 p-0 flex-grow-1">
  <mat-card>
    <mat-card-title>
      <mat-icon>campaign</mat-icon>
      {{'feed.type.' + type | translate}}
    </mat-card-title>
    <mat-card-content>
      <div *ngFor="let feed of feeds | paginate: {
        id: type,
        itemsPerPage: pageSize,
        currentPage: currentPage,
        totalItems: totalItems}"
           class="item">
        <div *ngIf="feed.title" class="item-title-text inline">{{feed.title}} - </div>
        {{feed.feed}}
        <div *ngIf="feed.source" class="inline">
          (<a *ngIf="feed.link" href="{{feed.link}}" target="_blank">{{feed.source}}</a>
          <span *ngIf="!feed.link">{{feed.source}}</span>)
        </div>
        <div *ngIf="feed.date" class="item-date">{{dateTimeService.formatDate(feed.date, 'medium')}}</div>
      </div>
      <mat-error *ngIf="isError">{{'feed.messages.errLoading' | translate}}</mat-error>
    </mat-card-content>
    <mat-card-actions>
      <div class="text-right">
        <pagination-controls
          id="{{type}}"
          [responsive]="true"
          [maxSize]="3"
          previousLabel="{{'page.previous' | translate}}"
          nextLabel="{{'page.next' | translate}}"
          (pageChange)="currentPage = $event">
        </pagination-controls>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
