export interface ServiceConfiguration {
  id: {
    property: string;
    service: string;
    organization: string;
  };
  value: string;
  validators: string;
  category: string;
  description: string;
}

export interface ServiceConfigurationFilter {
  propertyExact: string;
  serviceExact: string;
  organizationExact: string;
}

export enum ServiceConfigurationProperties {
  BusinessLeaveEnabled = "businessLeaveEnabled",
  DailyEventsEnabled = "dailyEventsEnabled",
  DailyEventsShowBirthAnniversary = 'dailyEventsShowBirthAnniversary',
  LeaveAllowBusySubstitute = 'leaveAllowBusySubstitute',
  LeaveAllowRequestOnCredit = 'leaveAllowRequestOnCredit',
  LeaveCalendarDisplayFormat = 'leaveCalendarDisplayFormat',
  LeaveEmployeeCancel = 'leaveEmployeeCancel',
  LeaveEnabled = 'leaveEnabled',
  LeaveFilterDefaultEmployeeSelection = 'leaveFilterDefaultEmployeeSelection',
  LeaveSelfManagerApproval = 'leaveSelfManagerApproval',
  LeaveRetroactiveApprovingManagerWrite = 'leaveRetroactiveApprovingManagerWrite',
  LeaveMaxLeaveOfAbsencePeriod = "leaveMaxLeaveOfAbsencePeriod",
  LeaveMedicalManagerApproval = 'leaveMedicalManagerApproval',
  LeaveMedicalHrManagerApproval = 'leaveMedicalHrManagerApproval',
  LeaveNotifyHrManagers = "leaveNotifyHrManagers",
  LeavePaidSubstituteApproval = "leavePaidSubstituteApproval",
  LeaveShowCanceledInCalendar = 'leaveShowCanceledInCalendar',
  LeaveShowResidualSummary = "leaveShowResidualSummary",
  LeaveSkipPlanning = "leaveSkipPlanning",
  LeaveSubstituteRequired = "leaveSubstituteRequired",
  ReportAggregatedSummaryHourCalculation = "reportAggregatedSummaryHourCalculation",
  ReportAggregatedSummaryTimesheetsIncludeBusinessLeaves = "reportAggregatedSummaryTimesheetsIncludeBusinessLeaves",
  ReportAggregatedSummaryWorkingHours = "reportAggregatedSummaryWorkingHours",
  TimesheetEnabled = 'timesheetEnabled',
  TimesheetShowCanceledInCalendar = 'timesheetShowCanceledInCalendar',
  UserPasswordPolicyMinDigit = 'userPasswordPolicyMinDigit',
  UserPasswordPolicyMinLength = 'userPasswordPolicyMinLength',
  UserPasswordPolicyMinLower = 'userPasswordPolicyMinLower',
  UserPasswordPolicyMinSpecial = 'userPasswordPolicyMinSpecial',
  UserPasswordPolicyMinUpper = 'userPasswordPolicyMinUpper',
  UserPasswordPolicySpecialList = 'userPasswordPolicySpecialList'
}

export enum ServiceType {
  Default = 'Default',
  HumanResources = 'HumanResources'
}
