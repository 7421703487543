<div class="container p-3 flex-grow-1">
  <div class="row">
    <div class="col-md-4">
      <mat-card class="light-background full-fill">
        <mat-card-subtitle class="subtitle">{{'leave.planning' | translate}}</mat-card-subtitle>
        <mat-card-content>
          <div *ngIf="leaveEnabled">
            <a [routerLink]="['/leaves/leave']"
               [queryParams]="{
                        type: LeaveType.Paid,
                        startDate: computeStartOfNextWeek() | date : 'yyyy-MM-dd',
                        endDate: computeEndOfNextWeek() | date : 'yyyy-MM-dd'}">
              {{"leave.actions.requestPaidLeaveNextWeek" | translate}}</a>
          </div>
          <div *ngIf="leaveEnabled">
            <a [routerLink]="['/leaves/leave']" [queryParams]="{type: LeaveType.Medical}">{{"leave.actions.requestMedicalLeaveToday" | translate}}</a>
          </div>
          <div *ngIf="leaveEnabled">
            <a [routerLink]="['/leaves/leave']" [queryParams]="{type: LeaveType.Absence}">{{"leave.actions.requestAbsenceNextHour" | translate}}</a>
          </div>
          <div *ngIf="leaveEnabled" class="text-center">
            <button mat-flat-button color="primary" class="button"
                    [routerLink]="['/leaves/leave']">
              {{"leave.actions.requestLeave" | translate}}
            </button>
          </div>
          <div *ngIf="leaveEnabled" class="text-center">
            <button mat-flat-button color="primary" class="button"
                    *ngIf="businessLeaveEnabled"
                    [routerLink]="['/leaves/leave']"
                    [queryParams]="{
                          filter: LeaveType.Business}">
              {{"leave.actions.requestBusinessLeave" | translate}}
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div *ngIf="timesheetEnabled" class="col-md-4">
      <mat-card class="light-background full-fill">
        <mat-card-subtitle class="subtitle">{{'timesheet.timesheet' | translate}}</mat-card-subtitle>
        <mat-card-content>
          <br>
          <div class="text-center">
            <button mat-flat-button color="primary" class="button"
                    [routerLink]="['/timesheets/timesheet']"
                    [queryParams]="{
                          type: TimesheetType.Work,
                          checkin: true}">
              {{"timesheet.checkin" | translate}}</button>
          </div>
          <br>
          <div class="text-center">
            <button mat-flat-button color="primary" class="button"
                    [routerLink]="['/timesheets/timesheet']"
                    [queryParams]="{
                          type: TimesheetType.Work,
                          checkout: true}">
              {{"timesheet.checkout" | translate}}</button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-md-4">
      <mat-card class="light-background full-fill">
        <mat-card-subtitle class="subtitle">{{'reports.reports' | translate}} / {{'reports.certificates' | translate}}</mat-card-subtitle>
        <mat-card-content>
          <div *ngIf="leaveEnabled">
            <a [routerLink]="['/leaves/manager']">{{"reports.leaveRequestsReport.title" | translate}}</a>
          </div>
          <div *ngIf="leaveEnabled && displayPaidLeaveReport">
            <a [routerLink]="['/reports/paid-leave-report']">{{"reports.paidLeavesReport.title" | translate}}</a>
          </div>
          <div>
            <a [routerLink]="[]" (click)="downloadReport()">{{"reports.employmentCertificate" | translate}}</a>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
