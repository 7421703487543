import {Component, OnInit} from '@angular/core';
import {ServiceConfigurationService} from '../../../services/service-configuration.service';
import {
  ServiceConfiguration,
  ServiceConfigurationProperties,
  ServiceType
} from '../../../interfaces/service-configuration';
import {SessionService} from '../../../services/session.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {CalendarView} from 'angular-calendar';
import {Location} from '@angular/common';
import {ErrorService} from '../../../services/error.service';

@Component({
  selector: 'app-application-configuration',
  templateUrl: './application-configuration.component.html',
  styleUrls: ['./application-configuration.component.less']
})
export class ApplicationConfigurationComponent implements OnInit {

  actionInProgress = false;
  CalendarView = CalendarView;
  configurations: ServiceConfiguration[] = [];
  formGroup: FormGroup;

  constructor(private errorService: ErrorService,
              private formBuilder: FormBuilder,
              public location: Location,
              private serviceConfigurationService: ServiceConfigurationService,
              private sessionService: SessionService,
              public translateService: TranslateService,) {
    this.formGroup = this.formBuilder.group({
      businessLeaveEnabled: ServiceConfigurationService.getBooleanPropertyValue(ServiceConfigurationProperties.BusinessLeaveEnabled, false),
      dailyEventsEnabled: ServiceConfigurationService.getBooleanPropertyValue(ServiceConfigurationProperties.DailyEventsEnabled, false),
      dailyEventsShowBirthAnniversary: ServiceConfigurationService.getBooleanPropertyValue(ServiceConfigurationProperties.DailyEventsShowBirthAnniversary, false),
      leaveAllowBusySubstitute: ServiceConfigurationService.getBooleanPropertyValue(ServiceConfigurationProperties.LeaveAllowBusySubstitute, true),
      leaveAllowRequestOnCredit: ServiceConfigurationService.getBooleanPropertyValue(ServiceConfigurationProperties.LeaveAllowRequestOnCredit, true),
      leaveCalendarDisplayFormat: ServiceConfigurationService.getPropertyValue(ServiceConfigurationProperties.LeaveCalendarDisplayFormat, CalendarView.Month),
      leaveEnabled: ServiceConfigurationService.getBooleanPropertyValue(ServiceConfigurationProperties.LeaveEnabled, false),
      leaveMaxLeaveOfAbsencePeriod: ServiceConfigurationService.getNumberPropertyValue(ServiceConfigurationProperties.LeaveMaxLeaveOfAbsencePeriod, 8),
      leaveNotifyHrManagers: ServiceConfigurationService.getBooleanPropertyValue(ServiceConfigurationProperties.LeaveNotifyHrManagers, false),
      leaveSkipPlanning: ServiceConfigurationService.getBooleanPropertyValue(ServiceConfigurationProperties.LeaveSkipPlanning, false),
      leaveSubstituteRequired: ServiceConfigurationService.getBooleanPropertyValue(ServiceConfigurationProperties.LeaveSubstituteRequired, false),
      timesheetEnabled: ServiceConfigurationService.getBooleanPropertyValue(ServiceConfigurationProperties.TimesheetEnabled, false)
    });
  }

  ngOnInit(): void {
    this.subscribeForServiceConfigurations();
  }

  saveConfigurations(): void {
    this.serviceConfigurationService.patchServiceConfiguration(this.convertValueListToConfigurations(this.formGroup.value))
      .subscribe(() => {
        // retrieve changed service configurations
        this.serviceConfigurationService.getServiceConfigurations({
          serviceExact: ServiceType.HumanResources
        })
          .subscribe(configurations => {
              this.sessionService.updateData({
                serviceConfiguration: configurations
              });
            },
            error => {
              this.errorService.showError(error);
            })
      });
  }

  subscribeForServiceConfigurations(): void {
    this.sessionService.getServiceConfigurationsObservable()
      .subscribe(
        configurations => {
          this.formGroup.controls.businessLeaveEnabled.setValue(
            ServiceConfigurationService.getBooleanPropertyValue(ServiceConfigurationProperties.BusinessLeaveEnabled,
              this.formGroup.controls.businessLeaveEnabled.value));
          this.formGroup.controls.leaveAllowBusySubstitute.setValue(
            ServiceConfigurationService.getBooleanPropertyValue(ServiceConfigurationProperties.LeaveAllowBusySubstitute,
              this.formGroup.controls.leaveAllowBusySubstitute.value));
          this.formGroup.controls.leaveAllowRequestOnCredit.setValue(
            ServiceConfigurationService.getBooleanPropertyValue(ServiceConfigurationProperties.LeaveAllowRequestOnCredit,
              this.formGroup.controls.leaveAllowRequestOnCredit.value));
          this.formGroup.controls.leaveCalendarDisplayFormat.setValue(
            ServiceConfigurationService.getPropertyValue(ServiceConfigurationProperties.LeaveCalendarDisplayFormat,
              this.formGroup.controls.leaveCalendarDisplayFormat.value));
          this.formGroup.controls.leaveEnabled.setValue(
            ServiceConfigurationService.getBooleanPropertyValue(ServiceConfigurationProperties.LeaveEnabled,
              this.formGroup.controls.leaveEnabled.value));
          this.formGroup.controls.leaveMaxLeaveOfAbsencePeriod.setValue(
            ServiceConfigurationService.getNumberPropertyValue(ServiceConfigurationProperties.LeaveMaxLeaveOfAbsencePeriod,
              this.formGroup.controls.leaveMaxLeaveOfAbsencePeriod.value));
          this.formGroup.controls.leaveNotifyHrManagers.setValue(
            ServiceConfigurationService.getBooleanPropertyValue(ServiceConfigurationProperties.LeaveNotifyHrManagers,
              this.formGroup.controls.leaveNotifyHrManagers.value));
          this.formGroup.controls.leaveSkipPlanning.setValue(
            ServiceConfigurationService.getBooleanPropertyValue(ServiceConfigurationProperties.LeaveSkipPlanning,
              this.formGroup.controls.leaveSkipPlanning.value));
          this.formGroup.controls.leaveSubstituteRequired.setValue(
            ServiceConfigurationService.getBooleanPropertyValue(ServiceConfigurationProperties.LeaveSubstituteRequired,
              this.formGroup.controls.leaveSubstituteRequired.value));
          this.formGroup.controls.timesheetEnabled.setValue(
            ServiceConfigurationService.getBooleanPropertyValue(ServiceConfigurationProperties.TimesheetEnabled,
              this.formGroup.controls.timesheetEnabled.value));
        }
      )
  }

  private convertValueListToConfigurations(valueList: any): Partial<ServiceConfiguration>[] {
    return Object.entries(valueList).map(([key, value]) => {
      let config: Partial<ServiceConfiguration> = {
        id: {
          property: key,
          service: ServiceType.HumanResources,
          organization: this.sessionService.getData().currentUser.organizationName,
        },
        value: value as string
      };
      return config;
    })
  }
}
