import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ErrorService} from '../../../services/error.service';
import {TimesheetService} from '../../../services/timesheet.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Timesheet, TimesheetStatus, TimesheetType} from '../../../interfaces/timesheet';
import {LocationType} from '../../../interfaces/location';
import {catchError, concatAll, switchMap, takeUntil} from 'rxjs/operators';
import {SessionService} from '../../../services/session.service';
import {EMPTY, Observable, of, Subject, throwError} from 'rxjs';
import {Employee} from '../../../interfaces/employee';
import {UserRole} from '../../../interfaces/user';
import {EmployeeService} from '../../../services/employee.service';
import {SessionData} from '../../../interfaces/session';
import {AuthenticationService} from '../../../services/authentication.service';
import {DatePipe, Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {LeaveService} from '../../../services/leave.service';
import {DateTimeService} from '../../../services/date-time.service';
import {LeaveStatus, LeaveType} from '../../../interfaces/leave';
import {isSameDay, parse} from 'date-fns';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../../../components/confirmation-dialog/confirmation-dialog.component';
import {DepartmentService} from '../../../services/department.service';
import {MatRadioChange} from "@angular/material/radio";

@Component({
  selector: 'app-timesheet-editor',
  templateUrl: './timesheet-editor.component.html',
  styleUrls: ['./timesheet-editor.component.less']
})
export class TimesheetEditorComponent implements OnInit {

  readonly LocationType = LocationType;
  readonly TimesheetStatus = TimesheetStatus;

  private previousUrl;

  actionInProgress = false;
  destroy$ = new Subject<void>();
  employees = [] as Employee[];
  formGroup: FormGroup;
  sessionData: SessionData;

  constructor(private activatedRoute: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private datePipe: DatePipe,
              private dateTimeService: DateTimeService,
              private departmentService: DepartmentService,
              private dialog: MatDialog,
              private employeeService: EmployeeService,
              private errorService: ErrorService,
              private formBuilder: FormBuilder,
              private leaveService: LeaveService,
              private location: Location,
              private router: Router,
              private sessionService: SessionService,
              private timesheetService: TimesheetService,
              private translateService: TranslateService) {
    this.sessionData = this.sessionService.getData();
    const nowIn = new Date();
    const nowInTime = this.dateTimeService.formatDate(nowIn, 'HH:mm');
    this.formGroup = this.formBuilder.group({
      id: 0,
      employeeBadgeNumber: [this.sessionData.currentUser.id, Validators.required],
      type: [TimesheetType.Work],
      location: [null, Validators.required],
      checkin: [true],
      checkout: [false],
      dateTimeIn: [nowIn, Validators.required],
      dateTimeOut: [{value: null, disabled: true}],
      // hourIn, hourOut are user input controls only, use fields dateTimeIn & dateTimeOut for reading timesheet date and time values
      hourIn: [nowInTime, [Validators.required,
        Validators.pattern(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)]],
      hourOut: [{value: null, disabled: true}, [Validators.required,
        Validators.pattern(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)]],
      description: [''],
      status: TimesheetStatus.Open
    });
    this.previousUrl = this.router?.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString();
  }

  ngOnInit(): void {
    this.loadPageParameters(this.activatedRoute);
    this.populateEmployeeLists();
    if (this.formGroup.value.id !== 0) {
      this.loadTimesheet(this.formGroup.value.id);
    } else {
      this.onSelectedDateChanged(this.formGroup.controls.dateTimeIn.value,
        this.formGroup.controls.dateTimeOut.value,
        this.formGroup.controls.dateTimeIn);
    }
  }

  canCancelTimesheet(): boolean {
    return this.formGroup.controls.status.value === TimesheetStatus.Open;
  }

  cancelTimesheet(): void {
    if (this.formGroup.value.id !== 0 && this.canCancelTimesheet()) {
      const descriptionString = this.formGroup.value.description as string;
      if (descriptionString === null || descriptionString.trim() === '') {
        this.formGroup.controls.description.setErrors({required: true});
        this.formGroup.controls.description.markAsTouched();
        setTimeout(() => {
          this.formGroup.controls.description.setErrors(null);
          this.formGroup.controls.description.markAsTouched();
        }, 7000);
        return;
      }

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data: {question: 'timesheet.messages.cancelConfirmation'}});

      dialogRef.afterClosed()
        .subscribe(confirmation => {
          if (confirmation) {
            this.setActionInProgress(true);
            this.timesheetService.cancelTimesheet(this.formGroup.value.id, this.formGroup.value.description)
              .subscribe(
                () => {
                  this.setActionInProgress(false);
                  this.errorService.showInfo('timesheet.messages.cancelSuccess', {
                    id: this.formGroup.value.id
                  });
                  this.navigateBack();
                },
                error => {
                  this.setActionInProgress(false);
                  this.errorService.showError(error);
                }
              );
          }
        });

    } else {
      this.navigateBack();
    }
  }

  getLastOpenTimesheet(employeeBadgeNumber: number, referenceDate: Date, type: TimesheetType): Observable<Timesheet> {
    const timesheetFilter = {
      employeeBadgeNumbers: [employeeBadgeNumber],
      dateTimeInLessThanOrEqualTo: this.dateTimeService.formatDate(referenceDate, 'yyyy-MM-ddTHH:mm'),
      dateTimeOutIsNull: true,
      statusIn: [TimesheetStatus.Open],
      types: [type]
    };
    return this.timesheetService.getTimesheets(timesheetFilter)
      .pipe(
        switchMap(page => {
          if (page.numberOfElements > 0) {
            return of(page.content[0]);
          } else {
            return of();
          }
        })
      );
  }

  loadPageParameters(route: ActivatedRoute): void {
    route.paramMap.forEach(params => {
      if (params.has('id')) {
        this.formGroup.controls.id.setValue(Number(params.get('id')));
      } else {
        // pre-set new fields
        this.activatedRoute.queryParams
          .subscribe(queryParams => {
            if (queryParams.hasOwnProperty('dateTimeIn')) {
              const paramInDate = Date.parse(queryParams.dateTimeIn);
              if (!isNaN(paramInDate)) {
                const newDate = new Date(queryParams.dateTimeIn);
                if (newDate.getUTCHours() === 0 && newDate.getUTCMinutes() === 0) {
                  newDate.setHours(this.formGroup.controls.dateTimeIn.value.getHours());
                  newDate.setMinutes(this.formGroup.controls.dateTimeIn.value.getMinutes());
                }
                this.formGroup.controls.dateTimeIn.setValue(newDate);
              }
            }
            if (queryParams.hasOwnProperty('dateTimeOut')) {
              const paramOutDate = Date.parse(queryParams.dateTimeOut);
              if (!isNaN(paramOutDate)) {
                const newDate = new Date(queryParams.dateTimeOut);
                const calculatedOutDate = this.dateTimeService.addHours(this.formGroup.controls.dateTimeIn.value, 8);
                if (newDate.getUTCHours() === 0 && newDate.getUTCMinutes() === 0) {
                  newDate.setHours(calculatedOutDate.getHours());
                  newDate.setMinutes(calculatedOutDate.getMinutes());
                }
                this.formGroup.controls.dateTimeOut.setValue(newDate);
                this.formGroup.controls.checkout.setValue(true);
                this.onCheckoutChanged(true);
              }
            }
            if (queryParams.hasOwnProperty('type') &&
              Object.keys(TimesheetType).some((type) => type === queryParams.type)) {
              this.formGroup.controls.type.setValue(queryParams.type);
            }
            if (queryParams.hasOwnProperty('location') &&
              Object.keys(LocationType).some((location) => location === queryParams.location)) {
              this.formGroup.controls.location.setValue(queryParams.location);
            }
            if (queryParams.hasOwnProperty('checkin')) {
              this.formGroup.controls.checkin.setValue(queryParams.checkin);
              this.onCheckinChanged(queryParams.checkin);
            }
            if (queryParams.hasOwnProperty('checkout')) {
              this.formGroup.controls.checkout.setValue(queryParams.checkout);
              this.onCheckoutChanged(queryParams.checkout);
              this.getLastOpenTimesheet(this.formGroup.controls.employeeBadgeNumber.value,
                this.formGroup.controls.dateTimeIn.value,
                this.formGroup.controls.type.value)
                .subscribe(timesheet => {
                  if (timesheet !== undefined) {
                    const navigationParams = {
                      queryParams:
                        {checkout: true}
                    };
                    this.router.navigate(['/timesheets/timesheet/' + timesheet.id], navigationParams);
                  }
                });
            }
          });
      }
    });
  }

  loadTimesheet(id: number): void {
    this.setActionInProgress(true);
    if (id > 0) {
      this.timesheetService.getTimesheet(id)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(timesheet => {
            if (timesheet) {
              this.loadTimesheetFields(timesheet);
              if (timesheet.status !== TimesheetStatus.Open) {
                this.formGroup.disable();
                this.formGroup.controls.status.enable();
              }
            }
            this.setActionInProgress(false);
          },
          error => {
            this.errorService.showError(error);
            this.router.navigate(['/home']);
          }
        );
    }
  }

  loadTimesheetFields(timesheet: Timesheet): void {
    this.formGroup.patchValue(timesheet);
    const hasCheckIn = timesheet.dateTimeIn !== undefined && timesheet.dateTimeIn !== null;
    let hasCheckOut = timesheet.dateTimeOut !== undefined && timesheet.dateTimeOut !== null;
    this.formGroup.controls.checkin.setValue(hasCheckIn);
    if (!hasCheckOut) {
      this.activatedRoute.queryParams
        .subscribe(queryParams => {
          if (queryParams.hasOwnProperty('checkout')) {
            hasCheckOut = queryParams.checkout;
            this.formGroup.controls.dateTimeOut.setValue(new Date());
          }

        });
    }
    this.formGroup.controls.checkout.setValue(hasCheckOut);
    this.onCheckoutChanged(hasCheckOut);
    this.onCheckinChanged(hasCheckIn);

  }

  navigateBack(): void {
    const previousUrl = this.previousUrl?.split('?', 1).pop();
    const previousPage = previousUrl?.split('/').pop();
    if (previousPage === 'timesheet') {
      this.router.navigate(['/home']);
    } else {
      this.location.back();
    }
  }

  onCheckinChanged(checked: boolean): void {
    this.formGroup.controls.checkin.setValue(true);
    this.formGroup.controls.hourIn.setValue(this.dateTimeService.formatDate(this.formGroup.controls.dateTimeIn.value, 'HH:mm'));
  }

  onCheckoutChanged(checked: boolean): void {
    if (checked) {
      this.formGroup.controls.dateTimeOut.enable();
      this.formGroup.controls.hourOut.enable();
      if (this.formGroup.controls.dateTimeOut.value === null) {
        const today = new Date();
        if (isSameDay(today, this.formGroup.controls.dateTimeIn.value)) {
          this.formGroup.controls.dateTimeOut.setValue(today);
        } else {
          this.formGroup.controls.dateTimeOut.setValue(this.dateTimeService.addHours(this.formGroup.controls.dateTimeIn.value, 8));
        }
      }
      this.formGroup.controls.hourOut.setValue(this.dateTimeService.formatDate(this.formGroup.controls.dateTimeOut.value, 'HH:mm'));
      this.onCheckoutDateChanged(this.formGroup.controls.dateTimeOut.value);
    } else {
      this.formGroup.controls.dateTimeOut.disable();
      this.formGroup.controls.hourOut.disable();
      this.formGroup.controls.dateTimeOut.setValue(null);
      this.formGroup.controls.hourOut.setValue(null);
    }
  }

  onCheckinDateChanged(date: Date | unknown): void {
    this.adjustDateWithHour(this.formGroup.controls.hourIn.value, this.formGroup.controls.dateTimeIn);
    this.onSelectedDateChanged(date, null, this.formGroup.controls.dateTimeIn);
  }

  onCheckoutDateChanged(date: Date | unknown): void {
    this.adjustDateWithHour(this.formGroup.controls.hourOut.value, this.formGroup.controls.dateTimeOut);
    this.onSelectedDateChanged(null, date,  this.formGroup.controls.dateTimeOut);
  }

  onEmployeeChanged(isChanging: boolean) {
    if (isChanging) {
      return;
    }
    this.onSelectedDateChanged(null, null, this.formGroup.controls.dateTimeIn);
  }

  onSelectedDateChanged(dateIn: Date | unknown, dateOut: Date | unknown, control: AbstractControl): void {
    if (control === undefined) {
      return;
    }

    if (dateIn === undefined || dateIn === null) {
      dateIn = this.formGroup.controls.dateTimeIn.value;
    }

    if (dateOut === undefined || dateOut === null) {
      if (this.formGroup.controls.checkout.value === true ) {
        dateOut = this.formGroup.controls.dateTimeOut.value;
      } else {
        dateOut = this.formGroup.controls.dateTimeIn.value;
      }
    }

    const leaveFilter = {
      employeeBadgeNumber: this.formGroup.controls.employeeBadgeNumber.value,
      startDateLessThanOrEqualTo: this.dateTimeService.formatDate(dateIn, 'yyyy-MM-dd'),
      endDateGreaterThanOrEqualTo: this.dateTimeService.formatDate(dateOut, 'yyyy-MM-dd'),
      statusGreaterThan: LeaveStatus.New,
      statusLessThan: LeaveStatus.Canceled,
      typeNotIn: this.formGroup.controls.location.value === LocationType.BusinessLeave ? [LeaveType.Business, LeaveType.Absence] : [LeaveType.Absence]
    };
    this.leaveService.getLeaves(leaveFilter)
      .subscribe(page => {
        if (page.numberOfElements > 0) {
          let businessLeaves = page.content.filter(leave => leave.type === LeaveType.Business);
          if (businessLeaves.length > 0) {
            control.setErrors({incorrect: true, leaveTypeBusinessLeave: true});
          } else {
            control.setErrors({incorrect: true, leaveTypeOther: true});
          }
        } else {
          control.setErrors(null);
        }
        control.markAsTouched();
      });
  }

  onTypeChange($event: MatRadioChange): void {
    this.onSelectedDateChanged(null, null, this.formGroup.controls.dateTimeIn);
  }

  adjustDateWithHour(hour: string, dateTimeControl: AbstractControl): void {
    const newDate = parse(hour, 'HH:mm', new Date());
    const dateTimeIn: Date = dateTimeControl.value;
    if (dateTimeIn == null) {
      return;
    }
    dateTimeIn.setHours(newDate.getHours());
    dateTimeIn.setMinutes(newDate.getMinutes());
  }

  onHourInChanged(newTime: EventTarget | null): void {
    const newTimeElement = newTime as HTMLInputElement;
    if (newTimeElement === null || newTimeElement.valueAsDate === null) {
      return;
    }
    this.adjustDateWithHour(newTimeElement.value, this.formGroup.controls.dateTimeIn);
  }

  onHourOutChanged(newTime: EventTarget | null): void {
    const newTimeElement = newTime as HTMLInputElement;
    if (newTime === null || (newTime as HTMLInputElement).value === null) {
      return;
    }
    this.adjustDateWithHour(newTimeElement.value, this.formGroup.controls.dateTimeOut);
  }

  populateEmployeeLists(): void {
    const currentEmployee$ = this.employeeService.getActiveEmployees({
      employeeBadgeNumberList: [this.sessionData.currentUser.id]
    });
    // employee list will only have current user, by default
    let employeesList$: Observable<Employee[]> = currentEmployee$;

    // employee list will have all department active members, for dept mangers
    if (this.authenticationService.currentUserHasRole(UserRole.departmentManager) &&
      !this.authenticationService.currentUserHasRole(UserRole.humanResourceManager)) {
      employeesList$ = this.departmentService.filter({
        managerBadgeNumber: this.sessionData.currentUser.id,
        active: true})
        .pipe(
          takeUntil(this.destroy$),
          switchMap(managedDepartments => {
            if (managedDepartments === null || managedDepartments.length < 1) {
              return EMPTY;
            }
            return this.employeeService.getActiveEmployees({
              departmentNameList: this.departmentService.getFlatDepartmentTree(managedDepartments).map(dept => dept.name),
              recurseDepartments: true
            });
        })
        );
    }

    // employee list will have all active employees, for HR mangers
    if (this.authenticationService.currentUserHasRole(UserRole.humanResourceManager)) {
      employeesList$ = this.employeeService.getActiveEmployees({});
    }

    employeesList$.pipe(
      switchMap((employees: Employee[]) => {
        if (this.formGroup.value.id === 0) {
          this.employees = employees;
        }
        return of(employees);
      }),
      takeUntil(this.destroy$)
    )
      .subscribe(employees =>
        this.employees = employees
      );
  }

  saveTimesheet(): void {
    if (this.formGroup.invalid) {
      this.errorService.showError('timesheet.messages.invalidFields');
      return;
    }

    this.setActionInProgress(true);
    const timesheet: Timesheet = this.formGroup.getRawValue();
    const isNew = timesheet.id === null || timesheet.id === undefined || timesheet.id === 0;

    const actionsObservables: Observable<any>[] = [];

    if (isNew) {
      actionsObservables.push(
        this.timesheetService.createTimesheet(timesheet)
          .pipe(
            catchError(error => {
              this.setActionInProgress(false);
              return throwError(this.translateService.instant('timesheet.messages.createError', {error}));
            })
          )
      );
    } else {
      actionsObservables.push(
        this.timesheetService.updateTimesheet(timesheet)
          .pipe(
            catchError(error => {
              this.setActionInProgress(false);
              return throwError(this.translateService.instant('timesheet.messages.updateError', {error})
              );
            })
          )
      );
    }

    of(...actionsObservables)
      .pipe(
        takeUntil(this.destroy$),
        concatAll()
      )
      .subscribe(
        response => {
          this.setActionInProgress(false);
          if (response && response.employeeBadgeNumber) {
            this.navigateBack();
          }
        },
        error => {
          this.setActionInProgress(false);
          this.errorService.showError(error);
        }
      );
  }

  private setActionInProgress(value: boolean): void {
    this.actionInProgress = value;
  }
}
