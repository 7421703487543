import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {catchError, first, switchMap, switchMapTo, tap} from 'rxjs/operators';
import {SessionService} from './session.service';
import {EmployeeService} from './employee.service';
import {User, UserRole} from '../interfaces/user';
import {TranslateService} from '@ngx-translate/core';
import {ServiceConfigurationService} from './service-configuration.service';
import {ServiceType} from '../interfaces/service-configuration';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  // required for the handleError callback method
  private static translateService: TranslateService;

  constructor(private http: HttpClient,
              private serviceConfigurationService: ServiceConfigurationService,
              private sessionService: SessionService,
              private employeeService: EmployeeService,
              private translateService: TranslateService) {
    AuthenticationService.translateService = this.translateService;
  }

  static securityApi: string = environment.backendServiceUrl + '/' + environment.backendApiContext + '/security';
  static payrollRealm = 'payroll';

  private static handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage;
    if (error.status === 401) {
      errorMessage = AuthenticationService.translateService.instant('security.messages.errAuthenticationFailure');
    }
    if (error.status === 0) {
      errorMessage = AuthenticationService.translateService.instant('generic.messages.errBackendNotAvailable');
    }

    return throwError(errorMessage);
  }

  authenticateWithRealm(username: number, password: string): Observable<User> {
    if (!username) {
      return of();
    }
    const headers = new HttpHeaders({Authorization: 'Basic ' + btoa(username + ':' + password)});

    return this.http.post<User>(AuthenticationService.securityApi + '/authn/realm/' + AuthenticationService.payrollRealm,
      null, {headers})
      .pipe(
        first(),
        tap(response => {
          // retrieve user object
            if (response) {
              this.sessionService.setData({
                loggedIn: !!response,
                currentUser: response,
                // TODO: replace with token, password should not be stored anywhere
                authenticationToken: btoa(username + ':' + password)
              });
            }
          }
        ),
        switchMap(response => {
          // retrieve service configurations before returning user object
          return this.serviceConfigurationService.getServiceConfigurations({
            serviceExact: ServiceType.HumanResources
          })
            .pipe(
              catchError(error => {
                this.logout();
                return AuthenticationService.handleError(error);
              }),
              tap(configurations =>
                // load service configurations
                this.sessionService.updateData({
                  serviceConfiguration: configurations
                })),
              switchMapTo(
                // return the user object, finalizing the login
                of(response)
              )
            );
        }),
        catchError(error => AuthenticationService.handleError(error))
      );
  }

  logout(): void {
    this.http.post(AuthenticationService.securityApi + '/logout', {})
      .subscribe(data => {
        },
        error => AuthenticationService.handleError(error)
      );
    this.sessionService.removeData();
  }

  isAuthenticated(): boolean {
    const session = this.sessionService.getData();
    if (session === undefined || session.loggedIn === undefined) {
      return false;
    }
    return session.loggedIn;
  }

  currentUserHasRole(role: UserRole): boolean {
    const session = this.sessionService.getData();
    if (session === undefined || session.currentUser === undefined || session.currentUser.authorities === undefined) {
      return false;
    }
    return session.currentUser.authorities.some(value => value.authority === role);
  }
}
