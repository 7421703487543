import {FormGroup} from "@angular/forms";
import {Leave} from "../../../interfaces/leave";
import {DateTimeService} from "../../../services/date-time.service";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class LeaveEditorUtils {

  originalLeave: Leave = {} as Leave;

  constructor(private dateTimeService: DateTimeService) {
  }

  computeFileTypeIcon(fileType: string): string {
    switch (fileType) {
      case 'image/jpeg':
        return 'image';
      case 'application/pdf':
        return 'picture_as_pdf';
      case 'text/plain':
        return 'article';
      default:
        return 'web_asset';
    }
  }

  deserializeLeave(leave: Leave): Leave {
    if (leave.approverBadgeNumber === null) { // mat-select does not accept null
      leave.approverBadgeNumber = -1;
    }
    if (leave.hrApproverBadgeNumber === null) { // mat-select does not accept null
      leave.hrApproverBadgeNumber = -1;
    }
    if (leave.substituteBadgeNumber === null) { // mat-select does not accept null
      leave.substituteBadgeNumber = -1;
    }
    leave.startDate = new Date(leave.startDate as string);
    leave.endDate = new Date(leave.endDate as string);

    return leave;
  }

  getNumberOfBusinessDays(startDate: Date, endDate: Date): number {
    return this.dateTimeService.countBusinessDays(new Date(endDate), new Date(startDate)) + 1;
  }

  getNumberOfBusinessDaysDifference(originalStartDate: string | Date | null, originalEndDate: string | Date | null, startDate: Date, endDate: Date) : number {
    return this.dateTimeService.countBusinessDays(new Date(endDate), new Date(startDate)) -
      this.dateTimeService.countBusinessDays(new Date(originalEndDate as Date), new Date(originalStartDate as Date));
  }

  /**
   * When any field, other than 'details' field is dirty, form is not ready
   * @param form
   */
  isFormPristineExceptDetails(form: FormGroup): boolean {
    if (form === null || form === undefined) {
      return false;
    }
    if (form.pristine) {
      return true;
    }

    for (let controlName in form.controls) {
      if (form.controls[controlName].dirty && controlName !== 'details') {
        return false;
      }
    }

    return true;
  }

  serializeLeave(leave: Leave, formGroup: FormGroup): Leave {
    if (leave.approverBadgeNumber === -1) {
      leave.approverBadgeNumber = null;
    }
    if (leave.hrApproverBadgeNumber === -1) {
      leave.hrApproverBadgeNumber = null;
    }
    if (leave.substituteBadgeNumber === -1) {
      leave.substituteBadgeNumber = null;
    }
    leave.startDate = this.dateTimeService.convertDateControlToString(formGroup.controls.startDate);
    leave.endDate = this.dateTimeService.convertDateControlToString(formGroup.controls.endDate);

    return leave;
  }
}
