export interface Page {
  content: Array<any>;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  totalPages: number;
  totalElements: number;
}

export interface PageFilter {
  page: number;
  size: number;
  unpaged: boolean;
}

export const UnpagedFilter: Partial<PageFilter> = {
  unpaged: true
};

export class Paginator {
  numberOfElements = 0;
  page = 0;
  size = 25;
  pageSizeOptions = [5, 10, 25, 50, 100];
  totalElements = 0;
}
