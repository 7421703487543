import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {SessionService} from '../../services/session.service';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../services/language.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ServiceConfigurationProperties} from '../../interfaces/service-configuration';
import {ServiceConfigurationService} from '../../services/service-configuration.service';
import {UserRole} from "../../interfaces/user";
import {LeaveType} from "../../interfaces/leave";
import {ProgressBarEventTypes} from '@gits/ngx-gits-progress-bar';
import {ThemePalette} from '@angular/material/core';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.less']
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  progressBarColor: ThemePalette = 'primary';
  progressBarMonitorEvents: ProgressBarEventTypes[] = [ProgressBarEventTypes.HTTP, ProgressBarEventTypes.MANUAL];

  readonly LeaveType = LeaveType;

  currentCountry = '';
  destroy$ = new Subject<void>();
  isUserLoggedIn = false;
  languages = [] as { language: string, country: string }[];
  businessLeaveEnabled: boolean | null = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.BusinessLeaveEnabled,false);
  leaveEnabled: boolean | null = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.LeaveEnabled,true);
  timesheetEnabled: boolean | null = ServiceConfigurationService.getBooleanPropertyValue(
    ServiceConfigurationProperties.TimesheetEnabled,true);

  constructor(private authService: AuthenticationService,
              private languageService: LanguageService,
              private sessionService: SessionService,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.subscribeForLogin();
    this.subscribeForServiceConfigurations();
    this.populateLanguagesList();
    this.setLanguage(this.languageService.getCurrentLocale());
  }

  isUserAdmin(): boolean
  {
    return this.authService.currentUserHasRole(UserRole.humanResourceManager);
  }

  logout(): void {
    this.authService.logout();
  }

  populateLanguagesList(): void {
    let languageCode;
    this.translateService.getLangs()
      .forEach(language => {
        languageCode = language.split('-');
        if (languageCode.length > 1) {
          this.languages.push({
            language,
            country: languageCode[1]
          });
        }
      });
  }

  setLanguage(newLanguage: string): void {
    if (newLanguage) {
      this.languageService.setCurrentLocale(newLanguage);
      const countrySplit = newLanguage.split('-');
      if (countrySplit.length > 0) {
        this.currentCountry = countrySplit[1];
      }
    }
  }

  subscribeForLogin(): void {
    this.sessionService.isUserLoggedIn
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(isUserLoggedIn => {
        this.isUserLoggedIn = isUserLoggedIn;
      });
  }

  private subscribeForServiceConfigurations(): void {
    this.sessionService.getServiceConfigurationsObservable()
      .subscribe(
        configurations => {
          this.businessLeaveEnabled = ServiceConfigurationService.getBooleanPropertyValue(ServiceConfigurationProperties.BusinessLeaveEnabled,
            this.businessLeaveEnabled as boolean, configurations);
          this.leaveEnabled = ServiceConfigurationService.getBooleanPropertyValue(ServiceConfigurationProperties.LeaveEnabled,
            this.leaveEnabled as boolean, configurations);
          this.timesheetEnabled = ServiceConfigurationService.getBooleanPropertyValue(ServiceConfigurationProperties.TimesheetEnabled,
            this.timesheetEnabled as boolean, configurations);
        }
      )
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
