<div class="jumbotron d-flex align-items-center full-fill">
  <div class="container-fluid text-center">
    <div class="row">
      <div class="col">
        <mat-form-field appearance="fill" class="bottom-margin-30">
          <mat-label>{{ 'security.username' | translate }}</mat-label>
          <input matInput type="text" [formControl]="username" (keydown.enter)="login()" autofocus
                 placeholder="{{'security.usernamePlaceholder' | translate}}">
          <mat-error
            *ngIf="username.errors?.pattern">{{ 'security.messages.errWrongUsernameFormat' | translate }}</mat-error>
          <mat-error *ngIf="username.errors?.required">{{ 'security.messages.errMissingUsername' | translate }}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'security.password' | translate }}</mat-label>
          <input matInput type="password" [formControl]="password" (keydown.enter)="login()" autofocus>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button mat-flat-button color="primary" class="m-1"
                (click)="login()">{{ 'security.authenticate' | translate }}</button>
      </div>
    </div>
  </div>
</div>
