<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button mat-icon-button>
      <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
    </button>
    <h1>{{ 'generic.humanResources' | translate }}</h1>
    <div *ngIf="isUserLoggedIn">
      <button mat-button [routerLink]="'/home'"> {{'generic.home' | translate}} </button>
      <button mat-button *ngIf="leaveEnabled" [routerLink]="'/leaves/calendar'">
        {{'leave.leavesAndAbsences' | translate}}
      </button>
      <button mat-button *ngIf="timesheetEnabled" [routerLink]="'/timesheets/calendar'">
        {{'timesheet.timesheet' | translate }}
      </button>
      <button mat-button *ngIf="businessLeaveEnabled" [routerLink]="'/businessLeaves/calendar'" [queryParams]="{filter: LeaveType.Business}">
        {{'leave.businessLeaves' | translate}}
      </button>
      <!-- <a mat-button [routerLink]="'/employee-details'"> My info </a>
      <a mat-button [routerLink]="'/employees'"> People </a>
      -->
    </div>
    <span class="toolbar-spacer"></span>
    <button mat-icon-button *ngIf="isUserLoggedIn" [matMenuTriggerFor]="settingsMenu">
      <mat-icon matTooltip="{{ 'settings.settings' | translate }}">settings</mat-icon>
    </button>
    <mat-menu #settingsMenu="matMenu">
      <button mat-menu-item [routerLink]="'users/user-profile'">{{ 'user.profile' | translate }}</button>
      <div *ngIf="isUserAdmin()">
        <button mat-menu-item [routerLink]="'config/application-configuration'">{{ 'settings.appConfig' | translate }}</button>
      </div>
    </mat-menu>
    <button mat-button [matMenuTriggerFor]="languageMenu">
      <span class="flag-icon flag-icon-{{currentCountry.toLowerCase()}}"></span>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #languageMenu="matMenu">
      <ng-container *ngFor="let language of languages">
        <button mat-menu-item (click)="setLanguage(language.language)">
          <span
            class="flag-icon flag-icon-{{language.country.toLowerCase()}}"></span> {{"language." + language.language | translate}}
        </button>
      </ng-container>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>

<ngx-gits-progress-bar [color]="progressBarColor"
                       [monitorEvents]="progressBarMonitorEvents"
                       [showProgress]="true">
</ngx-gits-progress-bar>

<mat-sidenav-container>
  <mat-sidenav #sidenav (click)="sidenav.close()">
    <mat-nav-list>
      <!--<a mat-list-item [routerLink]="'/employee-details/info'"> Personal details </a>
      <a mat-list-item [routerLink]="'/employee-details/job'"> Job </a>
      <a mat-list-item [routerLink]="'/employee-details/time-off'"> Time Off </a>
      <a mat-list-item [routerLink]="'/employee-details/documents'"> Documents </a>
      <a mat-list-item [routerLink]="'/employee-details/notes'"> Notes </a>
      <a mat-list-item [routerLink]="'/employee-details/other'"> More... </a>
      -->
      <a mat-list-item *ngIf="!isUserLoggedIn" [routerLink]="'/login'">{{ 'security.login' | translate }}</a>
      <a mat-list-item *ngIf="isUserLoggedIn" (click)="logout()"
         [routerLink]="'/login'">{{ 'security.logout' | translate }}</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div style="height: 88vh; padding: 20px">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>

</mat-sidenav-container>

