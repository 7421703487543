import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Feed, FeedType} from 'src/app/interfaces/feed';
import {DateTimeService} from 'src/app/services/date-time.service';
import {FeedService} from 'src/app/services/feed.service';

@Component({
  selector: 'app-feed-panel',
  templateUrl: './feed-panel.component.html',
  styleUrls: ['./feed-panel.component.less']
})
export class FeedPanelComponent implements OnInit, OnDestroy {

  @Input() type: FeedType = FeedType.Internal;

  feeds: Feed[] = [];
  currentPage = 0;
  totalItems = 0;
  pageSize = 3;
  isError = false;
  private destroy$ = new Subject<void>();

  constructor(private feedService: FeedService,
              public dateTimeService: DateTimeService) {
  }

  ngOnInit(): void {
    this.loadFeeds(this.type);
  }

  public loadFeeds(type: FeedType): void {
    this.feedService.getFeeds({type})
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        response => {
          this.feeds = response.content;
          this.totalItems = response.totalElements;
        },
        error => {
          this.isError = true;
        }
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
