import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable, throwError} from 'rxjs';
import {Timesheet, TimesheetFilter, TimesheetStatus} from '../interfaces/timesheet';
import {catchError, map} from 'rxjs/operators';
import {ErrorService} from './error.service';
import {Page, PageFilter, UnpagedFilter} from '../interfaces/page';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TimesheetService {

  // required for the handleError callback method
  private static translateService: TranslateService;

  constructor(private datePipe: DatePipe,
              private http: HttpClient,
              private translateService: TranslateService) {
    TimesheetService.translateService = this.translateService;
  }

  static timesheetApi: string = environment.backendServiceUrl + '/' + environment.backendApiContext + '/' + 'timesheets';

  getTimesheet(id: number): Observable<Timesheet> {
    return this.http.get<Timesheet>(TimesheetService.timesheetApi + '/' + id)
      .pipe(
        map(timesheet => this.deserializeTimesheet(timesheet)),
        catchError(ErrorService.handleError)
      );
  }

  getTimesheets(timesheetFilter?: Partial<TimesheetFilter>, pageFilter?: Partial<PageFilter>): Observable<Page> {
    let params = new HttpParams();
    if (timesheetFilter !== undefined) {
      Object.entries(timesheetFilter)
        .forEach(([key, value]) =>
          params = params.append(key, value as string)
        );
    }
    if (pageFilter === undefined) {
      pageFilter = UnpagedFilter
    }
    Object.entries(pageFilter)
    .forEach(([key, value]) =>
      params = params.append(key, value)
    );

    return this.http.get<Page>(TimesheetService.timesheetApi + '/', {params})
      .pipe(
        map(page => {
          page.content.map( timesheet => this.deserializeTimesheet(timesheet));
          return page;
        }),
        catchError(ErrorService.handleError)
      );
  }

  cancelTimesheet(timesheetId: number, description?: string): Observable<Timesheet> {
    return this.patchTimesheet({
      id: timesheetId,
      status: TimesheetStatus.Canceled,
      description: description
    });
  }

  createTimesheet(timesheet: Timesheet): Observable<Timesheet> {
    return this.http.post<Timesheet>(TimesheetService.timesheetApi, this.serializeTimesheet(timesheet))
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  patchTimesheet(timesheet: Partial<Timesheet>): Observable<Timesheet> {
    if (timesheet == null || timesheet.id === undefined) {
      throwError('invalid input');
    }
    return this.http.patch<Timesheet>(TimesheetService.timesheetApi, timesheet)
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  updateTimesheet(timesheet: Timesheet): Observable<Timesheet> {
    return this.http.put<Timesheet>(TimesheetService.timesheetApi, this.serializeTimesheet(timesheet))
      .pipe(
        catchError(ErrorService.handleError)
      );
  }

  serializeTimesheet(timesheet: Timesheet): Timesheet {
    if (timesheet === undefined || timesheet === null) {
      return timesheet;
    }
    if (timesheet.dateTimeIn !== undefined && timesheet.dateTimeIn !== null) {
      timesheet.dateTimeIn = (this.datePipe.transform(timesheet.dateTimeIn, 'yyyy-MM-ddTHH:mm') as string);
    }
    if (timesheet.dateTimeOut !== undefined && timesheet.dateTimeOut !== null) {
      timesheet.dateTimeOut = (this.datePipe.transform(timesheet.dateTimeOut, 'yyyy-MM-ddTHH:mm') as string);
    }

    return timesheet;
  }

  deserializeTimesheet(timesheet: Timesheet): Timesheet {
    if (timesheet.dateTimeIn !== null) {
      timesheet.dateTimeIn = new Date(timesheet.dateTimeIn as string);
    }
    if (timesheet.dateTimeOut !== null) {
      timesheet.dateTimeOut = new Date(timesheet.dateTimeOut as string);
    }

    return timesheet;
  }
}
