import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../environments/environment';
import {LanguageService} from './services/language.service';
import {SessionService} from './services/session.service';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {

  title = 'GITS';

  constructor(private translateService: TranslateService,
              private languageService: LanguageService,
              private sessionService: SessionService) {
    if ('languages' in environment.features) {
      this.languageService.localeInitializer(environment.features.languages);
    }
    const sessionData = this.sessionService.getData();
    this.languageService.setCurrentLocale(sessionData.currentLocale ?? environment.language);
  }

  ngOnInit(): void {
    this.translateService.get('generic.humanResources')
      .pipe(
        first()
      )
      .subscribe(value =>
        this.title += value);
  }
}
