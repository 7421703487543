<div class="container-fluid">
  <div class="row justify-content-md-center">
    <div class="col-3">
      <mat-form-field appearance="fill" class="full-fill">
        <mat-label>{{"generic.id" | translate}}</mat-label>
        <input type="text" matInput disabled [value]="sessionData.currentUser.id">
      </mat-form-field>
    </div>
    <div class="col-3 text-center full-fill">
      <div>
      <mat-slide-toggle color="primary"
                        [aria-label]="'user.active' | translate"
                        [matTooltip]="'user.active' | translate"
                        [checked]="sessionData.currentUser.active"
                        disabled>{{"user.active" | translate}}
      </mat-slide-toggle>
      </div>
      <div>
      <mat-slide-toggle color="primary"
                        [aria-label]="'user.locked' | translate"
                        [matTooltip]="'user.locked' | translate"
                        [checked]="sessionData.currentUser.locked"
                        disabled>{{"user.locked" | translate}}
      </mat-slide-toggle>
      </div>
    </div>
  </div>
  <div class="row justify-content-md-center">
    <div class="col-md-3">
      <mat-form-field appearance="fill" class="full-fill">
        <mat-label>{{"generic.lastName" | translate}}</mat-label>
        <input type="text" matInput disabled [value]="sessionData.currentUser.lastName">
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="fill" class="full-fill">
        <mat-label>{{"generic.firstName" | translate}}</mat-label>
        <input type="text" matInput disabled [value]="sessionData.currentUser.firstName">
      </mat-form-field>
    </div>
  </div>
  <div class="row justify-content-md-center">
    <div class="col-md-3">
      <mat-form-field appearance="fill" class="full-fill">
        <mat-label>{{"generic.position" | translate}}</mat-label>
        <input type="text" matInput disabled [value]="sessionData.currentUser.position">
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="fill" class="full-fill">
        <mat-label>{{"generic.organizationName" | translate}}</mat-label>
        <input type="text" matInput disabled [value]="sessionData.currentUser.organizationName">
      </mat-form-field>
    </div>
  </div>
  <div class="row justify-content-md-center">
    <div class="col-md-3 full-fill">
      <mat-form-field appearance="fill" class="full-fill">
        <mat-label>{{"generic.emailAddress" | translate}}</mat-label>
        <input type="text" matInput disabled [value]="sessionData.currentUser.emailAddress">
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field appearance="fill" class="full-fill">
        <mat-label>{{"generic.phoneNumber" | translate}}</mat-label>
        <input type="text" matInput disabled [value]="sessionData.currentUser.phoneNumber">
      </mat-form-field>
    </div>
  </div>
  <div class="row justify-content-md-center">
    <div class="col-md-3 full-fill">
      <mat-form-field appearance="fill" class="full-fill">
        <mat-label>{{"user.password" | translate}}</mat-label>
        <input type="password" matInput [value]="sessionData.currentUser.password"
        [formControl]="passwordControl">
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <button mat-flat-button color="primary" class="m-1" type="button" [disabled]="passwordControl.pristine || actionInProgress"
      (click)="updatePassword()">
        {{"user.updatePassword" | translate}}
      </button>
      <button mat-flat-button type="button" class="float-right" (click)="navigateBack()" [disabled]="actionInProgress">
        {{'generic.back' | translate}}
      </button>
    </div>
  </div>
  <div class="row justify-content-md-center">
    <div class="col-md-3 full-fill">
      {{'user.passwordPolicy' | translate}}:
      <ul>
        <li *ngIf="userPasswordPolicyMinLength > 0">{{'user.passwordPolicyMinLength' | translate : {userPasswordPolicyMinLength} }}</li>
        <li *ngIf="userPasswordPolicyMinUpper > 0">{{'user.passwordPolicyMinUpper' | translate : {userPasswordPolicyMinUpper} }}</li>
        <li *ngIf="userPasswordPolicyMinLower > 0">{{'user.passwordPolicyMinLower' | translate : {userPasswordPolicyMinLower} }}</li>
        <li *ngIf="userPasswordPolicyMinDigit > 0">{{'user.passwordPolicyMinDigit' | translate : {userPasswordPolicyMinDigit} }}</li>
        <li *ngIf="userPasswordPolicyMinSpecial > 0">{{'user.passwordPolicyMinSpecial' | translate :
          {userPasswordPolicyMinSpecial, userPasswordPolicySpecialList} }}</li>
      </ul>
    </div>
    <div class="col-md-3 full-fill">
    </div>
  </div>
</div>
