import {Injectable} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {

    constructor(private translate: TranslateService) {
        super();
        this.translate.onLangChange.subscribe((e: Event) => {
            this.getAndInitTranslations();
        });
        this.getAndInitTranslations();
    }

    getAndInitTranslations(): void {
        this.translate.get(['paginator.itemsPerPage', 'paginator.previousPage', 'paginator.nextPage',
            'paginator.firstPage', 'paginator.lastPage'])
          .subscribe(translation => {
                this.itemsPerPageLabel = translation['paginator.itemsPerPage'];
                this.previousPageLabel = translation['paginator.previousPage'];
                this.nextPageLabel = translation['paginator.nextPage'];
                this.firstPageLabel = translation['paginator.firstPage'];
                this.lastPageLabel = translation['paginator.lastPage'];
                this.changes.next();
            });
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return `0 / ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} / ${length}`;
    }
}
