<div class="m-0 p-0 flex-grow-1">
  <mat-card>
    <mat-card-title>
      <mat-icon>campaign</mat-icon>
      {{'userNotification.notifications' | translate}} :
      <div class="inline mat-card-subtitle indent-1">
        <mat-form-field>
          <mat-select [(value)]="selectedFilter">
            <mat-option *ngFor="let userNotificationFilterType of userNotificationFilterTypes"
                        [value]="userNotificationFilterType">
              {{'userNotification.types.' + userNotificationFilterType | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <button mat-icon-button color="primary" type="button" class="outer-action-button" (click)="addNotification()">
        <mat-icon class="outer-action-button-icon">add_circle</mat-icon>
      </button>
    </mat-card-title>

    <mat-card-content>
      <div *ngFor="let notification of getNotifications(selectedFilter) | paginate: {
            id: 'notifications',
            itemsPerPage: pageSize,
            currentPage: currentPage,
            totalItems: totalItems}"
           [class]="notification.read ? 'item-read' : 'item-unread'">

          <table class="full-width margin-bottom-15">
            <tr>
              <td>
                <mat-icon class="item-title-icon" [color]="displayIconColor(notification)"
                  matTooltip="{{'userNotification.types.' + notification.type | translate}}">{{displayIcon(notification)}}</mat-icon>
                <div class="inline item-title-text">
                  {{displayHeader(notification)}}
                </div>
              </td>
              <td rowSpan="2" class="action-cell-2-buttons">
                  <button mat-button class="action-button"
                          matTooltip="{{'userNotification.moreActions' | translate}}"
                          [matMenuTriggerFor]="filterByMenu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <button mat-button class="action-button"
                          matTooltip="{{'userNotification.' + (notification.read ? 'markAsUnread' : 'markAsRead') | translate}}"
                          (click)="toggleNotificationRead(notification)"
                          [color]="notification.read ? 'primary' : 'basic'">
                    <mat-icon>check</mat-icon>
                  </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="pre">
                  <ng-container *ngFor="let it of parse(notification)">
                    <ng-container [ngSwitch]="it[0]">
                      <ng-container *ngSwitchCase="0">{{it[1]}}</ng-container>
                      <a *ngSwitchCase="1" [href]="it[1]">{{it[1]}}</a>
                      <a *ngSwitchCase="2" [href]="'mailto:' + it[1]">{{it[1]}}</a>
                    </ng-container>
                  </ng-container>
                  <div class="inline item-date">
                    {{dateTimeService.formatDate(notification.receivedDate, 'medium')}}
                  </div>
                </div>
              </td>
            </tr>
          </table>

        <mat-menu #filterByMenu="matMenu">
          <button mat-menu-item
                  (click)="openNotification(notification)">{{'generic.' + 'view' | translate}}</button>
          <button *ngIf="notificationBelongsToCurrentUser(notification)"
                  mat-menu-item
                  (click)="editNotification(notification)">{{'generic.' + 'edit' | translate}}</button>
          <button mat-menu-item
                  (click)="deleteNotification(notification)">{{'generic.' + 'delete' | translate}}</button>
          <button mat-menu-item
                  (click)="toggleNotificationRead(notification)">{{'userNotification.markAs' + (notification.read ? 'Unread' : 'Read') | translate}}</button>
        </mat-menu>
      </div>
      <mat-error *ngIf="isError">{{'userNotification.messages.errLoading' | translate}}</mat-error>
    </mat-card-content>

    <div class="text-right">
      <pagination-controls
        id="notifications"
        [responsive]="true"
        [maxSize]="5"
        previousLabel="{{'page.previous' | translate}}"
        nextLabel="{{'page.next' | translate}}"
        (pageChange)="currentPage = $event; onPageChange(currentPage);">
      </pagination-controls>
    </div>
  </mat-card>
</div>
