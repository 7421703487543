import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {DateTimeIntervals} from '../interfaces/features';
import {
  differenceInBusinessDays,
  differenceInDays,
  formatDistanceToNow,
  formatRelative,
  isWithinInterval,
  startOfMonth, endOfMonth, isAfter, isBefore, subDays, addDays, addHours, startOfWeek, endOfWeek
} from 'date-fns';
import {LanguageService} from './language.service';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';
import Holidays, {HolidaysTypes} from 'date-holidays';
import {PublicHolidayType} from '../interfaces/public-holiday';
import {AbstractControl} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {

  private publicHolidays: Holidays;

  constructor(private languageService: LanguageService,
              private translateService: TranslateService,
              private datePipe: DatePipe) {
    this.publicHolidays = new Holidays(languageService.getCurrentLocale());
  }

  addHours(date: Date, hours: number): Date {
    return addHours(date, hours);
  }

  convertDateControlToString(dateCtrl: AbstractControl | null): string | null {
    if (dateCtrl != null && dateCtrl.valid && dateCtrl.value instanceof Date) {
      return this.datePipe.transform(dateCtrl.value, 'yyyy-MM-dd');
    }
    return null;
  }

  convertDateTimeControlToString(dateCtrl: AbstractControl | null): string | null {
    if (dateCtrl != null && dateCtrl.valid && dateCtrl.value instanceof Date) {
      return this.datePipe.transform(dateCtrl.value, 'yyyy-MM-ddTHH:mm:ss');
    }
    return null;
  }

  formatDateTimeInterval(dateTimeText: string | unknown, format?: DateTimeIntervals): string {
    if (!dateTimeText) {
      return '';
    }
    const dateString: string = dateTimeText as string;
    const dateFormat = format ?? environment.features.dateTimeIntervals;
    const currentFnsLang = this.languageService.getCurrentFnsLocale();
    switch (dateFormat) {
      case DateTimeIntervals.Distance :
        return (currentFnsLang.code === 'ro' ? 'cu ' : '') +
          formatDistanceToNow(Date.parse(dateString), {addSuffix: true, locale: currentFnsLang});
      case DateTimeIntervals.Relative :
        const date = Date.parse(dateString);
        const now = new Date();
        const prefix = differenceInDays(now, date) > 6 ? this.translateService.instant('generic.in') + ' ' : '';
        return prefix + formatRelative(date, now, {locale: currentFnsLang});
      case DateTimeIntervals.DateTime :
      default:
        return ' ' + this.translateService.instant('dateTime.atDate') + ' ' +
          this.datePipe.transform(dateString, 'medium', '', this.translateService.currentLang);
    }
  }

  formatDate(date: string | Date | unknown, format?: string): string {
    if (!date) {
      return '';
    }

    let dateString: string = date as string;
    if ((date instanceof Date)) {
      dateString = date.toISOString();
    }
    const dateFormat = format ?? 'mediumDate';
    return this.datePipe.transform(dateString, dateFormat, '', this.translateService.currentLang) as string;
  }

  countBusinessDays(startDate: Date, endDate: Date): number {
    return differenceInBusinessDays(startDate, endDate) - this.countPublicHolidays(startDate, endDate);
  }

  isPublicHoliday(date: Date | null): boolean {
    if (date === null || date === undefined) {
      return false;
    }

    const holiday = this.publicHolidays.isHoliday(date);
    if (holiday === false || holiday.length === 0) {
      return false;
    }

    return holiday[0].type === PublicHolidayType.Public || holiday[0].type === PublicHolidayType.Bank;
  }

  countPublicHolidays(startDate: Date, endDate: Date, excludeWeekends: boolean = true): number {
    if (startDate === undefined || startDate === null
      || endDate === undefined || endDate === null) {
      return 0;
    }
    let holidayCount = 0;
    const minDate = startDate <= endDate ? startDate : endDate;
    const maxDate = startDate <= endDate ? endDate : startDate;

    let holidaysRange: HolidaysTypes.Holiday[] = [];
    for (let year = minDate.getFullYear(); year <= maxDate.getFullYear(); year++) {
      holidaysRange = holidaysRange.concat(this.publicHolidays.getHolidays(year));
    }
    holidaysRange.forEach(holiday => {
      if ((holiday.type === PublicHolidayType.Public || holiday.type === PublicHolidayType.Bank) &&
        (!excludeWeekends || (excludeWeekends && holiday.start.getDay() >= 1 && holiday.start.getDay() <= 5)) &&
        isWithinInterval(holiday.start, {start: minDate, end: maxDate})) {
          holidayCount++;
      }
    });

    return holidayCount;
  }

  endOfMonth(date: Date): Date {
    return endOfMonth(date);
  }

  endOfWeek(date: Date, options?: {locale?: Locale, weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6}): Date {
    return endOfWeek(date, options);
  }

  startOfMonth(date: Date): Date {
    return startOfMonth(date);
  }

  startOfWeek(date: Date, options?: {locale?: Locale, weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6}): Date {
    return startOfWeek(date, options);
  }

  isBetween(referenceDate: Date, startRangeDate: Date | string, endRangeDate: Date | string, inclusive = true): boolean {
    if (!referenceDate || !startRangeDate || !endRangeDate) {
      return false;
    }
    let startDate: Date;
    let endDate: Date;
    if (startRangeDate instanceof Date) {
      startDate = startRangeDate;
    } else {
      startDate = new Date(startRangeDate);
      startDate.setHours(0, 0, 0);
    }
    if (endRangeDate instanceof Date) {
      endDate = endRangeDate;
    } else {
      endDate = new Date(endRangeDate);
      endDate.setHours(23, 59, 59);
    }

    if (inclusive) {
      startDate = subDays(startDate, 1);
      endDate = addDays(endDate, 1);
    }
    const ret = isAfter(referenceDate, startDate) && isBefore(referenceDate, endDate);
    return ret;
  }
}
