import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {DashboardComponent} from './pages/activities/dashboard/dashboard.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {EmployeesComponent} from './components/employees/employees.component';
import {EmployeePageComponent} from './components/employees/employee-page/employee-page.component';
import {EmployeeDetailsComponent} from './components/employees/employee-page/employee-details/employee-details.component';
import {EmployeeDocumentsComponent} from './components/employees/employee-page/employee-documents/employee-documents.component';
import {EmployeeNotesComponent} from './components/employees/employee-page/employee-notes/employee-notes.component';
import {EmployeesListComponent} from './components/employees/employees-list/employees-list.component';
import {EmployeesOrgchartComponent} from './components/employees/employees-orgchart/employees-orgchart.component';
import {LeaveEditorComponent} from './pages/leaves/leave-editor/leave-editor.component';
import {LoginComponent} from './pages/security/login/login.component';
import {LeavesCalendarComponent} from './pages/leaves/leaves-calendar/leaves-calendar.component';
import {AuthenticationGuard} from './services/authentication-guard.service';
import {UserNotificationEditorComponent} from './pages/users/user-notification-editor/user-notification-editor.component';
import {UserProfileComponent} from './pages/users/user-profile/user-profile.component';
import {PaidLeaveReportComponent} from './pages/reports/paid-leave-report/paid-leave-report.component';
import {TimesheetsCalendarComponent} from './pages/timesheets/timesheets-calendar/timesheets-calendar.component';
import {TimesheetEditorComponent} from './pages/timesheets/timesheet-editor/timesheet-editor.component';
import {ApplicationConfigurationComponent} from './pages/config/application-configuration/application-configuration.component';
import {LeaveManagerComponent} from "./pages/leaves/leave-manager/leave-manager.component";

const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'config', canActivate: [AuthenticationGuard], children: [
      {path: 'application-configuration', component: ApplicationConfigurationComponent, canActivate: [AuthenticationGuard]}
    ]
  },
  {path: 'employee-details', component: EmployeePageComponent, canActivate: [AuthenticationGuard]},
  {path: 'employee-details/info', component: EmployeeDetailsComponent, canActivate: [AuthenticationGuard]},
  {path: 'employee-details/job', component: EmployeeDetailsComponent, canActivate: [AuthenticationGuard]},
  {path: 'employee-details/documents', component: EmployeeDocumentsComponent, canActivate: [AuthenticationGuard]},
  {path: 'employee-details/notes', component: EmployeeNotesComponent, canActivate: [AuthenticationGuard]},
  {path: 'employees', component: EmployeesComponent, canActivate: [AuthenticationGuard], children: [
      {path: 'list', component: EmployeesListComponent, canActivateChild: [AuthenticationGuard]},
      {path: 'org-chart', component: EmployeesOrgchartComponent, canActivateChild: [AuthenticationGuard]},
    ]
  },
  {path: 'home', component: DashboardComponent, canActivate: [AuthenticationGuard]},
  {path: 'leaves', canActivate: [AuthenticationGuard], children: [
      {path: 'calendar', component: LeavesCalendarComponent, canActivateChild: [AuthenticationGuard]},
      {path: 'leave', component: LeaveEditorComponent, canActivateChild: [AuthenticationGuard]},
      {path: 'leave/:id', component: LeaveEditorComponent, canActivateChild: [AuthenticationGuard]},
      {path: 'manager', component: LeaveManagerComponent, canActivate: [AuthenticationGuard]}
    ]
  },
  {path: 'businessLeaves', canActivate: [AuthenticationGuard], children: [
      {path: 'calendar', component: LeavesCalendarComponent, canActivateChild: [AuthenticationGuard]},
      {path: 'leave', component: LeaveEditorComponent, canActivateChild: [AuthenticationGuard]},
      {path: 'leave/:id', component: LeaveEditorComponent, canActivateChild: [AuthenticationGuard]},
      {path: 'manager', component: LeaveManagerComponent, canActivate: [AuthenticationGuard]}
    ]
  },
  {path: 'login', component: LoginComponent},
  {path: 'not-found', component: PageNotFoundComponent},
  {path: 'reports', canActivate: [AuthenticationGuard], children: [
      {path: 'paid-leave-report', component: PaidLeaveReportComponent, canActivate: [AuthenticationGuard]}
    ]
  },
  {path: 'timesheets', children: [
      {path: 'calendar', component: TimesheetsCalendarComponent},
      {path: 'timesheet', component: TimesheetEditorComponent, canActivateChild: [AuthenticationGuard]},
      {path: 'timesheet/:id', component: TimesheetEditorComponent, canActivateChild: [AuthenticationGuard]},
    ]
  },
  {path: 'users', canActivate: [AuthenticationGuard], children: [
      {path: 'user-notification', component: UserNotificationEditorComponent, canActivate: [AuthenticationGuard]},
      {path: 'user-notification/:id', component: UserNotificationEditorComponent, canActivate: [AuthenticationGuard]},
      {path: 'user-profile', component: UserProfileComponent, canActivate: [AuthenticationGuard]},
      {path: 'user-profile/:id', component: UserProfileComponent, canActivate: [AuthenticationGuard]}
    ]
  },
  {path: '**', redirectTo: '/not-found'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
