<mat-card style="margin:1em;">
  <mat-card-title>
    <mat-icon>today</mat-icon>
    {{"event.dailyEvents" | translate}}</mat-card-title>
  <mat-card-content>
    <div *ngIf="dailyEventsShowBirthAnniversary">
      <div *ngFor="let employee of employeesBornToday">
        <mat-icon matTooltip="{{'event.birthAnniversary' | translate}}">cake</mat-icon>
        {{employee.firstName}} {{employee.lastName}}
      </div>
    </div>
    <div *ngFor="let employee of employeesEmployedToday">
      <mat-icon matTooltip="{{'event.employmentAnniversary' | translate}}">business_center</mat-icon>
      {{employee.firstName}} {{employee.lastName}}
    </div>
    <div *ngFor="let employee of employeesOnLeaveToday">
      <mat-icon matTooltip="{{'leave.leave' | translate}}">beach_access</mat-icon>
      {{employee.firstName}} {{employee.lastName}}
    </div>
  </mat-card-content>
</mat-card>
