<div>
  <div *ngIf="showFilter">
    <div class="row justify-content-md-left">
      <div class="col-md-9">
        <app-leave-filter (selectionChanged)="onFilterChanged($event)"></app-leave-filter>
      </div>


      <div class="col-md-3">
        <span class="outer-action-button float-right">
          <button mat-icon-button type="button" [matMenuTriggerFor]="exportMenu"
                  matTooltip="{{'generic.export' | translate}}">
            <mat-icon>list_alt</mat-icon>
          </button>
          <mat-menu #exportMenu="matMenu" xPosition="before">
            <button mat-menu-item (click)="exportToExcel()">
              <mat-icon>filter_none</mat-icon>
              <span>{{"generic.exportXls" | translate}}</span>
            </button>
            <button mat-menu-item (click)="exportToPdf()">
              <mat-icon>picture_as_pdf</mat-icon>
              <span>{{"generic.exportPdf" | translate}}</span>
            </button>
          </mat-menu>
        </span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <mat-card>
        <mat-drawer-container autosize hasBackdrop="true">
          <gits-tabulator-table [tableId]="tableId"
                                [eventsSubscriptions]="gridSubscriptions"
                                [initLang]="currentLang"
                                [initWithoutTranslationsCheck]="true"
                                [columnsDefs]="columnDefs"
                                [columnLabels]="columnLabels"
                                [options]="gridOptions"
                                [data]="gridData"
                                [paginatorOptions]="paginationOptions"
                                [addRowButtons]="rowControls"
                                [groupByFieldsConfig]=""
                                [rowFormatter]=""
                                [customValidators]=""
                                [debugConfig]=""
                                [showHelp]=""
                                (apiAvailable)="onTableApiAvailable($event)"
                                (pageChanged)="onPageChanged($event)">
          </gits-tabulator-table>

          <mat-drawer #documentsDrawer
                      (openedChange)="onMatDrawerOpenedChanged($event)"
                      mode="over"
                      position="end">

            <div class="attached-docs-container">
              <p class="attached-doc-title">{{'leave.attachments' | translate}} </p>

              <span class="attached-doc-item">
                {{'employee.employee' | translate}}
                : <b>{{rowData?.employeeFirstName}} {{rowData?.employeeLastName}} ({{rowData?.employeeBadgeNumber}})</b>
              </span>
              <span class="attached-doc-item">
                {{'reports.leaveRequestsReport.grid.columnLabels.START_DATE' | translate}}
                : {{rowData?.startDate | luxonDateFormatter: 'dd-MM-yyyy'}}
              </span>
              <span class="attached-doc-item">{{'reports.leaveRequestsReport.grid.columnLabels.END_DATE' | translate}}
                : {{rowData?.endDate | luxonDateFormatter: 'dd-MM-yyyy'}}
              </span>

              <mat-selection-list #documents [multiple]="false">
                <mat-list-option *ngFor="let doc of rowData?.documentList"
                                 [value]="doc"
                                 (click)="downloadAttachment(doc.id)">
                  <div class="attached-doc-download-btn">
                    <mat-icon>attach_file</mat-icon>
                    <span>{{'generic.DOWNLOAD' | translate}} <b>"{{doc.fileName}}
                      "</b></span>
                  </div>
                </mat-list-option>
              </mat-selection-list>

              <button (click)="closeDocumentsDrawer()"
                      class="attached-doc-close-btn"
                      mat-flat-button
                      color="primary">
                Close
              </button>
            </div>

          </mat-drawer>

        </mat-drawer-container>
      </mat-card>
    </div>
  </div>
</div>
