import {DatePipe} from '@angular/common';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {addMonths} from 'date-fns';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Employee} from 'src/app/interfaces/employee';
import {SessionData} from 'src/app/interfaces/session';
import {UserNotification, UserNotificationPriorities, UserNotificationType} from 'src/app/interfaces/user-notification';
import {EmployeeService} from 'src/app/services/employee.service';
import {ErrorService} from 'src/app/services/error.service';
import {SessionService} from 'src/app/services/session.service';
import {UserNotificationService} from 'src/app/services/user-notification.service';
import {Location} from '@angular/common';
import {DateTimeService} from "../../../services/date-time.service";

@Component({
  selector: 'app-user-notification-editor',
  templateUrl: './user-notification-editor.component.html',
  styleUrls: ['./user-notification-editor.component.less']
})
export class UserNotificationEditorComponent implements OnInit, OnDestroy {

  readonly UserNotificationType = UserNotificationType;
  readonly userNotificationPriorities = UserNotificationPriorities;
  keysType: string[] = [];
  keysPriorities: string[] = [];

  actionInProgress = false;
  sessionData = this.sessionService.getData() as SessionData;
  employees: Employee[] = [];
  formGroup: FormGroup;
  destroy$ = new Subject<void>();

  constructor(private dateTimeService: DateTimeService,
              private formBuilder: FormBuilder,
              private userNotificationService: UserNotificationService,
              private errorService: ErrorService,
              private sessionService: SessionService,
              private employeeService: EmployeeService,
              private datePipe: DatePipe,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private location: Location
  ) {
    this.formGroup = this.formBuilder.group({
      id: 0,
      subject: [null, Validators.required],
      type: [UserNotificationType.Info, Validators.required],
      message: [null, Validators.required],
      priority: [UserNotificationPriorities.Normal, Validators.required],
      sourceUserAccountId: [this.sessionData.currentUser.id],
      targetUserAccountId: [null, Validators.required],
      expirationDate: [addMonths(new Date(), 1), Validators.required],
      receivedDate: [new Date(), Validators.required]
    });
    this.keysType = Object.keys(this.UserNotificationType);
    this.keysPriorities = Object.keys(this.userNotificationPriorities);
  }

  ngOnInit(): void {
    this.loadPageParameters(this.activatedRoute);
    this.populateEmployeeLists();
    this.loadUserNotification(this.formGroup.controls.id.value);
  }

  loadPageParameters(route: ActivatedRoute): void {
    route.paramMap.forEach(params => {
      if (params.has('id')) {
        this.formGroup.controls.id.setValue(Number(params.get('id')));
      }
    });
  }

  private loadUserNotificationFields(userNotification: UserNotification): void {
    this.formGroup.patchValue(userNotification);
    this.formGroup.patchValue({
      expirationDate: new Date(userNotification.expirationDate as string),
      receivedDate: new Date(userNotification.receivedDate as string)
    });
    this.setActionInProgress(false);
  }

  populateEmployeeLists(): void {
    this.employeeService.getActiveEmployees({})
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(employees =>
        this.employees = employees
      );
  }

  loadUserNotification(id: number): void {
    if (id > 0) {
      this.setActionInProgress(true);
      this.userNotificationService.getUserNotification(id)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(userNotification => {
          if (userNotification) {
            this.loadUserNotificationFields(userNotification);
          }
        });
    }
  }

  saveUserNotification(): void {
    if (this.formGroup.invalid) {
      this.errorService.showError('userNotification.messages.invalidNotificationFields');
      return;
    }
    this.setActionInProgress(true);
    const userNotification = this.formGroup.value;
    userNotification.expirationDate = this.dateTimeService.convertDateTimeControlToString(this.formGroup.get('expirationDate'));
    userNotification.receivedDate = this.dateTimeService.convertDateTimeControlToString(this.formGroup.get('receivedDate'));
    if (userNotification.id > 0) {
      this.userNotificationService.updateUserNotification(userNotification)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(response => {
            if (response && response.id) {
              this.errorService.showInfo('userNotification.messages.updateSuccess', {id: response.id});
              this.setActionInProgress(false);
              this.navigateBack();
            }
          },
          error => {
            this.errorService.showError('userNotification.messages.updateError', {error});
            this.setActionInProgress(false);
          });
    } else {
      this.userNotificationService.createUserNotification(userNotification)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(response => {
            if (response && response.id) {
              this.errorService.showInfo('userNotification.messages.createSuccess', {id: response.id});
              this.setActionInProgress(false);
              this.navigateBack();
            }
          },
          error => {
            this.errorService.showError('userNotification.messages.createError', {error});
            this.setActionInProgress(false);
          });
    }
  }

  navigateBack(): void {
    this.location.back();
  }

  private setActionInProgress(value: boolean): void {
    this.actionInProgress = value;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
